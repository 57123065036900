import { EmailTypesEnum } from 'src/app/shared-components/enums/emails.enum';

export interface EmailContactModel {
	from_email: string;
	creator_email: string;
	email_type: EmailTypesEnum;
	mailbox_id: number;
	conversation_id: any;
	subject: string;
	body: string;
	sent_at: Date;
	is_starred: boolean;
	influencer_id: number;
	creator_name: string;
	profile_picture: string;
	has_attachments: boolean;
	is_read: boolean;
}

export interface EmailConversationResponseModel {
	from_email?: string; // Brand Email
	to_email?: string; // Creator email
	company_picture?: string;
	company_user_name: string;
	influencer_picture?: string;
	influencer_name: string;
	conversation?: EmailConversationModel[];
}

export interface EmailConversationModel {
	id: string;
	body?: string;
	date?: string;
	from_email: string;
	to_email: string;
	attachments?: AttachmentModel[];
}

export interface AttachmentModel {
	id: string;
	name: string;
	contentType: string;
	size: number;
}

export interface SendCRMEmailRequestModel {
	conversation_id: string;
	mailbox_id: number;
	email_type: EmailTypesEnum;
	from_email: string;
	to_email: string;
	body: string;
	attachments?: FormData;
}

export interface DownloadAttachmentRequestModel {
	email_type: string;
	mailbox_id: string;
	message_id: string;
	attachment_id: string;
}

export enum FilterType {
	ALL = 'All',
	READ = 'Read',
	UNREAD = 'Unread',
	STARRED = 'Starred',
	HAS_FILES = 'Has files',
	DATE = 'Date'
}

export enum SortType {
	DATE = 'Date',
	NEWEST_ON_TOP = 'Newest on top',
	OLDEST_ON_TOP = 'Oldest on top'
}
