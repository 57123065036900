import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericInputDataModel } from '../../models/generic.interface';

@Component({
	selector: 'app-tab-bar-blue',
	templateUrl: './tab-bar-blue.component.html',
	styleUrls: ['./tab-bar-blue.component.scss']
})
export class TabBarBlueComponent implements OnInit {
	@Input() public tabItems: GenericInputDataModel[];
	@Input() public activeTabIndex: number = 0; // * Starts With 0
	@Output() public tabChanged: EventEmitter<number> = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	public setActiveTab(tabIndex: number): void {
		if (this.activeTabIndex == tabIndex) {
			return;
		}
		this.activeTabIndex = tabIndex;
		this.tabChanged.emit(tabIndex);
	}
}
