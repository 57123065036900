import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { ShortNumberPipe } from 'src/app/shared/pipes/shortnumber-pipe';
import { getDefaultScaleConfig } from 'src/app/shared/utils';
import { GenericChartDataModel } from '../models/chart.model';

import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-generic-pie-graph',
	templateUrl: './generic-pie-graph.component.html',
	styleUrls: ['./generic-pie-graph.component.scss']
})
export class GenericPieGraphComponent implements OnInit {
	@Input() public chartId: string = 'default-chart-id';
	@Input() public chartData: GenericChartDataModel;
	@Input() public chartHeight?: string = '100%';
	@Input() public chartWidth?: string = '100%';
	@Input() public chartXAxisLabel?: string;
	@Input() public chartYAxisLabel?: string;
	@Input() public chartXAxisCallback?: Function;
	@Input() public chartYAxisCallback?: Function;

	public donutChart: any = Chart;

	private resizeSubject$: Subject<void> = new Subject<void>();
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private shortNumberPipe: ShortNumberPipe) {}

	ngOnInit(): void {
		this.resizeSubject$
			.pipe(debounceTime(200), takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.generateChart();
			});
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.resizeSubject$.next();
	}

	ngAfterViewInit(): void {
		this.generateChart();
	}

	private generateChart(): void {
		let generatedChartData: any = {
			labels: this.chartData.labels,
			datasets: this.chartData.datasets
		};
		var chartExist = Chart.getChart(this.chartId); // <canvas> id
		if (chartExist != undefined) chartExist.destroy();

		this.donutChart = new Chart(this.chartId, {
			type: 'doughnut',
			data: generatedChartData,
			options: {
				responsive: true,
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						callbacks: {
							label: context => `${context.parsed}%`
						}
					}
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.resizeSubject$.complete();
		var chartExist = Chart.getChart(this.chartId); // <canvas> id
		if (chartExist != undefined) chartExist.destroy();
	}
}
