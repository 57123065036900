import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SequenceHeadCellComponent } from '../cells/head-cell.component';

@Component({
	selector: 'thead[sequence-table-head]',
	template: `
		<th sequence-head-cell title="{{ heads[0] }}"></th>
		<th sequence-head-cell title="{{ heads[1] }}"></th>
		<th sequence-head-cell title="{{ heads[2] }}" center="true"></th>
		<th sequence-head-cell title="{{ heads[3] }}" center="true"></th>
		<th sequence-head-cell title="{{ heads[4] }}" center="true"></th>
		<th sequence-head-cell title="{{ heads[5] }}" center="true"></th>
	`,
	styleUrls: ['./sequence-head.component.scss'],
	imports: [SequenceHeadCellComponent, CommonModule],
	standalone: true
})
export class SequenceTableHeadComponent {
	@Input()
	heads: string[];
}
