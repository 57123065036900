import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OutreachSequenceSummaryModel } from '../../../../models/outreach-sequence-new.model';
import { SequenceTextCellComponent } from '../cells/text-cell.component';
import { SequenceMenuCellComponent } from '../cells/menu-cell.component';
import { SequenceToggleCellComponent } from '../cells/toggle-cell.component';
import { formatTriggerString } from '../../../../../../../shared/utils';

@Component({
	selector: 'tr[sequence-table-row]',
	template: `
		<td
			sequence-text-cell
			link="true"
			(click)="onSequenceClicked.emit(item)"
			data="{{ item.sequence_name }}"
		></td>
		<td
			sequence-text-cell
			data="{{
				item.trigger
					? formatTriggerString(item.trigger)
					: 'Not specified'
			}}"
		></td>
		<td
			sequence-text-cell
			data="{{ item.num_of_steps }}"
			center="true"
		></td>
		<td
			sequence-text-cell
			data="{{ getPrettyScheduleTime() }}"
			center="true"
		></td>
		<td
			sequence-toggle-cell
			[data]="item"
			(onCheck)="onSequenceChecked.emit($event)"
		></td>
		<td
			sequence-menu-cell
			[data]="item"
			(deleteClick)="onSequenceDeleted.emit($event)"
		></td>
	`,
	styleUrls: ['./sequence-row.component.scss'],
	imports: [
		SequenceTextCellComponent,
		SequenceMenuCellComponent,
		SequenceToggleCellComponent
	],
	standalone: true
})
export class SequenceTableRowComponent {
	@Input()
	public item: OutreachSequenceSummaryModel;

	@Output()
	public onSequenceClicked = new EventEmitter<OutreachSequenceSummaryModel>();

	@Output()
	public onSequenceChecked = new EventEmitter<OutreachSequenceSummaryModel>();

	@Output()
	public onSequenceDeleted = new EventEmitter<OutreachSequenceSummaryModel>();

	protected readonly formatTriggerString = formatTriggerString;

	public getPrettyScheduleTime(): string {
		if (this.item.is_immediate) {
			return 'Immediate';
		} else {
			return `${this.item.start_time} - ${this.item.end_time} ${this.item.timezone}`;
		}
	}
}
