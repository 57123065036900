import { Location } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
	RouterEvent
} from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { Subject, Subscription, interval } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AudiencePermissions } from 'src/app/shared/permisions/enums/audience-permissions';
import { CreativeBuilderPermissions } from 'src/app/shared/permisions/enums/creative-builder-permissions';
import { InfluencerPermissions } from 'src/app/shared/permisions/enums/influencer-permissions';
import { ProductCatalogPermissions } from 'src/app/shared/permisions/enums/product-catalog-permissions';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import {
	CampaignFlowData,
	CampaignService
} from 'src/app/social-media-influencer/modules/dashboard/services/campaign.service';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import { UserRejectedReason } from 'src/app/_services/user/user-rejected-reason.enum';
import { buildGeneratedValues } from '../../../../build-generated-values';
import { environment } from '../../../environments/environment';
import { AccountTypeService } from '../../shared/account-type.service';
import { ButtonClassEnum } from '../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../shared/button-component/button-type.enum';
import { AccountsPermissions } from '../../shared/permisions/enums/accounts-permissions';
import { AdsManagerPermissions } from '../../shared/permisions/enums/ads-manager-permissions';
import { BackofficePermissions } from '../../shared/permisions/enums/backoffice-permissions';
import { CampaignBuilderPermissions } from '../../shared/permisions/enums/campaign-builder-permissions';
import { Modules } from '../../shared/permisions/enums/modules';
import { OptimizePermissions } from '../../shared/permisions/enums/optimize-permissions';
import { PixelPermissions } from '../../shared/permisions/enums/pixel-permissions';
import { ReportsPermissions } from '../../shared/permisions/enums/reports-permissions';
import { PermissionsDirectiveInterface } from '../../shared/permisions/models/permissions-directive.interface';
import {
	getnavigationSpinnerStatus,
	SharedState
} from '../../shared/state/shared.reducer';
import { SetUserDetails } from '../../shared/state/user/user.actions';
import {
	getAccountState,
	getFiledId,
	getUserDetails,
	hasBackOfficePermission,
	isUserImpersonated,
	UserState
} from '../../shared/state/user/user.reducer';
import {
	getActiveUserManagementPage,
	UserManagementState
} from '../../user-management/state/user-management.reducer';
import { UserDetails } from '../../_models/identity-models/identity-detail';
import { StorageKey } from '../../_models/local-storage-key';
import { AuthenticationService } from '../../_services/authentication.service';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { ScrollService } from '../../_services/scroll.service';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { KnowledgeBaseComponent } from '../component-pieces/knowledge-base/components/knowledge-base/knowledge-base.component';
import {
	IMainMenuGroup,
	IProductMenuGroup,
	IProductSubMenuGroup
} from '../_models/nav-group.interface';
import { ChannelButton } from './sidenav-button-channel.model';
import {
	NotificationActionEnum,
	NotificationModel
} from 'src/app/shared/models/notifications.model';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { ShopifyService } from 'src/app/_services/shopify/shopify.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TrialDataModel } from 'src/app/shared/models/first-timer-user.model';
import { GenericSidePopupModel } from 'src/app/shared-components/generic-side-popup/models/generic-side-popup-data.model';
import { GenericSidePopupService } from 'src/app/shared/services/generic-side-popup.service';
import { UtilsService } from 'src/app/shared/utils';
import { UserPlanRestrictionModel } from 'src/app/shared/models/user-plan-restriction.model';
import { LandingPageService } from 'src/app/social-media-influencer/modules/landing-page/services/landing-page.service';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';
import { OnboardingDialogComponent } from '../components/onboarding-dialog/onboarding-dialog.component';

@Component({
	selector: 'app-sidenav-group',
	templateUrl: './sidenav-group.component.html',
	styleUrls: ['./sidenav-group.component.scss'],
	providers: [ScrollService]
})
export class SidenavGroupComponent implements OnInit, OnDestroy {
	@ViewChild('sidenav') public sidenav: MatSidenav;
	@ViewChild('firstTimeLogin') public firstTimeLogin: TemplateRef<any>;
	@ViewChild('menuTrigger') public trigger: MatMenuTrigger;

	@ViewChild('settingsPopup') settingsPopup: ElementRef;
	@ViewChild('settingsIcon') settingsIcon: ElementRef;
	@ViewChild('mainSection') mainSection: ElementRef;
	@ViewChild('headerLogo') headerLogo: ElementRef;

	public mainMenuGroup = new Map<number, IMainMenuGroup>();

	public productMenuGroup: Map<number, IProductMenuGroup>;

	public productSubMenuGroup = new Map<number, IProductSubMenuGroup>();

	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public backOfficePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.BackOffice,
		permissions: [BackofficePermissions.CanAccessBackOffice]
	};
	public accountsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Accounts,
		permissions: [AccountsPermissions.CanAccessAccounts]
	};
	public adsManagerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.AdsManager,
		permissions: [AdsManagerPermissions.CanAccessAdsManager]
	};
	public campaignBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CampaignBuilder,
		permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
	};
	public creativeBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CreativeBuilder,
		permissions: [CreativeBuilderPermissions.CanAccessCreativeBuilder]
	};
	public optimizePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Optimize,
		permissions: [OptimizePermissions.CanAccessOptimize]
	};
	public pixelPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Pixel,
		permissions: [PixelPermissions.CanAccessPixels]
	};
	public audiencePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Audience,
		permissions: [AudiencePermissions.CanAccessAudience]
	};
	public reportsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Reports,
		permissions: [ReportsPermissions.CanAccessReports]
	};
	public influencerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Influencer,
		permissions: [InfluencerPermissions.FullAccess]
	};
	public productCatalogPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.ProductCatalog,
		permissions: [ProductCatalogPermissions.CanAccessProductCatalog]
	};
	public showSidenav = true;
	public showSpinner = false;
	public isAdmin = false;
	public isImpersonated = false;
	public unpaidInvoice = false;
	public showProgressBar = false;
	public invoicesList: InvoiceHistory[];
	public timestamp: string;
	public userDetails: UserDetails;
	public showUserManagement: boolean;
	public dropdownIsVisible: boolean;
	public selectedButton: ChannelButton;
	public accountState: number;
	public currentUrl: string;
	public currentRoute: string;
	public progressMin = 0;
	public progressMax: number;
	public progressStatus: number;
	public isLoading: boolean;
	public isSocialMediaInfluencer: boolean;
	public isInfluencerPlatform: boolean;
	public progressBarLabel: LabelSettings = {
		position: 'end',
		visible: true,
		format: () => `${this.progressStatus + ' of ' + this.progressMax}`
	};
	public userStateEnum = UserStateEnum;
	public pcHover = false;
	public pcChildHover = false;
	public userStatus: UserRejectedReason;
	public isSelectedNoBO: string;
	public isBO = false;
	productCatalogMenuSelected: boolean;
	selectedRoute: string;
	public newPointPosition: string = '0px';
	expandMenu: boolean;
	mouseInside: boolean;
	filteredMegaMenuList: IMainMenuGroup[] = [];
	filteredMenuList: IProductMenuGroup[] = [];
	filteredSubMenuList: IProductSubMenuGroup[];
	openSubMenu: boolean;
	megaMenuOpen: any;
	megaMenuSelected: string = 'sansa';
	profileOpen: any;
	dexterList: IProductMenuGroup[] = [];
	sansaList: IProductMenuGroup[] = [];
	shakespeareList: IProductMenuGroup[] = [];
	activeSearchMapList: IProductMenuGroup[];
	activeRoute: string;
	brandName: string;
	userFName: string;
	userLName: string;
	userEmail: string;
	urlOrigin: string;
	activeParentIndex: number;
	allSubMenuItems: IProductSubMenuGroup[] = [];
	pageLoadingRoute: boolean;
	public subscriptionLoading: boolean = false;

	public primaryColor: string = '#005ed8';

	public get navHover(): boolean {
		return this.pcHover || this.pcChildHover;
	}

	public hideSideBar: number = 0;
	private expandAccountMenuSubscription = new Subscription();
	private notificationSubscription: Subscription;
	private unsubscriber$ = new Subject<void>();
	private asyncLoadCount: number;
	public hasSMI: boolean;
	public smiHover = false;
	public notifications: NotificationModel[] = [];
	public unreadCount: number = 0;
	public smiChildHover = false;
	public numberOfDaysLeftForTrial: number = 0;
	public getStartedLoading: boolean = true;
	public trialPeriodData: TrialDataModel;
	public isStatusBarVisible: boolean = true;
	public get smiNavHover(): boolean {
		return this.smiHover || this.smiChildHover;
	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (!this.settingsIcon?.nativeElement.contains(event.target)) {
			this.profileOpen = false;
		}
	}

	constructor(
		public router: Router,
		private authenticationService: AuthenticationService,
		private landingPageService: LandingPageService,
		private notificationService: NotificationsService,
		private toastNotificationService: ToastNotificationService,
		private accountTypeService: AccountTypeService,
		private route: ActivatedRoute,
		private config: NgSelectConfig,
		private store: Store<UserManagementState>,
		private dialog: MatDialog,
		private userStore: Store<UserState>,
		private backOfficeService: BackOfficeService,
		private shopifyService: ShopifyService,
		// private smartCreateService: CampaignSmartCreateService,
		private scrollService: ScrollService,
		public cdRef: ChangeDetectorRef,
		private sharedService: SharedService,
		private sharedStore: Store<SharedState>,
		private campaignService: CampaignService,
		private genericSidePopupService: GenericSidePopupService,
		private authV2Service: AuthenticationV2Service,
		private location: Location
	) {
		this.pageLoadingRoute = true;
		this.mainMenuGroup = new Map();
		const megaMenus = this.authenticationService.allowedMegaRoutes$.value;
		try {
			megaMenus.forEach((item, index) => {
				this.mainMenuGroup.set(index + 1, item);
			});
			this.route.queryParams
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(params => {
					if (params['charge_id']) {
						this.shopifyService
							.clientAPI(params['charge_id'])
							.pipe(take(1))
							.subscribe(
								res => {
									// window.location.href = window.location.href.split('?')[0];
								},
								err => {
									// window.location.href = window.location.href.split('?')[0];
								}
							);
					}
				});
		} catch (e) {
			// this.router.navigate(['accounts']);
		}

		this.productMenuGroup = new Map([
			[
				1,
				{
					parentName: 'sansa',
					name: 'home',
					title: 'Home',
					icon: 'assets/icons/nav-menu/sidenav/home.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/',
					childIndex: 0
				}
			],
			// [
			// 	13,
			// 	{
			// 		parentName: 'sansa',
			// 		name: 'dashboard',
			// 		title: 'Dashboard',
			// 		icon: 'assets/icons/nav-menu/sidenav/Icon material-dashboard.svg',
			// 		isSelected: false,
			// 		hasChildren: false,
			// 		hoverOver: false,
			// 		childOpen: false,
			// 		route: '/dashboard',
			// 		childIndex: 0
			// 	}
			// ],
			[
				15,
				{
					parentName: 'sansa',
					name: 'discovery',
					title: 'Discovery',
					icon: 'assets/icons/nav-menu/sidenav/discovery.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/discovery',
					childIndex: 0
				}
			],
			[
				16,
				{
					parentName: 'sansa',
					name: 'list',
					title: 'Lists',
					icon: 'assets/icons/nav-menu/sidenav/lists.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/list',
					queryParams: { tab: 'lists' },
					childIndex: 0
				}
			],
			[
				6,
				{
					parentName: 'sansa',
					name: 'campaign',
					title: 'Campaigns',
					icon: 'assets/icons/nav-menu/sidenav/campaign.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/campaign',
					childIndex: 0
				}
			],
			// [
			// 	3,
			// 	{
			// 		parentName: 'sansa',
			// 		name: 'discovery',
			// 		title: 'Discovery',
			// 		icon: 'assets/icons/nav-menu/sidenav/discovery.svg',
			// 		isSelected: false,
			// 		hasChildren: false,
			// 		hoverOver: false,
			// 		childOpen: false,
			// 		route: '/discovery-old',
			// 		childIndex: 0
			// 	}
			// ],
			[
				4,
				{
					parentName: 'sansa',
					name: 'outreach',
					title: 'Messaging',
					icon: 'assets/icons/nav-menu/sidenav/crm.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/outreach',
					childIndex: 0
				}
			],
			// [
			// 	7,
			// 	{
			// 		parentName: 'sansa',
			// 		name: 'jobs',
			// 		title: 'Jobs',
			// 		icon: 'assets/icons/nav-menu/sidenav/jobs.svg',
			// 		isSelected: false,
			// 		hasChildren: false,
			// 		hoverOver: false,
			// 		childOpen: false,
			// 		route: '/jobs',
			// 		childIndex: 0
			// 	}
			// ],
			// [
			// 	2,
			// 	{
			// 		parentName: 'sansa',
			// 		name: 'marketplace',
			// 		title: 'Marketplace',
			// 		icon: 'assets/icons/nav-menu/sidenav/marketplace.svg',
			// 		isSelected: false,
			// 		hasChildren: false,
			// 		hoverOver: false,
			// 		childOpen: false,
			// 		route: '/marketplace',
			// 		childIndex: 0
			// 	}
			// ],
			// [
			// 	5,
			// 	{
			// 		parentName: 'sansa',
			// 		name: 'messages',
			// 		title: 'Chat',
			// 		icon: 'assets/icons/nav-menu/sidenav/chat.svg',
			// 		isSelected: false,
			// 		hasChildren: false,
			// 		hoverOver: false,
			// 		childOpen: false,
			// 		route: '/messages',
			// 		childIndex: 0
			// 	}
			// ],
			// [
			// 	9,
			// 	{
			// 		parentName: 'sansa',
			// 		name: 'content-review',
			// 		title: 'Content Review',
			// 		icon: 'assets/icons/nav-menu/sidenav/creatives.svg',
			// 		isSelected: false,
			// 		hasChildren: false,
			// 		hoverOver: false,
			// 		childOpen: false,
			// 		route: '/content-review',
			// 		childIndex: 0
			// 	}
			// ],
			[
				10,
				{
					parentName: 'sansa',
					name: 'reporting',
					title: 'Analytics',
					icon: 'assets/icons/nav-menu/sidenav/reporting.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/reporting',
					childIndex: 0
				}
			],
			[
				12,
				{
					parentName: 'sansa',
					name: 'ecommerce',
					title: 'Ecommerce',
					icon: 'assets/icons/nav-menu/sidenav/ecommerce.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/e-commerce',
					childIndex: 0
				}
			],
			[
				11,
				{
					parentName: 'sansa',
					name: 'payments',
					title: 'Payouts',
					icon: 'assets/icons/nav-menu/sidenav/payments.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/payments',
					childIndex: 0
				}
			],
			// [
			// 	14,
			// 	{
			// 		parentName: 'sansa',
			// 		name: 'profile',
			// 		title: 'Profile',
			// 		icon: 'assets/icons/nav-menu/sidenav/profile.svg',
			// 		isSelected: false,
			// 		hasChildren: false,
			// 		hoverOver: false,
			// 		childOpen: false,
			// 		route: '/user-management/my-profile',
			// 		childIndex: 0
			// 	}
			// ]
			[
				19,
				{
					parentName: 'sansa',
					name: 'creators',
					title: 'Creators',
					icon: 'assets/icons/nav-menu/sidenav-creators.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/creators',
					childIndex: 0
				}
			],
			[
				17,
				{
					parentName: 'sansa',
					name: 'adv-search',
					title: 'Advance Search',
					icon: 'assets/icons/nav-menu/sidenav/adv-search-icon.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/adv-search',
					disabled: false,
					childIndex: 0
				}
			],
			[
				18,
				{
					parentName: 'sansa',
					name: 'assets',
					title: 'Assets',
					icon: 'assets/icons/nav-menu/sidenav/creatives.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/asset-management',
					disabled: false,
					childIndex: 0
				}
			],
			[
				20,
				{
					parentName: 'sansa',
					name: 'onboarding',
					title: 'Onboarding',
					icon: 'assets/icons/rocket.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/onboarding',
					disabled: false,
					childIndex: 0
				}
			]
		]);

		this.productSubMenuGroup = new Map([
			[
				1,
				{
					parentName: 'reporting',
					name: 'campaign-dashboard',
					title: 'Campaign Dashboard',
					icon: '',
					isSelected: false,
					hoverOver: false
				}
			],
			[
				2,
				{
					parentName: 'reporting',
					name: 'campaign-analytics',
					title: 'Campaign Analytics',
					icon: '',
					isSelected: false,
					hoverOver: false,
					show: false
				}
			],
			[
				3,
				{
					parentName: 'reporting',
					name: 'influencer-dashboard',
					title: 'Creator Dashboard',
					icon: '',
					isSelected: false,
					hoverOver: false
				}
			],
			[
				4,
				{
					parentName: 'reporting',
					name: 'influencer-analytics',
					title: 'Influencer Analytics',
					icon: '',
					isSelected: false,
					hoverOver: false,
					show: false
				}
			],
			[
				5,
				{
					parentName: 'optimize',
					name: 'dashboard',
					title: 'Dashboard',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/dashboard'
				}
			],
			[
				6,
				{
					parentName: 'optimize',
					name: 'recommendationsv2',
					title: 'Recommendations',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/recommendationsv2'
				}
			],
			[
				7,
				{
					parentName: 'optimize',
					name: 'new-ideas',
					title: 'New Ideas',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/labs'
				}
			],
			[
				8,
				{
					parentName: 'optimize',
					name: 'sync',
					title: 'Sync',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/dexter-sync'
				}
			],
			[
				9,
				{
					parentName: 'campaign-builder',
					name: 'google',
					title: 'Google',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/campaign/intro'
				}
			],
			[
				22,
				{
					parentName: 'campaign-builder',
					name: 'facebook',
					title: 'Facebook',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/campaign/intro'
				}
			],
			[
				10,
				{
					parentName: 'audience-creator',
					name: 'facebook',
					title: 'Facebook',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/dexter-creator'
				}
			],
			[
				11,
				{
					parentName: 'copywriter',
					name: 'ad-copy',
					title: 'Ad Copy',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/dexter-creator'
				}
			],
			[
				12,
				{
					parentName: 'ecommerce',
					name: 'manage',
					title: 'Manage',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/catalog/catalog-manage'
				}
			],
			[
				13,
				{
					parentName: 'ecommerce',
					name: 'import',
					title: 'Import',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/catalog/catalog-import-option'
				}
			],
			[
				23,
				{
					parentName: 'campaign',
					name: 'campaign',
					title: 'Campaigns',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/campaign'
				}
			],
			[
				24,
				{
					parentName: 'campaign',
					name: 'affiliatecampaign',
					title: 'Affiliate Campaigns',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/campaign/affiliate'
				}
			]
		]);
		this.asyncLoadCount = 0;
		this.router.events
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((event: RouterEvent) => {
				if (event instanceof NavigationEnd) {
					if (
						this.trialPeriodData?.subscription_status !==
							'active' &&
						this.trialPeriodData?.had_trial_period === true &&
						this.trialPeriodData?.is_on_trial === false
					) {
						let name = 'home';
						const splitUrl = this.router.url.split('/');
						if (splitUrl.length > 0) {
							name = splitUrl[1];
						}
						if (name == 'reporting') {
							this.genericSidePopupService
								.showPredefinedSidePopups(name)
								.then(res => {
									if (res?.primary === true) {
										this.router.navigate(
											['user-management/manage-plan'],
											{ queryParams: { openPlans: true } }
										);
									}
									if (res?.secondary === true) {
										window.open(
											UtilsService.BOOK_DEMO_URL,
											'_blank'
										);
									}
								});
							this.router.navigate(['/']);
						}
						// Means user's trial period has expired
						// this.restrictUserNavigationAndShowSidePopup(event);
					} else {
						const state = this.router.getCurrentNavigation()?.extras
							?.state;
						this.urlOrigin = window.location.origin;
						this.sharedService.previousRoute = this.currentRoute;
						this.currentRoute = event?.urlAfterRedirects;
						if (event.id === 1) {
							this.isUserAdmin();
						}
						this.updateSidenavVisibility();
						this.userStore
							.pipe(select(getFiledId), take(1))
							.subscribe(filedId => {
								this.userStore
									.pipe(
										select(hasBackOfficePermission),
										take(1)
									)
									.subscribe(isAdmin => {
										this.currentUrl = event.url;
										if (!isAdmin) {
											this.backOfficeService
												.getUserState(filedId)
												.pipe(
													takeUntil(
														this.unsubscriber$
													)
												)
												.subscribe(accountState => {
													this.currentUrl = event.url;

													if (
														this.currentUrl !==
															'/user-management/billing-and-payment' &&
														accountState ===
															UserStateEnum.BillPendingRestrictedAccess
													) {
														// this.router.navigate(['/user-management/billing-and-payment']);
													} else if (
														accountState ===
															UserStateEnum.FreemiumExpiredNoCreditCard ||
														accountState ===
															UserStateEnum.FreeTrialExpiredNoCreditCard
													) {
														// this.router.navigate(['/authentication/trial-end']);
													} else if (
														accountState ===
														UserStateEnum.NoCreditCard
													) {
														// this.router.navigate(['/authentication/add-billing-address']);
													} else if (
														accountState ===
														UserStateEnum.NoBusinessOwner
													) {
														// this.router.navigate(['/authentication/connect']);
													}
												});
										}
									});
							});
						this.hasSMI = event.url.split('/')[1] == 'influencer';
						this.activeRoute = event.url;

						if (!this.pageLoadingRoute) {
							// this.checkRouteMap();
						}
					}
				}
			});

		if (environment.Flags.hasTimestamp) {
			this.timestamp = buildGeneratedValues.timeStamp;
		}

		this.config.appendTo = 'body';
		this.config.bindValue = 'value';
	}

	public ngOnInit(): void {
		this.isUserAdmin();
		this.initializeSpinner();
		this.getAllData();

		this.hideSideBar = +localStorage.getItem('hideBar') || 0;

		if (this.selectedButton) {
			this.accountTypeService.setAccountType(
				this.selectedButton.sourceChannel
			);
		}
		this.impersonationHandle();
		this.checkUserManagementState();
		this.isUnpaidInvoice();
		if (!this.isAdmin) {
			setTimeout(() => {
				this.filterMegaMenutList();
				this.getUserPlanInfo();
			}, 200);
		}

		this.updateBindValues();

		// * Only for first time
		setTimeout(() => {
			const pathSegments = location.pathname.split('?')[0].split('/');
			this.updateSidenavSelectedMenuItem(pathSegments);
		}, 500);

		// * Then subscribing to route changes here
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe((val: NavigationStart) => {
				const url = val.url.split('?')[0].split('/');
				const pathSegments = url;
				this.updateSidenavSelectedMenuItem(pathSegments);
			});

		// ! Notifications below implementation
		// this.getNotifications();
		// this.pollNotifications();
		this.getFirstTimerData();
		this.listenToTrialPeriodSubject();
		this.sharedService.userProfileData$
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				this.brandName = res?.company ?? '';
			});
		this.sharedService.triggerFirstTimeGetStarted
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.openOnboardingDialog();
			});
	}

	private openOnboardingDialog(): void {
		this.dialog
			.open(OnboardingDialogComponent, {
				width: '430px',
				height: '424px',
				panelClass: 'onboarding-dialog-no-shadow',
				backdropClass: 'light-backdrop'
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {});
	}

	private updateSidenavSelectedMenuItem(pathSegments: string[]) {
		let firstPathSegment =
			pathSegments.length > 1 && pathSegments[1] ? pathSegments[1] : '';
		firstPathSegment = '/' + firstPathSegment;
		this.filteredMenuList.forEach(v => {
			if (firstPathSegment === v.route) v.isSelected = true;
			else v.isSelected = false;
		});
	}

	private listenToTrialPeriodSubject(): void {
		this.sharedService.trialPeriodData$
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				this.trialPeriodData = res;
			});
	}

	public onSubscribeNowClick(): void {
		this.router.navigate(['user-management/manage-plan'], {
			queryParams: { openPlans: true }
		});
		this.hideStatusBar();
	}

	public hideStatusBar(): void {
		this.isStatusBarVisible = false;
	}

	public getFirstTimerData(): void {
		this.getStartedLoading = true;
		this.sharedService.getStartedData().subscribe(
			res => {
				this.trialPeriodData = res?.trial_period;
				this.sharedService.trialPeriodData$.next(this.trialPeriodData);
				this.sharedService.firstTimerData$.next(res?.first_time_user);
				this.sharedService.getStartedData$.next(res?.get_started_data);
				this.getStartedLoading = false;
			},
			err => {
				this.getStartedLoading = false;
			}
		);
	}

	public getAllData(): void {
		this.landingPageService
			.getAllData('all')
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.sharedService.userProfileData$.next(res?.data);
				},
				err => {}
			);
	}

	public goToUpgradePlan(): void {
		this.router.navigate(['user-management/manage-plan'], {
			queryParams: { openPlans: true }
		});
	}

	public updateSidenavVisibility(): void {
		if (
			this.currentRoute.includes('campaign-create') ||
			this.currentRoute.includes('content-review') ||
			this.currentRoute.includes('onboarding')
		) {
			this.showSidenav = false;
			return;
		}
		this.showSidenav = true;
	}

	public ngAfterViewChecked(): void {
		this.updateBindValues();
		this.cdRef.detectChanges();
	}

	public restrictUserNavigationAndShowSidePopup(event: NavigationEnd): void {
		const navItem = this.filteredMenuList.find(v => {
			if (event.url === v.route) return v;
			else if (event.url.includes(v.route) && v.route !== '/') return v;
		});
		this.showRestrictedSidePopup(navItem.name);
		this.router.navigate(['/']);
	}

	private updateBindValues(): void {
		const storageJwt = JSON.parse(
			localStorage.getItem(StorageKey.decodedJwtIo)
		);
		// console.log(storageJwt);
		// this.brandName = storageJwt['company_name'];
		this.userFName = storageJwt?.['user_firstname'];
		this.userLName = storageJwt?.['user_lastname'];
		this.userEmail = storageJwt?.['sub'];
	}

	public updateUnreadCountEvent(count: number): void {
		this.unreadCount = count;
	}

	public pollNotifications(): void {
		interval(60000)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(x => {
				this.getNotifications();
			});
	}

	public getNotifications(): void {
		if (
			this.notificationSubscription &&
			!this.notificationSubscription.closed
		) {
			this.notificationSubscription.unsubscribe();
		}
		this.notificationSubscription = this.notificationService
			.getAllNotifications(10)
			.pipe(take(1))
			.subscribe(res => {
				this.notifications = res.data.notifications;
				this.unreadCount = res.data.unread_count;
			});
	}

	// public checkRouteMap(): void {
	// 	if (this.activeRoute.includes('?')) {
	// 		if (this.activeRoute.includes('outreach')) {
	// 			this.filteredMenuList.forEach(item => {
	// 				if (item.name == 'outreach') {
	// 					item.isSelected = true;
	// 				} else {
	// 					item.isSelected = false;
	// 				}
	// 			});
	// 			return;
	// 		} else if (this.activeRoute.includes('messages')) {
	// 			this.filteredMenuList.forEach(item => {
	// 				if (item.name == 'messages') {
	// 					item.isSelected = true;
	// 				} else {
	// 					item.isSelected = false;
	// 				}
	// 			});
	// 			return;
	// 		} else {
	// 			return;
	// 		}
	// 	}
	// 	this.activeSearchMapList = [];
	// 	let routesplit = this.activeRoute.split('/');
	// 	if (this.megaMenuSelected === 'sansa') {
	// 		this.activeSearchMapList = this.sansaList;
	// 	}
	// 	if (this.activeSearchMapList.length > 0) {
	// 		const findRouteIndex = this.activeSearchMapList.findIndex(item => {
	// 			return location.pathname === item.route;
	// 			// return this.activeRoute.includes(item.route);
	// 		});
	// 		if (findRouteIndex !== -1) {
	// 			this.pageLoadingRoute = true;
	// 			this.menuItemActive(findRouteIndex, false);

	// 			if (this.pageLoadingRoute) {
	// 				setTimeout(() => {
	// 					this.pageLoadingRoute = false;
	// 				}, 10);
	// 			}
	// 		}
	// 		if (this.megaMenuSelected === 'sansa') {
	// 			if (routesplit[3] == 'influencer-profile') {
	// 				this.filteredMenuList.forEach(item => {
	// 					item.isSelected = false;
	// 				});
	// 				this.filteredMenuList.length > 0 ? (this.filteredMenuList[2].isSelected = true) : null;
	// 			}
	// 		}
	// 	}
	// }

	toggleProfileAction(): void {
		this.profileOpen
			? (this.profileOpen = false)
			: (this.profileOpen = true);
	}

	public toggleExpandNavMenu(): void {
		if (this.expandMenu) {
			this.expandMenu = false;
			this.authenticationService.expandMenu$.next(this.expandMenu);
		} else {
			this.expandMenu = true;
			this.authenticationService.expandMenu$.next(this.expandMenu);
		}
	}

	public filterMegaMenutList(): void {
		let filtered: IMainMenuGroup[] = [];
		const activeMegaRouteIndex = this.authenticationService
			.setMegaRouteIndex$.value;

		for (let item of this.mainMenuGroup.values()) {
			filtered.push(item);
			this.filterMenutList(item.name);
		}
		if (this.mainMenuGroup.size < 1) {
			this.filterMenutList('');
		}
		this.filteredMegaMenuList = filtered;

		this.megaMenuItemActive(activeMegaRouteIndex);
	}

	public filterMenutList(megaMenuName: string): void {
		for (let item of this.productMenuGroup.values()) {
			this.sansaList.push(item);
		}
		if (this.megaMenuSelected === 'dexter') {
			this.filteredMenuList = this.dexterList;
		}
		if (this.megaMenuSelected === 'sansa') {
			this.filteredMenuList = this.sansaList;
			// this.router.navigate(['/']);
		}
		if (this.megaMenuSelected === 'shakespeare') {
			this.filteredMenuList = this.shakespeareList;
		}
		this.openSubMenu = true;
	}

	public redirectToHome(): void {
		this.router.navigate(['']);
	}

	public gotoProfile(): void {
		this.router.navigate(['user-management/my-profile']);
	}

	public getUserPlanInfo(): void {
		this.sharedService
			.userPlanInfo()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res) {
						this.sharedService.userPlanRestrictions$.next(res);
						this.filteredMenuList.find(
							x => x.name === 'reporting'
						).disabled = !(res?.campaign_analytics === true);
						this.filteredMenuList.find(
							x => x.name === 'adv-search'
						).disabled = !(res?.advanced_search === true);
						const paySide = this.filteredMenuList.find(
							x => x.name === 'payments'
						);
						if (paySide) {
							paySide.disabled = !(res?.payouts === true);
						}
					}
				},
				err => {
					const restricted: UserPlanRestrictionModel = {
						advanced_search: false,
						campaign_analytics: false,
						payouts: false
					};
					this.sharedService.userPlanRestrictions$.next(restricted);
					this.filteredMenuList.find(
						x => x.name === 'reporting'
					).disabled = false;
					this.filteredMenuList.find(
						x => x.name === 'adv-search'
					).disabled = false;
					this.filteredMenuList.find(
						x => x.name === 'payments'
					).disabled = false;
				}
			);
	}

	menuItemTrigger(index: number): void {
		const menuItemObj = this.filteredMenuList[index];
		if (menuItemObj?.name == 'onboarding') {
			this.openOnboardingDialog();
			return;
		}
		if (menuItemObj?.disabled === true) {
			this.router.navigate(['/']);
			this.showRestrictedSidePopup(menuItemObj.name);
			return;
		}
		this.menuItemActive(index);
	}

	public showRestrictedSidePopup(name: string): void {
		this.genericSidePopupService
			.showPredefinedSidePopups(name)
			.then(res => {
				if (res?.primary === true) {
					this.router.navigate(['user-management/manage-plan'], {
						queryParams: { openPlans: true }
					});
				}
				if (res?.secondary === true) {
					this.bookADemoLink();
				}
			});
	}

	public bookADemoLink(): void {
		window.open(UtilsService.BOOK_DEMO_URL, '_blank');
	}

	menuItemSubTrigger(index: number): void {
		this.menuItemSubActive(index);
	}

	menuItemActive(index: number, pushRoute = true): void {
		let indexactive = this.filteredMenuList.findIndex(
			x => x.isSelected == true
		);
		if (index != indexactive) {
			// this.sharedStore.dispatch(new ShowNavigationSpinner());
		}
		this.filteredMenuList.forEach(item => {
			item.isSelected = false;
		});
		for (let i = 0; i < this.filteredMenuList.length; i++) {
			if (i === index) {
				this.filteredMenuList[i].isSelected = true;
				if (this.filteredMenuList[i].hasChildren) {
					this.expandMenu = true;
					this.authenticationService.expandMenu$.next(
						this.expandMenu
					);
				} else {
					this.expandMenu = false;
					this.authenticationService.expandMenu$.next(
						this.expandMenu
					);
				}
				this.menuChildrenList(this.filteredMenuList[i], i, pushRoute);

				break;
			}
		}
		this.activeParentIndex = index;
		if (this.filteredMenuList[index].name === 'audience-creator') {
			this.authenticationService.triggerNavRoute$.next('audience');
		}
		if (this.filteredMenuList[index].name === 'copywriter') {
			this.authenticationService.triggerNavRoute$.next('content');
		}
		if (this.filteredMenuList[index].name === 'campaign-builder') {
			this.authenticationService.triggerNavRoute$.next('google');
		}
		if (!this.filteredMenuList[index].hasChildren) {
			if (this.filteredMenuList[index].queryParams)
				this.router.navigate([this.filteredMenuList[index].route], {
					queryParams: this.filteredMenuList[index].queryParams
				});
			else this.router.navigate([this.filteredMenuList[index].route]);
		}
		if (!this.filteredMenuList[index].hasChildren) {
			// this.expandMenu = false;
			this.filteredMenuList.forEach(item => {
				item.childOpen = false;
			});
		} else {
			this.expandMenu = true;
			this.authenticationService.expandMenu$.next(this.expandMenu);
			this.filteredMenuList[index].childOpen = true;
		}
	}

	megaMenuItemActive(index: number, change = false): void {
		this.filteredMegaMenuList.forEach(item => {
			item.isSelected = false;
		});
		let routesplit = this.activeRoute.split('/');
		for (let i = 0; i < this.filteredMegaMenuList.length; i++) {
			if (i === index) {
				this.filteredMegaMenuList[i].isSelected = true;
				this.megaMenuSelected = this.filteredMegaMenuList[i].name;
				break;
			}
		}
		if (this.megaMenuSelected === 'dexter') {
			this.filteredMenuList = this.dexterList;
			this.router.navigate(['/accounts']);
		}
		if (this.megaMenuSelected === 'sansa') {
			this.filteredMenuList = this.sansaList;

			if (
				this.activeRoute !== '/terms-and-conditions' &&
				!this.activeRoute.includes('user-management') &&
				!(
					this.activeRoute.indexOf('outreach') >= 0 &&
					this.activeRoute.indexOf('customize') >= 0
				)
			) {
				if (routesplit[3] == 'influencer-profile') {
					this.router.navigate([this.activeRoute]);
				} else {
					// this.router.navigate(['/']);
				}
			}
		}
		if (this.megaMenuSelected === 'shakespeare') {
			this.filteredMenuList = this.shakespeareList;
			this.router.navigate(['/shakespeare']);
		}
		this.megaMenuOpen = false;
		// this.expandMenu = false;
		this.filteredSubMenuList = [];
		this.filteredMenuList.forEach(item => {
			item.isSelected = false;
			item.childOpen = false;
		});
		this.activeParentIndex = 0;
		if (this.megaMenuSelected === 'sansa') {
			if (routesplit[3] == 'influencer-profile') {
				this.filteredMenuList.length > 0
					? (this.filteredMenuList[1].isSelected = true)
					: null;
			} else {
				this.filteredMenuList.length > 0
					? (this.filteredMenuList[0].isSelected = true)
					: null;
			}
		} else {
			this.filteredMenuList.length > 0
				? (this.filteredMenuList[0].isSelected = true)
				: null;
		}

		if (this.pageLoadingRoute) {
			setTimeout(() => {
				this.pageLoadingRoute = false;
			}, 10);
		}
	}

	menuItemSubActive(index: number, pushRoute = true): void {
		this.filteredSubMenuList.forEach(item => {
			item.isSelected = false;
		});
		for (let i = 0; i < this.filteredSubMenuList.length; i++) {
			if (i === index) {
				this.authenticationService.navMenuRoute$.next(true);
				const findParent = this.filteredMenuList.findIndex(item => {
					return item.name === this.filteredSubMenuList[i].parentName;
				});
				this.activeParentIndex = findParent;
				if (this.filteredSubMenuList[i].parentName === 'reporting') {
					if (pushRoute) {
						this.router
							.navigate([this.filteredMenuList[findParent].route])
							.then(() => {
								this.authenticationService.setSubNavIndex$.next(
									i
								);
							});
					}
				}
				if (
					this.filteredSubMenuList[i].parentName ===
					'audience-creator'
				) {
					this.authenticationService.triggerNavRoute$.next(
						'audience'
					);
					if (pushRoute) {
						this.router.navigate([
							this.filteredMenuList[findParent].route
						]);
					}
				}
				if (this.filteredSubMenuList[i].parentName === 'copywriter') {
					this.authenticationService.triggerNavRoute$.next('content');
					if (pushRoute) {
						this.router.navigate([
							this.filteredMenuList[findParent].route
						]);
					}
				}
				if (this.filteredSubMenuList[i].parentName === 'templates') {
					this.router.navigate([
						this.filteredMenuList[findParent].route
					]);
				}
				if (
					this.filteredSubMenuList[i].parentName ===
					'campaign-builder'
				) {
					if (this.filteredSubMenuList[i].name === 'google') {
						this.authenticationService.triggerNavRoute$.next(
							'google'
						);
					} else if (
						this.filteredSubMenuList[i].name === 'facebook'
					) {
						this.authenticationService.triggerNavRoute$.next(
							'facebook'
						);
					}
					if (pushRoute) {
						this.router.navigate([
							this.filteredMenuList[findParent].route
						]);
					}
				}
				if (
					this.filteredSubMenuList[i].parentName !== 'reporting' &&
					this.filteredSubMenuList[i].parentName !== 'copywriter' &&
					this.filteredSubMenuList[i].parentName !==
						'audience-creator' &&
					this.filteredSubMenuList[i].parentName !==
						'campaign-builder' &&
					this.filteredSubMenuList[i].parentName !== 'ecommerce'
				) {
					setTimeout(() => {
						if (pushRoute) {
							this.router.navigate([
								this.filteredSubMenuList[i].route
							]);
						}
					}, 200);
				}
				if (this.filteredSubMenuList[i].parentName === 'reporting') {
					switch (i) {
						case 1:
							this.filteredSubMenuList[0].isSelected = true;
							this.filteredMenuList[findParent].childIndex = 0;
							break;
						case 3:
							this.filteredSubMenuList[2].isSelected = true;
							this.filteredMenuList[findParent].childIndex = 2;
							break;
						default:
							this.filteredSubMenuList[i].isSelected = true;
							this.filteredMenuList[findParent].childIndex = i;
							break;
					}
				} else {
					this.filteredSubMenuList[i].isSelected = true;
					this.filteredMenuList[findParent].childIndex = i;
				}

				break;
			}
		}
		setTimeout(() => {
			this.expandMenu = false;
		}, 3000);
	}

	public menuChildrenList(
		list: IProductMenuGroup,
		parentIndex,
		pushRoute = true
	): void {
		let filteredSubMenu: IProductSubMenuGroup[] = [];
		for (let item of this.productSubMenuGroup.values()) {
			if (item.parentName === list.name) {
				filteredSubMenu.push(item);
			}
		}
		this.filteredSubMenuList = filteredSubMenu;
		if (this.filteredSubMenuList.length > 0) {
			if (this.filteredMenuList[parentIndex].childIndex === 0) {
				this.filteredSubMenuList[0].isSelected = true;
				this.menuItemSubActive(0, pushRoute);
			} else {
				this.filteredSubMenuList[
					this.filteredMenuList[parentIndex].childIndex
				].isSelected = true;
				this.menuItemSubActive(
					this.filteredMenuList[parentIndex].childIndex,
					pushRoute
				);
			}
			this.openSubMenu = true;
		} else {
			this.menuItemSubActive(
				this.filteredMenuList[parentIndex].childIndex,
				pushRoute
			);
		}
		// this.store.dispatch(new HideGlobalSpinner());
	}

	public collapseMenu(): void {
		this.openSubMenu
			? (this.openSubMenu = false)
			: (this.openSubMenu = true);
	}

	public mouseEnterMegaMenu(index: number): void {
		this.filteredMegaMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredMegaMenuList.length; i++) {
			if (i === index) {
				this.filteredMegaMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveMegaMenu(index): void {
		this.filteredMegaMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterMenu(index: number): void {
		this.filteredMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredMenuList.length; i++) {
			if (i === index) {
				this.filteredMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveMenu(index): void {
		this.filteredMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterSubMenu(index: number): void {
		this.filteredSubMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredSubMenuList.length; i++) {
			if (i === index) {
				this.filteredSubMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveSubMenu(index): void {
		this.filteredSubMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterMenuEffect(): Object {
		let styles = {
			background: !this.megaMenuOpen ? '#DADCE0' : '#fafafa'
		};
		return styles;
	}

	public menuItemSelectedEffect(): Object {
		let styles = {
			// background: '#DADCE0',
			'border-left': !this.megaMenuOpen
				? `5px solid ${this.primaryColor}`
				: '0px'
		};
		return styles;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();

		this.removeSubscriptions();
		if (
			this.notificationSubscription &&
			!this.notificationSubscription.closed
		) {
			this.notificationSubscription.unsubscribe();
		}
	}

	public onScroll(event: any): void {
		this.scrollService.changeScrollY(event.target.scrollTop);
	}

	public exitImpersonation(): void {
		const oldToken = localStorage.getItem(StorageKey.oldToken);
		const oldDecodedToken = localStorage.getItem(
			StorageKey.oldDecodedJwtIo
		);

		this.userStore
			.pipe(select(getUserDetails), take(1))
			.subscribe(userDetails => {
				this.userDetails = userDetails;
			});
		this.router.navigate(['/back-office']);

		localStorage.setItem(StorageKey.token, oldToken);
		localStorage.setItem(
			StorageKey.decodedJwtIo,
			JSON.stringify(oldDecodedToken)
		);

		this.userDetails.IsImpersonated = false;
		this.userStore.dispatch(new SetUserDetails(this.userDetails));

		this.resetPermissions();
	}

	public impersonationHandle(): void {
		this.userStore
			.pipe(select(getUserDetails), takeUntil(this.unsubscriber$))
			.subscribe(details => {
				const googleBO = JSON.parse(
					localStorage.getItem('installedPlatorm')
				)?.find(value => {
					return value === 'Google';
				});
				this.isBO =
					details?.FacebookBusinessOwnerId !== '' &&
					details?.FacebookBusinessOwnerId
						? true
						: false;
				this.userStatus = details?.RejectedReason;
				this.resetPermissions();
			});
		this.userStore
			.pipe(select(isUserImpersonated), takeUntil(this.unsubscriber$))
			.subscribe(isImpersonated => {
				this.isImpersonated = isImpersonated;
				this.dropdownIsVisible = true;
				this.resetPermissions();
			});
		this.userStore
			.pipe(select(hasBackOfficePermission), take(1))
			.subscribe(isAdmin => {
				this.isAdmin = isAdmin;
				this.dropdownIsVisible = !isAdmin;
			});
	}

	public checkUserManagementState(): void {
		this.store
			.pipe(
				select(getActiveUserManagementPage),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(resp => {
				this.showUserManagement = resp;
			});
	}

	public openKnowledgeBase(): void {
		this.dialog.open(KnowledgeBaseComponent, {
			width: '600px',
			height: '100%',
			position: {
				right: '0'
			},
			panelClass: ['matdialog-no-padding', 'slide', 'slideInRight']
		});
	}

	public goToUserPage(): void {
		this.profileOpen = false;
		this.router.navigate(['/user-management/my-profile']);
	}

	public logout(): void {
		this.profileOpen = false;
		this.authenticationService.expandMenu$.next(false);
		this.authV2Service.signoutUser();
	}

	public isUserAdmin(): void {
		this.userStore
			.pipe(
				select(hasBackOfficePermission),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(isAdmin => {
				this.isAdmin = isAdmin;
			});
	}

	private removeSubscriptions(): void {
		this.expandAccountMenuSubscription.unsubscribe();
	}

	private initializeSpinner(): void {
		this.sharedStore
			.select(getnavigationSpinnerStatus)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(status => (this.showSpinner = status));
	}

	private isUnpaidInvoice(): void {
		this.userStore
			.pipe(select(getAccountState), takeUntil(this.unsubscriber$))
			.subscribe(accountState => {
				this.accountState = accountState;

				if (this.accountState === 7 && !this.isAdmin) {
					this.unpaidInvoice = true;
				}
			});
	}

	private resetPermissions(): void {
		this.backOfficePermission = {
			moduleName: Modules.BackOffice,
			permissions: [BackofficePermissions.CanAccessBackOffice]
		};
		this.accountsPermission = {
			moduleName: Modules.Accounts,
			permissions: [AccountsPermissions.CanAccessAccounts]
		};
		this.adsManagerPermission = {
			moduleName: Modules.AdsManager,
			permissions: [AdsManagerPermissions.CanAccessAdsManager]
		};
		this.campaignBuilderPermission = {
			moduleName: Modules.CampaignBuilder,
			permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
		};
		this.optimizePermission = {
			moduleName: Modules.Optimize,
			permissions: [OptimizePermissions.CanAccessOptimize]
		};
		this.pixelPermission = {
			moduleName: Modules.Pixel,
			permissions: [PixelPermissions.CanAccessPixels]
		};
		this.audiencePermission = {
			moduleName: Modules.Audience,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.reportsPermission = {
			moduleName: Modules.Reports,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.influencerPermission = {
			moduleName: Modules.Influencer,
			permissions: [InfluencerPermissions.FullAccess]
		};
	}

	public goToSettings(): void {
		this.profileOpen = false;
		this.router.navigate(['/user-management/my-profile']);
	}
}
