<div
	class="toggle-btn"
	[matMenuTriggerFor]="toggleColumnMenu"
	#toggleColumnMenuTrigger="matMenuTrigger"
>
	<img src="assets\icons\select-columns-icon.svg" />
	Columns
</div>

<mat-menu #toggleColumnMenu="matMenu" class="toggle-column-menu">
	<mat-icon class="mat-icon-btn-grey abs-top-right">close</mat-icon>
	<ng-container *ngFor="let column of availableColumns">
		<div class="slider-wrapper" (click)="$event?.stopPropagation()">
			<mat-slide-toggle
				class="custom-blue-slide-toggle"
				[checked]="inputControl?.value?.includes(column?.id)"
				(click)="onColumnSliderClick($event, column)"
			>
				{{ column?.title }}
			</mat-slide-toggle>
		</div>
	</ng-container>
</mat-menu>
