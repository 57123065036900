import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedState } from '../../shared/state/shared.reducer';
import { Store } from '@ngrx/store';
import { HideGlobalSpinner } from '../../shared/state/shared.actions';
import { Router } from '@angular/router';
import {
	UserPaymentSubscriptionCodesEnum,
	UserPlanLimitCodesEnum
} from 'src/app/shared/models/plan-limits.model';
import { GenericSmDialogService } from 'src/app/shared/services/generic-sm-dialog.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { TrialDataModel } from 'src/app/shared/models/first-timer-user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { GenericSidePopupService } from 'src/app/shared/services/generic-side-popup.service';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';
import { GenericPredefinedPopupTypesEnum } from 'src/app/shared-components/generic-side-popup/models/generic-side-popup-data.model';
import {
	convertBlobToJsonError,
	isBlobError,
	UtilsService
} from 'src/app/shared/utils';

@Injectable({
	providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<SharedState>,
		private sharedService: SharedService,
		private genericSidePopupService: GenericSidePopupService,
		private authV2Service: AuthenticationV2Service,
		private toast: ToastNotificationService,
		private genericSmDialogService: GenericSmDialogService,
		private router: Router
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				this.store.dispatch(new HideGlobalSpinner());

				if (error instanceof HttpErrorResponse) {
					if (isBlobError(error)) {
						convertBlobToJsonError(error)
							.then((newErr: HttpErrorResponse) => {
								return this.handleError(newErr);
							})
							.catch(err => {
								return this.handleError(err);
							});
					} else {
						return this.handleError(error);
					}
				} else {
					return throwError(error);
				}
			})
		);
	}

	private handleError(error: HttpErrorResponse): Observable<HttpEvent<any>> {
		if (error?.status === 401) {
			if (
				error?.error?.detail?.error_code ==
				UserPaymentSubscriptionCodesEnum.EMAIL_IS_NOT_CONFIRMED
			) {
				this.toast.sendErrorToast(error?.error?.detail?.message);
			} else {
				this.toast.sendErrorToast(
					'Invalid email and password combination'
				);
			}
			this.authV2Service.signoutUser();
			return throwError(error);
		}

		if (error?.status === 402) {
			this.handlePaymentErrors(error);
			return throwError(error);
		}

		if (error?.status === 403) {
			this.handleLimitErrors(error as HttpErrorResponse);
			return throwError(error);
		}

		return throwError(error);
	}

	private handlePaymentErrors(error): void {
		if (this.router.url === '/user-management/manage-plan') {
			return;
		}

		switch (error?.error?.detail?.error_code) {
			case UserPaymentSubscriptionCodesEnum.TRIAL_EXPIRED:
				const trialPeriodExpired: TrialDataModel = {
					days_left: 0,
					had_trial_period: true,
					is_on_trial: false,
					subscription_status: 'trialing'
				};
				this.sharedService.trialPeriodData$.next(trialPeriodExpired);
				this.handleTrialExpiredError();
				this.router.navigate(['/']);
				break;
			case UserPaymentSubscriptionCodesEnum.PAYMENT_FAILED:
				this.showPaymentFailedDialog();
				this.router.navigate(['/']);
				break;
			case UserPaymentSubscriptionCodesEnum.SUB_EXPIRED:
			case UserPaymentSubscriptionCodesEnum.SUB_NOT_FOUND:
			default:
				this.showSubscriptionCancelledDialog();
				this.router.navigate(['/']);
				break;
		}
	}

	private handleTrialExpiredError(): void {
		this.showPredefinedSidePopup();
	}

	private handleLimitErrors(error): void {
		if (this.router.url === '/user-management/manage-plan') {
			return;
		}

		let popupType: GenericPredefinedPopupTypesEnum =
			GenericPredefinedPopupTypesEnum.HOME;

		switch (error?.error?.detail?.error_code) {
			case UserPlanLimitCodesEnum.DISCOVERY_SEARCH:
			case UserPlanLimitCodesEnum.DISCOVERY_PROFILE:
			case UserPlanLimitCodesEnum.EXPORTS:
			case UserPlanLimitCodesEnum.DISCOVERY_EMAILS:
				popupType = GenericPredefinedPopupTypesEnum.DISCOVERY;
				break;
			case UserPlanLimitCodesEnum.ADVANCED_SEARCH:
				popupType = GenericPredefinedPopupTypesEnum.ADVANCED_SEARCH;
				break;
			case UserPlanLimitCodesEnum.LISTS_COUNT:
			case UserPlanLimitCodesEnum.LIST_SIZE:
				popupType = GenericPredefinedPopupTypesEnum.LIST;
				break;
			case UserPlanLimitCodesEnum.EMAILS_INTEGRATION:
				popupType = GenericPredefinedPopupTypesEnum.OUTREACH;
				break;
			case UserPlanLimitCodesEnum.INTEGRATED_DOMAINS:
				popupType = GenericPredefinedPopupTypesEnum.ECOMMERCE;
				break;
			// ? MORE CASES BELOW IF WE NEED IN FUTURE
			// case UserPlanLimitCodesEnum.TEAM_SIZE:
			// case UserPlanLimitCodesEnum.RESETED_COUNTERS:
			default:
				return;
		}
		this.showPredefinedSidePopup(
			popupType,
			error?.error?.detail?.error_code,
			true
		);
	}

	private showSubscriptionCancelledDialog(): void {
		this.genericSmDialogService
			.showPredefinedPopups('sub_expired', true)
			.then(res => {
				if (res && res?.primaryAction === 'true') {
					window.open(UtilsService.BOOK_DEMO_URL, '_blank');
				}

				if (res && res?.secondaryAction === 'true') {
					this.router.navigate(['user-management/manage-plan'], {
						queryParams: { openPlans: true }
					});
				}
			});
	}

	private showPaymentFailedDialog(): void {
		this.genericSmDialogService
			.showPredefinedPopups('payment_fail', true)
			.then(res => {
				if (res && res?.primaryAction === 'true') {
					this.router.navigate(['user-management/manage-plan'], {
						queryParams: { openPlans: true }
					});
				}
			});
	}

	private showPredefinedSidePopup(
		type?: GenericPredefinedPopupTypesEnum | string,
		limitCode?: UserPlanLimitCodesEnum,
		showLimitData = false
	): void {
		let name = type ?? GenericPredefinedPopupTypesEnum.HOME;
		if (!type) {
			const splitUrl = this.router.url.split('/');
			if (splitUrl.length > 0) {
				name = splitUrl[1];
			}
		}
		this.genericSidePopupService
			.showPredefinedSidePopups(name, limitCode, showLimitData)
			.then(res => {
				if (res?.primary === true) {
					this.router.navigate(['user-management/manage-plan'], {
						queryParams: { openPlans: true }
					});
				}
				if (res?.secondary === true) {
					window.open(UtilsService.BOOK_DEMO_URL, '_blank');
				}
			});
	}
}
