import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { GenericInputDataModel } from '../models/generic.interface';

@Component({
	selector: 'app-tab-bar-with-underline-indicator',
	templateUrl: './tab-bar-with-underline-indicator.component.html',
	styleUrls: ['./tab-bar-with-underline-indicator.component.scss']
})
export class TabBarWithUnderlineIndicatorComponent implements OnInit {
	@Input() public tabWidth: string = '100px';
	@Input() public tabGap: number = 8; // * in px
	@Input() public tabItems: GenericInputDataModel[];
	@Input() public activeTabIndex: number = 0; // * Starts With 0

	@Output() public tabChanged: EventEmitter<number> = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	get sliderPosition(): string {
		const gapsLength = this.tabGap * this.activeTabIndex;
		const leftPosition = this.activeTabIndex * 100;
		return `translateX(calc(${leftPosition}% + ${gapsLength}px))`;
	}

	public setActiveTab(tabIndex: number): void {
		if (this.activeTabIndex == tabIndex) {
			return;
		}
		this.tabChanged.emit(tabIndex);
	}
}
