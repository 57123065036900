import { Component, Injector, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	HideGlobalSpinner,
	ShowGlobalSpinner
} from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { Location } from '@angular/common';
//silsrang-migration
// import { PageHeaderComponent } from '../../../../../../reports/shared/page-header/page-header.component';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { Country } from 'src/app/_services/back-office/back-office.models';
import { CompleteModalComponent } from '../../../modals/complete-modal/complete-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastV2TypeEnum } from 'src/app/toast-v2/models/toast-v2.model';

@Component({
	selector: 'app-add-card',
	templateUrl: './add-card.component.html',
	styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public token: string;
	public planDetails: any;
	public cardDetails: UntypedFormGroup;
	public expirationDate: UntypedFormControl;
	public cardNumber: UntypedFormControl;

	public submitting: boolean;

	public currentRoute: string;
	protected sharedStore: Store<SharedState>;
	public loading: boolean = false;
	public isNewCard: boolean = true;
	public lastdigit: string;
	public cardType: string;
	public currentCard: any;
	public noTermsOption: boolean = false;
	public isDefault: boolean = false;

	public formControlsRequests: any = {};

	public countries: Country[];
	public dropdownCountries: any[];

	constructor(
		private injector: Injector,
		private formBuilder: UntypedFormBuilder,
		private toastService: ToastNotificationService,
		private billingService: PaymentServiceApi,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private _location: Location,
		public backOfficeService: BackOfficeService,
		public dialog: MatDialog
	) {
		this.sharedStore = this.injector.get<Store<SharedState>>(Store);
	}

	ngOnInit(): void {
		this.createForm();
		this.onFormNameChange();
		this.getActivatedRoute();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public noWhitespaceValidator(control: UntypedFormControl) {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return { whitespace: true };
	}

	public backClicked() {
		this._location.back();
	}

	public createForm(): void {
		this.cardDetails = this.formBuilder.group({
			cardName: new UntypedFormControl('', Validators.required),
			cardNumber: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern('^(?=.*[0-9])[ 0-9]+$'),
				Validators.minLength(17)
			]),
			securityCode: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern('^[0-9]*$'),
				Validators.minLength(3)
			]),
			expirationMonth: new UntypedFormControl('', Validators.required),
			expirationYear: new UntypedFormControl('', Validators.required),
			firstname: new UntypedFormControl(''),
			lastname: new UntypedFormControl(''),
			companyName: new UntypedFormControl(''),
			phone: new UntypedFormControl(''),
			firstAddress: new UntypedFormControl(''),
			secondAddress: new UntypedFormControl(''),
			city: new UntypedFormControl(''),
			zipCode: new UntypedFormControl(''),
			country: new UntypedFormControl(''),
			isDefault: new UntypedFormControl(false),
			termsAndCondition: new UntypedFormControl(
				false,
				Validators.required
			)
		});

		this.expirationDate = new UntypedFormControl('', [
			Validators.required,
			Validators.pattern('^(?=.*[0-9])[/0-9]+$'),
			Validators.minLength(6),
			Validators.maxLength(7)
		]);
		this.formControlsRequests = [
			'cardName',
			'cardNumber',
			'securityCode',
			'securityCode',
			'expirationMonth',
			'expirationYear',
			'firstAddress',
			'secondAddress',
			'city',
			'zipCode',
			'country'
		];
	}

	public loadUserCreditCards(): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.getPaymentCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((details: any) => {
				if (details) {
					this.currentCard = details.filter(
						card => card.last4Digits == this.lastdigit
					);
					if (this.currentCard) {
						this.getMySelectedCard();
					}
				}
				this.loading = false;
				this.sharedStore.dispatch(new HideGlobalSpinner());
			});
	}

	public getActivatedRoute() {
		this.activatedRoute.queryParams
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(params => {
				if (params && params?.lastdigit && params?.type) {
					this.lastdigit = encodeURIComponent(params['lastdigit']);
					this.cardType = encodeURIComponent(params['type']);
					this.getMySelectedCard();
				}
				if (params && params?.default) {
					this.isDefault = params?.default;
					this.onMarkDefault(params?.default);
				}
			});
	}

	public getMySelectedCard(): void {
		const payload: any = {
			cardLastFourDigits: this.lastdigit,
			cardType: this.cardType
		};
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.getMyCardDetails(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					this.isNewCard = false;
					const billingInfo = details?.billingInfo;
					const creditCard = details?.creditCard;
					let names = billingInfo.firstName;
					names += names != '' ? ' ' : '';
					names += billingInfo.lastName;
					this.cardDetails.controls['cardName'].setValue(`${names}`);
					this.cardDetails.controls['cardNumber'].setValue(
						'**** **** **** ' + creditCard.cardLastFourDigits
					);
					this.cardDetails.controls['securityCode'].setValue('');
					this.cardDetails.controls['expirationMonth'].setValue(
						creditCard.expirationMonth
					);
					this.cardDetails.controls['expirationYear'].setValue(
						creditCard.expirationYear
					);
					this.cardDetails.controls['firstAddress'].setValue(
						billingInfo.address1 || ''
					);
					this.cardDetails.controls['secondAddress'].setValue(
						billingInfo.address2 || ''
					);
					this.cardDetails.controls['city'].setValue(
						billingInfo.city || ''
					);
					this.cardDetails.controls['zipCode'].setValue(
						billingInfo.zip || ''
					);
					this.cardDetails.controls['isDefault'].setValue(false);
					this.expirationDate.setValue(
						`${creditCard.expirationMonth}/${creditCard.expirationYear}`
					);
					this.isDefault = billingInfo?.isdefault ? true : false;

					this.cardDetails.controls['cardNumber'].disabled;
					this.cardDetails.controls['cardNumber'].clearValidators();
					this.cardDetails.controls[
						'cardNumber'
					].updateValueAndValidity();

					this.cardDetails.controls['firstAddress'].disabled;
					this.cardDetails.controls['firstAddress'].clearValidators();
					this.cardDetails.controls[
						'firstAddress'
					].updateValueAndValidity();

					this.cardDetails.controls['secondAddress'].disabled;
					this.cardDetails.controls[
						'secondAddress'
					].clearValidators();
					this.cardDetails.controls[
						'secondAddress'
					].updateValueAndValidity();

					this.cardDetails.controls['city'].disabled;
					this.cardDetails.controls['city'].clearValidators();
					this.cardDetails.controls['city'].updateValueAndValidity();

					this.cardDetails.controls['zipCode'].disabled;
					this.cardDetails.controls['zipCode'].clearValidators();
					this.cardDetails.controls[
						'zipCode'
					].updateValueAndValidity();

					this.cardDetails.controls['companyName'].disabled;
					this.cardDetails.controls['companyName'].clearValidators();
					this.cardDetails.controls[
						'companyName'
					].updateValueAndValidity();

					this.cardDetails.controls['country'].disabled;
					this.cardDetails.controls['country'].clearValidators();
					this.cardDetails.controls[
						'country'
					].updateValueAndValidity();
				},
				error => {
					console.log(error);
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public onFormNameChange(): void {
		this.cardDetails.valueChanges
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				//console.log(this.cardDetails.value);
			});
	}

	public onMarkDefault(isChecked: boolean) {
		this.cardDetails.controls['isDefault'].setValue(isChecked);
	}

	public onAcceptTerms(isChecked: boolean) {
		this.noTermsOption = isChecked;
	}

	public addPaymentCard(): void {
		if (this.cardDetails.invalid) {
			this.toastService.sendCustomToast(
				'Please fill the required fields.',
				ToastV2TypeEnum.ERROR,
				8000,
				'Invalid inputs!'
			);
		} else {
			this.loading = true;
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			let names = this.cardDetails.controls['cardName'].value.split(' ');
			const country = this.cardDetails.get('country').value;
			const card_detail: any = {
				cardNumber: this.cardDetails
					.get('cardNumber')
					.value.replace(/\W/gi, ''),
				securityCode: this.cardDetails.get('securityCode').value,
				expirationMonth: this.cardDetails.get('expirationMonth').value,
				expirationYear: this.cardDetails.get('expirationYear').value,
				firstName: names[0],
				lastName: names.length > 1 ? names[1] : names[0],
				address1: this.cardDetails.get('firstAddress').value,
				address2: this.cardDetails.get('secondAddress').value,
				city: this.cardDetails.get('city').value,
				country: country.value,
				zipCode: this.cardDetails.get('zipCode').value,
				isDefault: this.cardDetails.get('isDefault').value
			};
			this.billingService
				.addPaymentCards(card_detail)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					detailResponse => {
						this.openConfirmModal({
							title: 'Card Details Added!',
							message:
								'You have successfully added a new card into your account.'
						});
					},
					({ error }) => {
						this.toastService.sendErrorToast(
							'Error occured while adding your card information.',
							8000
						);
					},
					() => {
						this.loading = false;
						this.sharedStore.dispatch(new HideGlobalSpinner());
					}
				);
		}
	}
	public updatePaymentCard(): void {
		if (this.cardDetails.invalid) {
			this.toastService.sendCustomToast(
				'Please fill the required fields.',
				ToastV2TypeEnum.ERROR,
				8000,
				'Invalid inputs!'
			);
		} else {
			this.loading = true;
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			let names = this.cardDetails.get('cardName').value.split(' ');
			const country = this.cardDetails.get('country').value;
			const card_detail: any = {
				cardLastFourDigit: this.lastdigit,
				currentCardType: this.cardType,
				expirationMonth: this.cardDetails.get('expirationMonth').value,
				expirationYear: this.cardDetails.get('expirationYear').value,
				securityCode: this.cardDetails.get('securityCode').value,
				firstName: names[0],
				lastName: names.length > 1 ? names[1] : names[0],
				address1: this.cardDetails.get('firstAddress').value,
				address2: this.cardDetails.get('secondAddress').value,
				city: this.cardDetails.get('city').value,
				country: country.value,
				zip: this.cardDetails.controls['zipCode'].value,
				isDefault: this.cardDetails.get('isDefault').value,
				personalIdentificationNumber: ''
			};
			this.billingService
				.updatePaymentCards(card_detail)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					detailResponse => {
						this.openConfirmModal({
							title: 'Card Edited!',
							message:
								'You have successfully updated your card details.'
						});
					},
					({ error }) => {
						this.toastService.sendErrorToast(
							'Error occured while processing your card information.',
							8000
						);
					},
					() => {
						this.loading = false;
						this.sharedStore.dispatch(new HideGlobalSpinner());
					}
				);
		}
	}

	public openConfirmModal(payload: any): void {
		const dialogRef = this.dialog.open(CompleteModalComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: payload.title,
			text: payload.message,
			closeBtn: false,
			actionBtn: false
		};
		dialogRef.afterClosed().subscribe(() => {
			this.backClicked();
		});
	}
}
