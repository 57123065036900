import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';
import {
	RoleBasedControlActionEnum,
	UserRoleEnum
} from '../models/user-role.model';

@Directive({
	selector: '[appRoleBasedControl]'
})
export class RoleBasedControlDirective {
	@Input() roleActionType: RoleBasedControlActionEnum =
		RoleBasedControlActionEnum.OTHERS;
	@Input() disableRoleBasedDirective: boolean = false;

	private actionsToDisableForStandard: RoleBasedControlActionEnum[] = [
		RoleBasedControlActionEnum.CANCEL_SUBSCRIPTION,
		RoleBasedControlActionEnum.PAYMENT
	];
	private actionsToDisableForAdmin: RoleBasedControlActionEnum[] = [
		RoleBasedControlActionEnum.CANCEL_SUBSCRIPTION
	];

	constructor(
		private el: ElementRef,
		private authV2Service: AuthenticationV2Service
	) {}

	ngOnInit() {
		this.updateRoleControl();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['disableRoleBasedDirective'] || changes['roleActionType']) {
			this.updateRoleControl();
		}
	}

	private updateRoleControl(): void {
		if (this.disableRoleBasedDirective) {
			this.enableElement();
			return;
		}

		const userRole = this.authV2Service.getUserRole();

		if (userRole == UserRoleEnum.READ_ONLY) {
			this.disableElement();
			return;
		}
		if (userRole == UserRoleEnum.ADMIN) {
			if (this.actionsToDisableForAdmin.includes(this.roleActionType)) {
				this.disableElement();
			}
			return;
		}
		if (userRole == UserRoleEnum.STANDARD) {
			if (
				this.actionsToDisableForStandard.includes(this.roleActionType)
			) {
				this.disableElement();
			}
			return;
		}
		// ELSE HE IS OWNER
		// else { }
	}

	private enableElement(): void {
		this.el.nativeElement.disabled = false;
		this.el.nativeElement.classList.remove('disabled');
		this.el.nativeElement.classList.remove('disabled-low-opacity');
	}

	private disableElement(): void {
		this.el.nativeElement.disabled = true;
		this.el.nativeElement.classList.add('disabled');
		this.el.nativeElement.classList.add('disabled-low-opacity');
	}
}
