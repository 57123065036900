<div class="date-picker-button-wrapper">
	<label for="datePicker" class="date-picker-button">{{ label }}</label>
	<input
		id="datePicker"
		class="hidden-date-picker"
		[formControl]="dateControl"
		ngxDaterangepickerMd
		type="text"
		[singleDatePicker]="false"
		[autoApply]="true"
	/>
</div>
