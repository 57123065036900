import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-select-dropdown-v2',
	templateUrl: './select-dropdown-v2.component.html',
	styleUrls: ['./select-dropdown-v2.component.scss']
})
export class SelectDropdownV2Component implements OnInit {
	@Input() inputLabel: string = '';
	@Input() dropdownControl: UntypedFormControl = new UntypedFormControl();
	@Input() options: any[];
	@Input() disabled: boolean = false;
	@Input() showNullOption: boolean = true;
	@Input() hideLabel: boolean = false;
	@Input() disableOptionCentering: boolean = true;
	@Input() titleKey: string = 'name';
	@Input() comparisonKey: string = 'value';
	@Input() nullOptionName: string = 'Select';

	@Output() selectionEvent = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	compareOptions = (o1: any, o2: any): boolean => {
		if (o1 == null || o2 == null) {
			return false;
		}
		return o1?.[this.comparisonKey] == o2?.[this.comparisonKey];
	};

	public optionSelected(event: any) {
		this.selectionEvent.emit(event);
	}
}
