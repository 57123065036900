import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Observer, of } from 'rxjs';
import { UserSetting } from '../../back-office/models/user-setting.model';
import { Card } from '../../back-office/models/offers-table';
import { OfferModel } from '../../back-office/models/offer-model';
import { ClientModel } from '../../back-office/models/client-model';
import { BaseApiUrl } from '../base-api-urls';
import { BaseOffersDto, Country, IBillingInfo } from './back-office.models';
import { FiledCreditCard } from '../../user-management/components/filed-cards/set-creditcard.models';
import { BillingAddress } from 'src/app/user-management/shared/billing-and-payment/billing-and-payment.models';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import { AccountManagersInterface } from '../../back-office/models/account-managers.interface';
import { AssignedBOsInterface } from '../../back-office/models/assigned-bos.interface';
import { BillingPlan } from 'src/app/user-management/models/billing-plan.interface';
import {
	BillingsTableInterface,
	SimpleCurrency
} from '../../back-office/models/billings-table.interface';
import { SavePasswordRequestModel } from 'src/app/user-management/new-sprint/components/my-profile/model/profile.model';

@Injectable({
	providedIn: 'root'
})
export class BackOfficeService {
	private readonly baseURL = `${BaseApiUrl.Users}`;
	private readonly baseURLEcommerce = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}`;
	public curency = new BehaviorSubject<SimpleCurrency[]>(null);
	public castcurency = this.curency.asObservable();

	public billingUpdatedSuccess$: BehaviorSubject<
		boolean
	> = new BehaviorSubject(false);

	public signUpGetStartedActive$: BehaviorSubject<
		string
	> = new BehaviorSubject('idle');

	constructor(protected http: HttpClient) {}

	public getSettings(id: number): Observable<UserSetting> {
		return this.http.get<UserSetting>(
			`${this.baseURL}userSettings/get/${id}`
		);
	}

	public editSettings(request: UserSetting): Observable<void> {
		const body = {
			id: request.id,
			languageName: request.language,
			dataSharing: request.dataSharing,
			notificationDelivery: +request.notificationDelivery,
			notificationFrequency: +request.notificationFrequency
		};

		return this.http.put<void>(
			`${this.baseURL}userSettings/edit`,
			body as any
		);
	}

	public getAllBillings(): Observable<BillingsTableInterface[]> {
		return this.http.get<BillingsTableInterface[]>(
			`${BaseApiUrl.Subscription}billings`
		);
	}

	public getAllCards(): Observable<FiledCreditCard[]> {
		return this.http.get<FiledCreditCard[]>(
			`${BaseApiUrl.Subscription}paymentcards/my`
		);
	}

	public makeCardPrimary(cardId: string): Observable<void> {
		return this.http.put<void>(
			`${BaseApiUrl.Subscription}paymentCards/${cardId}/default`,
			{}
		);
	}

	public deletePaymentCard(cardId: string): Observable<void> {
		return this.http.delete<void>(
			`${BaseApiUrl.Subscription}paymentCards/${cardId}`,
			{}
		);
	}

	public addNewPaymentCard(
		cardToken: string,
		makePrimary: boolean
	): Observable<boolean> {
		return this.http.post<boolean>(
			`${BaseApiUrl.Subscription}paymentCards`,
			{ SourceCardToken: cardToken, MakePrimary: makePrimary }
		);
	}

	public getBillingPlan(): Observable<BillingPlan> {
		return of();
		// return this.http.get<BillingPlan>(`${BaseApiUrl.Subscription}billings/my/billing-plan`);
	}

	public getAccountManagers(): Observable<AccountManagersInterface[]> {
		return this.http.get<AccountManagersInterface[]>(
			`${this.baseURL}backOfficeUsers/accountManagers`
		);
	}

	public getUnmanagedBOs(id: number): Observable<AccountManagersInterface[]> {
		return this.http.get<AccountManagersInterface[]>(
			`${this.baseURL}backOfficeUsers/accountManagers/${id}/unmanagedBOs`
		);
	}

	public getManagedBOs(id: number): Observable<AccountManagersInterface[]> {
		return this.http.get<AccountManagersInterface[]>(
			`${this.baseURL}backOfficeUsers/accountManagers/${id}/managedBOs`
		);
	}

	public updateAssignedBOs(
		id: number,
		BOs: AssignedBOsInterface
	): Observable<void> {
		return this.http.put<void>(
			`${this.baseURL}backOfficeUsers/accountManagers/${id}`,
			BOs
		);
	}

	public getContactId(contactId: number): Observable<ClientModel> {
		return this.http.get<ClientModel>(
			`${this.baseURL}contacts?contactId=${contactId}`
		);
	}

	public createOffer(attach: OfferModel): Observable<void> {
		return this.http.post<void>(`${this.baseURL}offers`, attach);
	}

	public addCardForCurrentAccount(cardToken: Card): Observable<void> {
		const body = {
			sourceCardToken: cardToken
		};

		return this.http.post<void>(`${this.baseURL}contacts/cardinfo`, body);
	}

	public getChargeBeeLink(): Observable<string> {
		return this.http.get<string>(
			`${BaseApiUrl.Subscription}PaymentCards/chargebeePortal`
		);
	}

	public confirmPaymentCard(): Observable<HttpResponse<any>> {
		return this.http.post<any>(
			`${BaseApiUrl.Subscription}PaymentCards/check/withPW`,
			{},
			{ observe: 'response' }
		);
	}

	public confirmFreemiumStatus(
		userId: string
	): Observable<HttpResponse<any>> {
		return this.http.post<any>(
			`${BaseApiUrl.Subscription}PaymentCards/checkFreeUseExpired`,
			{ userId },
			{ observe: 'response' }
		);
	}

	public updateBillingAccount(
		billingReq: IBillingInfo
	): Observable<HttpResponse<void>> {
		return this.http.put<void>(
			`${BaseApiUrl.Subscription}accounts`,
			billingReq,
			{ observe: 'response' }
		);
	}

	public addCard(cardToken: Card, id: number): Observable<void> {
		const body = {
			sourceCardToken: cardToken
		};

		return this.http.post<void>(
			`${this.baseURL}contacts/${id}/cardinfo`,
			body
		);
	}

	public attachOffer(offerId: number): Observable<void> {
		return this.http.put<void>(`${this.baseURL}offers/${offerId}`, {});
	}

	public activate(id: number): Observable<boolean> {
		return this.http.post<boolean>(`${this.baseURL}accounts`, {
			ContactId: id
		});
	}

	public deactivate(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseURL}contacts/${id}/accounts`);
	}

	public deleteCard(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseURL}contacts/card/${id}`);
	}

	public updateBaseOfferLandingPage(
		offerId: number,
		status: boolean
	): Observable<void> {
		return this.http.put<void>(
			`${BaseApiUrl.Subscription}baseOffers/${offerId}/landingPageSubscriptionAllowed`,
			{
				IsLandingPageSubscriptionAllowed: status
			}
		);
	}

	public updateFreemiumOfferLandingPage(
		offerId: number,
		status: boolean
	): Observable<void> {
		return this.http.put<void>(
			`${BaseApiUrl.Subscription}freemiumOffers/${offerId}/landingPageSubscriptionAllowed`,
			{
				IsLandingPageSubscriptionAllowed: status
			}
		);
	}

	public editBaseOffer(
		baseOfferId: number,
		baseOffer: BaseOffersDto
	): Observable<boolean> {
		return this.http.put<boolean>(
			`${this.baseURL}baseOffers/${baseOfferId}`,
			baseOffer
		);
	}

	public addCardNoPw(firstTimeCardDto: any): Observable<boolean> {
		return this.http.post<boolean>(
			`${BaseApiUrl.Subscription}paymentCards/noPW`,
			firstTimeCardDto
		);
	}

	public getBusinessOwnerCards(): Observable<FiledCreditCard[]> {
		return this.http.get<FiledCreditCard[]>(
			`${this.baseURL}paymentcards/my`
		);
	}

	public getAllCountriesList(): Observable<Country[]> {
		return this.http.get<Country[]>(`${BaseApiUrl.Subscription}countries`);
	}

	public getBillingAccountDetails(): Observable<BillingAddress> {
		return this.http.get<BillingAddress>(
			`${BaseApiUrl.Subscription}accounts`
		);
	}

	public getInvoiceHistory(): Observable<InvoiceHistory[]> {
		return this.http.get<InvoiceHistory[]>(
			`${BaseApiUrl.Subscription}billings/my/invoices`
		);
	}

	public getMyInvoiceHistory(): Observable<InvoiceHistory[]> {
		return this.http.get<InvoiceHistory[]>(
			`${BaseApiUrl.Subscription}Billings/my/billingInvoices`
		);
	}

	public getMyInvoiceByTransactionId(
		transactionId: string
	): Observable<InvoiceHistory[]> {
		return this.http.get<InvoiceHistory[]>(
			`${BaseApiUrl.Subscription}Billings/${transactionId}/transaction`
		);
	}

	public getMyInvoiceByBillingId(
		billingId: string
	): Observable<InvoiceHistory[]> {
		return this.http.get<InvoiceHistory[]>(
			`${BaseApiUrl.Subscription}Billings/${billingId}`
		);
	}

	public payInvoice(
		chargebeeInvoiceId: string
	): Observable<InvoiceHistory[]> {
		return this.http.put<InvoiceHistory[]>(
			`${BaseApiUrl.Subscription}billings/my/invoices/pay-invoice`,
			{
				ChargebeeInvoiceId: chargebeeInvoiceId
			}
		);
	}

	public getAllCountries(): Observable<Country[]> {
		return this.http.get<Country[]>(`${BaseApiUrl.Subscription}countries`);
	}

	public downloadInvoiceHistoryPdf(
		chargebeeInvoiceId: string,
		inlineDisplay: string
	) {
		return this.http.get(
			`${BaseApiUrl.Subscription}billings/my/invoices/pdf`,
			{
				params: {
					chargebeeInvoiceId: chargebeeInvoiceId,
					inlineDisplay: inlineDisplay
				}
			}
		);
	}

	public getUserDetails(userId: number): Observable<any> {
		return this.http.get<number>(`${BaseApiUrl.Users}Users/${userId}`);
	}

	public updateUserDetails(details: any): Observable<void> {
		return this.http.put<void>(
			`${BaseApiUrl.Users}Users/update-profle`,
			details
		);
	}

	public getUserState(userId: number): Observable<number> {
		return of(1);
		// return this.http.get<number>(`${BaseApiUrl.Users}Users/${userId}/user-account-status`);
	}

	public subscribeToOfer(details: any): Observable<void> {
		return this.http.post<void>(
			`${BaseApiUrl.Subscription}subscriptions/subscribeToOffer`,
			details
		);
	}

	public registerNewUser(details: any): Observable<any> {
		return this.http.post<any>(
			`${BaseApiUrl.SocialMessengerPython}users/sign-up`,
			details
		);
	}

	public resendActivationEmail(
		token: string,
		priceId: string
	): Observable<void> {
		return this.http.post<void>(
			`${BaseApiUrl.SocialInfluencerPython}users/resend-email?token=${token}&price_id=${priceId}`,
			{}
		);
	}

	public getAllInstalledPlatforms(): Observable<any> {
		return this.http.get<any>(
			`${this.baseURLEcommerce}channels/getallinstalledchannels`
		);
	}

	public integrationInstall(channel: string, request: any): Observable<any> {
		return this.http.post<any>(
			`${this.baseURLEcommerce}export/oauth/${channel}/preinstall`,
			request
		);
	}

	public getProfileDetails(token: string): Observable<any> {
		return this.http.get<any>(
			`${BaseApiUrl.SocialMessengerPython}users/view-profile`,
			{ headers: new HttpHeaders({ Authorization: token }) }
		);
	}

	public updateProfileDetails(form: FormData): Observable<any> {
		return this.http.patch(
			`${BaseApiUrl.SocialInfluencerPython}users/update-profile`,
			form
		);
	}

	public updatePassword(payload: SavePasswordRequestModel): Observable<any> {
		return this.http.patch(
			`${BaseApiUrl.SocialInfluencerPython}users/update-password`,
			payload
		);
	}
}
