import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-custom-date-range-picker-button',
	templateUrl: './custom-date-range-picker-button.component.html',
	styleUrls: ['./custom-date-range-picker-button.component.scss']
})
export class CustomDateRangePickerButtonComponent implements OnInit {
	@Input('label') public label: String;
	@Input('dateControl')
	public dateControl: UntypedFormControl = new UntypedFormControl();

	public selectedDateRange: any;

	constructor() {}

	ngOnInit(): void {}
}
