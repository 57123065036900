import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import {
	GenericTemplateDataModel,
	TemplateDataModel,
	TemplateDataResponseModel
} from '../component-pieces/template-selection/models/template-data.model';
import { SequenceModel } from '../models/outreach-sequence.model';
import { OutreachSequenceSummaryModel } from '../models/outreach-sequence-new.model';
import { PaginationResponseModel } from 'src/app/shared/models/pagination.model';

@Injectable({
	providedIn: 'root'
})
export class OutreachSequenceService {
	constructor(private http: HttpClient) {}

	// * SEQUENCE APIs
	public getAllSequences(
		queryParams: HttpParams
	): Observable<{
		data: OutreachSequenceSummaryModel[];
		pagination: PaginationResponseModel;
	}> {
		return this.http.get<{
			data: OutreachSequenceSummaryModel[];
			pagination: PaginationResponseModel;
		}>(`${BaseApiUrl.SocialMessengerPython}sequences`, {
			params: queryParams
		});
	}

	public createNewSequence(
		payload: SequenceModel
	): Observable<{ data: { sequence_id: number }; message: string }> {
		return this.http.post<any>(
			`${BaseApiUrl.SocialMessengerPython}sequences`,
			payload
		);
	}

	public getSpecificSequence(
		sequenceId: number
	): Observable<{ data: SequenceModel }> {
		return this.http.get<{ data: SequenceModel }>(
			`${BaseApiUrl.SocialMessengerPython}sequences/${sequenceId}`
		);
	}

	public editSequence(
		sequenceId: number,
		payload: SequenceModel
	): Observable<any> {
		return this.http.put<any>(
			`${BaseApiUrl.SocialMessengerPython}sequences/${sequenceId}`,
			payload
		);
	}

	public deleteSequence(sequenceId: number): Observable<any> {
		return this.http.delete<any>(
			`${BaseApiUrl.SocialMessengerPython}sequences/${sequenceId}`
		);
	}

	public startOutreach(payload: {
		sequence_id: number;
		campaign_id: number;
	}): Observable<any> {
		return this.http.post<any>(
			`${BaseApiUrl.SocialMessengerPython}sequences/activate`,
			payload
		);
	}

	public deactivateSequence(payload: {
		campaign_id: number;
		sequence_id: number;
	}): Observable<any> {
		return this.http.patch<any>(
			`${BaseApiUrl.SocialMessengerPython}sequences/deactivate`,
			payload
		);
	}

	public getAvailableTriggerTypes(
		queryParams: HttpParams
	): Observable<{ triggers: string[] }> {
		return this.http.get<{ triggers: string[] }>(
			`${BaseApiUrl.SocialMessengerPython}sequences/triggers`,
			{ params: queryParams }
		);
	}

	// * END OF SEQUENCE APIs

	// * TEMPLATE APIs

	public uploadImage(data): Observable<Object> {
		const url = new URL(
			`${BaseApiUrl.SocialMessengerPython}templates/image`
		);
		return this.http.put(url.href, data);
	}

	public uploadFile(data): Observable<Object> {
		const url = new URL(
			`${BaseApiUrl.SocialMessengerPython}templates/attachments`
		);
		return this.http.put(url.href, data);
	}

	public getAllTemplates(): Observable<TemplateDataResponseModel> {
		return this.http.get<TemplateDataResponseModel>(
			`${BaseApiUrl.SocialMessengerPython}templates`
		);
	}

	public createNewTemplate(
		payload: GenericTemplateDataModel
	): Observable<{ message: string }> {
		return this.http.post<{ message: string }>(
			`${BaseApiUrl.SocialMessengerPython}templates`,
			payload
		);
	}

	public getSpecificTemplate(
		templateId: number
	): Observable<GenericTemplateDataModel> {
		return this.http.get<GenericTemplateDataModel>(
			`${BaseApiUrl.SocialMessengerPython}templates/${templateId}`
		);
	}

	public deleteTemplate(templateId: number): Observable<{ detail: string }> {
		return this.http.delete<{ detail: string }>(
			`${BaseApiUrl.SocialMessengerPython}templates/${templateId}`
		);
	}

	public sendTestTemplate(
		template: GenericTemplateDataModel
	): Observable<{ message: string }> {
		return this.http.post<{ message: string }>(
			`${BaseApiUrl.SocialMessengerPython}templates/test_template`,
			template
		);
	}

	// * END OF TEMPLATE APIs
}
