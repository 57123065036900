<div class="date-picker-wrapper">
	<div class="action-button cursor-pointer">
		<mat-form-field
			[matMenuTriggerFor]="selectMenu"
			#selectMenuTrigger="matMenuTrigger"
			appearance="outline"
			class="outline"
			[floatLabel]="'never'"
		>
			<input
				matInput
				class="search-text"
				type="text"
				[placeholder]="'Select Date Range'"
				[formControl]="dateControl"
				autocomplete="off"
				readonly
			/>
		</mat-form-field>
	</div>

	<mat-menu
		#selectMenu="matMenu"
		class="custom-calendar-menu"
		xPosition="{{ 'after' }}"
	>
		<div class="wrapper" (click)="$event.stopPropagation()">
			<div class="tab-body">
				<div class="w-100" style="padding: 10px;">
					<app-select-drp-wo-input
						[inputPlaceholder]="'Group By'"
						[dropdownControl]="groupByControl"
						[options]="groupByDateOptions"
						(selectionEvent)="onGroupByChange($event)"
					></app-select-drp-wo-input>
				</div>

				<!-- *date picker -->
				<div class="active-tab date">
					<ngx-daterangepicker-material
						(choosedDate)="choseDate($event)"
						[singleDatePicker]="false"
						[autoApply]="true"
					></ngx-daterangepicker-material>
				</div>
			</div>
		</div>
	</mat-menu>
</div>
