import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { PlansDataModel } from 'src/app/shared-components/models/plans-data.model';

declare const Stripe: (arg0: string) => any;

@Injectable({
	providedIn: 'root'
})
export class AuthPaymentService {
	private static STRIPE_KEY = environment.STRIPE_KEY;

	constructor(private http: HttpClient) {}

	public redirectToCheckout(sessionId: string) {
		// type casting the key is mandatory, as stripe() accepts only string
		const stripe = Stripe(`${AuthPaymentService.STRIPE_KEY}`);
		stripe.redirectToCheckout({ sessionId });
	}

	public requestSession(priceId: string, token: string) {
		let headers: HttpHeaders = new HttpHeaders({
			Authorization: 'Bearer ' + token
		});
		return this.http.post(
			`${environment.SocialInfluencerApi.SocialInfluencerPython}subscriptions/session?price_id=${priceId}`,
			{},
			{ headers: headers }
		);
	}

	public redirectToStripe(plan: PlansDataModel): Observable<any> {
		return this.http.post(
			`${BaseApiUrl.SocialMessengerPython}subscriptions/session/new`,
			plan
		);
	}

	public redirectToStripeUpdate(product_price_id: string): Observable<any> {
		return this.http.post(
			`${BaseApiUrl.SocialMessengerPython}subscriptions/${product_price_id}/upgrade-downgrade`,
			{}
		);
	}

	public shopconnect(link: string): Observable<string> {
		return this.http.get<string>(
			`${environment.SocialInfluencerApi.SocialInfluencerPython}shopifyApp/load?shop=${link}`
		);
	}

	// SHOPIFY INTEGRATION COMPONENT API
	public shopifyAuth(params: any): Observable<any> {
		return this.http.get<any>(
			`${environment.SocialInfluencerApi.SocialMessengerPython}oauth/shopify/install?shop=${params.shop}&code=${params.code}&hmac=${params.hmac}&timestamp=${params.timestamp}&host=${params.host}`
		);
	}
}
