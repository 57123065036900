import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { DiscoveryCreatorApiModel } from '../models/discovery-creator.model';
import {
	DiscoveryFilterModel,
	DiscoveryListsStateModel,
	DiscoveryStateModel
} from '../models/discovery-filter.model';
import { DiscoveryCreditsResponseModel } from '../models/discovery-credits.model';
import {
	DiscoveryListCreatorsResponseModel,
	DiscoveryListOwnerModel,
	UnlockBulkEmailResponse
} from 'src/app/shared-components/models/discovery/discovery-list.model';
import {
	CreateListResponseModel,
	UploadedListDataModel
} from 'src/app/shared-components/models/discovery/discovery-uploaded-list-data.model';

@Injectable({
	providedIn: 'root'
})
export class DiscoveryService {
	public filterSubject: BehaviorSubject<
		DiscoveryFilterModel
	> = new BehaviorSubject<DiscoveryFilterModel>({
		selectedPlatform: 'Instagram'
	});
	public discoveryState: DiscoveryStateModel = {
		discoveryCreatorsPageNumber: 0,
		pageSize: 25,
		discoverySelectedCreatorIds: [],
		bulkSelectCount: 0,
		selectedView: 'table'
	};

	public discoveryListsState: DiscoveryListsStateModel = {
		pageNumber: 0,
		pageSize: 10,
		channel: [],
		listOwners: [],
		sortBy: {
			sort_by_last_update: -1
		}
	};

	public updateFilter(model: DiscoveryFilterModel) {
		this.filterSubject.next(model);
	}

	constructor(private http: HttpClient) {}

	public clearState(): void {
		this.discoveryState = {
			discoveryCreatorsPageNumber: 0,
			pageSize: 25,
			discoverySelectedCreatorIds: [],
			bulkSelectCount: 0,
			selectedView: 'table'
		};
		this.discoveryListsState = {
			pageNumber: 0,
			pageSize: 10,
			channel: [],
			listOwners: [],
			sortBy: {}
		};
		this.filterSubject.next({});
	}

	//************************ API(s) FOR DISCOVERY ************************//
	public getDiscoveryCreatorCount(queryParams?: HttpParams): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/searchable-count`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href, { params: queryParams });
	}

	public getDiscoveryCreators(
		queryParams?: HttpParams
	): Observable<DiscoveryCreatorApiModel> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}profiles/`);
		return this.http.get<DiscoveryCreatorApiModel>(url.href, {
			params: queryParams
		});
	}

	public getAllCampaigns(
		pageNumber: number,
		pageSize: number
	): Observable<any> {
		return this.http.get<any>(
			`${BaseApiUrl.SocialMessengerPython}campaigns?page_number=${pageNumber}&page_size=${pageSize}`
		);
	}

	public getAllJobs(queryParams: HttpParams): Observable<any> {
		return this.http.get<any>(
			`${BaseApiUrl.SocialMessengerPython}campaigns/jobs`,
			{ params: queryParams }
		);
	}

	public getEmailState(id): Observable<any> {
		return this.http.get(
			`${BaseApiUrl.SocialMessengerPython}profiles/creators/available?discovery_id=${id}`
		);
	}

	public sendJobToList(jobIds: number[], listId: number): Observable<any> {
		let payload = { job_ids: jobIds };

		const url = new URL(
			`${BaseApiUrl.SocialMessengerPython}applications/list/${listId}`
		);
		return this.http.post<any>(url.href, payload);
	}

	public sendJobsToCreators(
		jobIds: number[],
		influencerIds: number[],
		bulkSelectCount?: number,
		filterString?: string
	): Observable<any> {
		let payload = {
			discovery_ids: influencerIds,
			job_ids: jobIds,
			type: 'individual'
		};
		if (bulkSelectCount) {
			payload['size'] = bulkSelectCount;
			payload['type'] = 'bulk';
		}
		if (filterString) {
			payload['filter'] = filterString;
		}
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}applications`);
		return this.http.post<any>(url.href, payload);
	}

	public getSubCategories(categories: any[]): Observable<any> {
		let url: URL = new URL(
			`${BaseApiUrl.SocialMessengerPython}discovery/subcategories`
		);
		categories.forEach(x => {
			url.searchParams.append('category', x.name);
		});
		return this.http.get<any>(url.href);
	}

	public getHashtags(inputStr?: string): Observable<any> {
		let url: URL = new URL(
			`${BaseApiUrl.SocialMessengerPython}discovery/hashtags`
		);
		if (inputStr) {
			url.searchParams.append('query', inputStr);
		}
		return this.http.get<any>(url.href);
	}

	public advancedSearch(payload): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/advance-search`;
		const url = new URL(urlString);

		return this.http.post<any>(url.href, payload);
	}

	//************************ API(s) FOR DISCOVERY LISTS SECTION ************************//

	public getListOwners(): Observable<{ data: DiscoveryListOwnerModel[] }> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/owners`;
		const url = new URL(urlString);

		return this.http.get<{ data: DiscoveryListOwnerModel[] }>(url.href);
	}

	public createNewList(
		name: string,
		influencerIds: (string | number)[],
		bulkSelectCount?: number,
		filterString?: string
	): Observable<any> {
		const url = `${BaseApiUrl.SocialMessengerPython}lists`;
		let postBody = { name: name, discovery_ids: influencerIds };

		if (bulkSelectCount) {
			postBody['size'] = bulkSelectCount;
		}
		if (filterString) {
			postBody['filter'] = filterString;
		}
		return this.http.post<any>(url, postBody);
	}

	public editListName(
		listId: number,
		name: string,
		description: string
	): Observable<any> {
		const payload = {
			name: name,
			description: description
		};
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}`;
		const url = new URL(urlString);

		return this.http.patch<any>(url.href, payload);
	}

	public deleteList(listId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}`;
		const url = new URL(urlString);

		return this.http.delete<any>(url.href);
	}

	public duplicateList(listId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}`;
		const url = new URL(urlString);

		return this.http.put<any>(url.href, {});
	}

	public getListCreators(
		listId: number,
		queryParams: HttpParams
	): Observable<DiscoveryListCreatorsResponseModel> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}/creators`;
		const url = new URL(urlString);

		return this.http.get<DiscoveryListCreatorsResponseModel>(url.href, {
			params: queryParams
		});
	}

	public unlockEmail(creatorId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/creators/${creatorId}?fields=email`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public getUserTags() {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/tags`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public createTag(payload): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/tags`;
		const url = new URL(urlString);

		return this.http.post(url.href, payload);
	}

	public assignTag(payload): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/tags/assign`;
		const url = new URL(urlString);

		return this.http.post(url.href, payload);
	}

	public deleteTags(tag_id: number, payload) {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/tags/${tag_id}`;
		const url = new URL(urlString);

		return this.http.delete(url.href, payload);
	}

	public bulkUnlockEmail(payload): Observable<UnlockBulkEmailResponse> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/creators/emails`;
		const url = new URL(urlString);

		return this.http.post<UnlockBulkEmailResponse>(url.href, payload);
	}

	public getAllCreatorNames(listId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}lists/${listId}/usernames`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public exportCreators(payload): Observable<Blob> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/export`;
		const url = new URL(urlString);

		return this.http.post(url.href, payload, { responseType: 'blob' });
	}

	public deleteCreators(listId: number, payload): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}/creators`;
		const url = new URL(urlString);

		return this.http.post<any>(url.href, payload);
	}

	public getDemoFile(): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/file/sample.csv`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public validateCampName(name: string): Observable<any> {
		return this.http.get<any>(
			`${BaseApiUrl.SocialMessengerPython}campaigns/exists?name=${name}`
		);
	}

	//************************ API(s) FOR DISCOVERY IMPORT INFLUENCERS SECTION ************************//
	public getCredits(): Observable<DiscoveryCreditsResponseModel> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}credits`;
		const url = new URL(urlString);

		return this.http.get<DiscoveryCreditsResponseModel>(url.href);
	}

	public uploadFile(formData: FormData): Observable<UploadedListDataModel> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists`;
		const url = new URL(urlString);

		return this.http.put<UploadedListDataModel>(url.href, formData);
	}

	//************************ API(s) FOR DISCOVERY CREATORS SECTION ************************//
	public getSocial(id, platform): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/metrics?discovery_id=${id}&platform=${platform}`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public getEmailUnlockStatus(id): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/creators/${id}?fields=email`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public getSimilarProfiles(id: any, platform: string): Observable<any> {
		const url = new URL(
			`${BaseApiUrl.SocialMessengerPython}discovery/profile/similar?discovery_id=${id}&platform=${platform}`
		);

		return this.http.get<any>(url.href);
	}

	private objToQueryString(obj: any) {
		if (typeof obj !== 'object' || Array.isArray(obj)) {
			throw new Error('Input must be an object');
		}

		let queryString = '';
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				if (value !== null && value !== undefined) {
					if (Array.isArray(value)) {
						queryString += value
							.map(
								v =>
									`${encodeURIComponent(
										key
									)}=${encodeURIComponent(v)}`
							)
							.join('&');
					} else {
						queryString += `${encodeURIComponent(
							key
						)}=${encodeURIComponent(value)}`;
					}
					queryString += '&';
				}
			}
		}

		if (queryString) queryString = '?' + queryString;

		return queryString.slice(0, -1); // Remove the last '&' character
	}

	public getSimilarProfilesByCat(cat): Observable<any> {
		const data = {
			sortByFollowers: -1,
			hashtags: cat
		};
		const queryString = this.objToQueryString(data);

		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/${queryString}`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}
}
