import { EmailDataModel } from 'src/app/shared-components/models/mailbox.model';
import { EcommerceIntegrationDataModel } from 'src/app/shared-components/models/user-integrations.model';

export enum UserOnboardingStepEnum {
	BRAND_INFO = 0,
	EMAIL_INFO = 1,
	ECOMMERCE_INFO = 2,
	ADD_TEAM_MEMBER = 3,
	BOOK_DEMO = 4,
	COMPLETED = 5
}

export interface OnboardingStepModel {
	step_number: UserOnboardingStepEnum;
	to_skip?: boolean;
	is_last_step?: boolean;
	last_step_callback?: Function;
}

export interface UserOnboardingBrandInfoModel {
	brand_name?: string;
	brand_website?: string;
}

export interface UserOnboardingGetResponseModel {
	current_step: number; // starts with 0
	brand_info?: UserOnboardingBrandInfoModel;
	email_info: EmailDataModel[];
	ecommerce_info: EcommerceIntegrationDataModel[];
	is_demo_booked?: boolean;
	is_without_cc: boolean;
	team_size_limit: number;
	team_members: SubUserMinDataModel[];
}

export interface UpdateOnboardingPayloadModel {
	step: number;
}

export interface SubUserMinDataModel {
	id?: number;
	email?: string;
	name?: string;
}
