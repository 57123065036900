export interface SubUser {
	NormalizedEmail?: string;
	TwoFactorEnabled?: boolean;
	FiledParentId?: number;
	DisableReason?: any;
	EmailConfirmed: boolean;
	LockoutEnd?: any;
	GoogleBusinessOwnerId?: any;
	Feedback?: any;
	PasswordHash?: string;
	LockoutEnabled?: boolean;
	PictureUrl?: any;
	Access?: any;
	SecurityStamp?: any;
	AccessFailedCount?: number;
	CompanyName?: string;
	LollyModule?: string;
	Id: number;
	ConcurrencyStamp?: any;
	FirstName: string;
	CreatedAt?: string;
	CustomerId?: any;
	UserName: string;
	PhoneNumber?: string;
	LastName: string;
	LastLogin?: any;
	NormalizedUserName?: string;
	PhoneNumberConfirmed?: boolean;
	Position: string;
	AccountStateId?: number;
	Email?: string;
	FacebookBusinessOwnerId?: any;
}

export interface SubUserV2 {
	id: number;
	name?: string;
	email?: string;
	role?: string;
	permissions?: string[];
}
