import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	Renderer2,
	SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutreachSequenceSummaryModel } from '../../../../models/outreach-sequence-new.model';
import { SequenceTableModule } from '../sequence-table.module';

@Component({
	selector: 'th[sequence-head-cell]',
	template: `
		<span *ngIf="title != null">{{ title }}</span>
	`,
	styleUrls: ['./head-cell.component.scss'],
	imports: [CommonModule],
	standalone: true
})
export class SequenceHeadCellComponent {
	@Input()
	title: string;

	@Input()
	center = false;

	constructor(private renderer: Renderer2, private element: ElementRef) {}

	ngAfterViewInit(): void {
		this.updateAlignment();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['center']) {
			this.updateAlignment();
		}
	}

	updateAlignment() {
		const textAlign = this.center ? 'center' : 'left';
		this.renderer.setStyle(
			this.element.nativeElement,
			'text-align',
			textAlign
		);
	}
}
