<div class="dialog-root">
	<mat-icon *ngIf="!data?.hideCloseIcon" class="close-icon" mat-dialog-close>
		clear
	</mat-icon>

	<div class="icon" *ngIf="data?.icon">
		<img src="{{ data?.icon }}" [ngStyle]="data?.iconStyles" />
	</div>

	<div class="title" [ngStyle]="data?.titleStyles">{{ data.title }}</div>

	<div
		*ngIf="data?.showDescription"
		class="description"
		[ngStyle]="data?.descriptionStyles"
	>
		{{ data.description }}
	</div>

	<ng-container [ngSwitch]="true">
		<ng-container
			*ngSwitchCase="data?.oneButtonFooter"
			[ngTemplateOutlet]="oneButtonFooter"
		></ng-container>
		<ng-container
			*ngSwitchCase="!data?.oneButtonFooter"
			[ngTemplateOutlet]="twoButtonFooter"
		></ng-container>
	</ng-container>
</div>

<ng-template #oneButtonFooter>
	<div class="footer end" [ngStyle]="data?.footerStyles">
		<button
			class="primary-btn"
			[ngStyle]="data?.primaryButtonStyles"
			(click)="primaryAction()"
		>
			<img
				*ngIf="data?.secondaryPrefix"
				src="{{ data?.secondaryPrefix }}"
			/>
			<span>
				{{
					data?.primaryActionTitle
						? data?.primaryActionTitle
						: 'Confirm'
				}}
			</span>
		</button>
	</div>
</ng-template>

<ng-template #twoButtonFooter>
	<div class="footer" [ngStyle]="data?.twoButtonFooterStyles">
		<button
			class="primary-btn {{ data?.secondaryActionClass }}"
			[ngStyle]="data?.primaryButtonStyles"
			(click)="secondaryAction()"
		>
			<img
				*ngIf="data?.secondaryPrefix"
				src="{{ data?.secondaryPrefix }}"
			/>
			<span>
				{{
					data?.secondaryActionTitle
						? data?.secondaryActionTitle
						: 'No'
				}}
			</span>
		</button>

		<button
			class="secondary-btn"
			(click)="primaryAction()"
			[ngStyle]="data?.secondaryButtonStyles"
		>
			<ng-container *ngIf="data?.primaryPrefix">
				<img
					class="img-hover"
					src="{{ getImgHovered(data?.primaryPrefix) }}"
				/>
				<img class="img-default" src="{{ data?.primaryPrefix }}" />
			</ng-container>
			<span>
				{{
					data?.primaryActionTitle ? data?.primaryActionTitle : 'Yes'
				}}
			</span>
		</button>

		<button
			*ngIf="data?.alternativeActionTitle"
			class="secondary-btn new-line"
			(click)="alternativeAction()"
		>
			<span>
				{{ data?.alternativeActionTitle }}
			</span>
		</button>
	</div>
</ng-template>
