import { NgModule } from '@angular/core';
import { SequenceTextCellComponent } from './cells/text-cell.component';
import { SequenceMenuCellComponent } from './cells/menu-cell.component';
import { SequenceHeadCellComponent } from './cells/head-cell.component';
import { SequenceTableRowComponent } from './row/sequence-row.component';
import { SequenceTableHeadComponent } from './head/sequence-head.component';

@NgModule({
	imports: [
		SequenceTextCellComponent,
		SequenceMenuCellComponent,
		SequenceHeadCellComponent,
		SequenceTableRowComponent,
		SequenceTableHeadComponent
	],
	exports: [
		SequenceTextCellComponent,
		SequenceMenuCellComponent,
		SequenceHeadCellComponent,
		SequenceTableRowComponent,
		SequenceTableHeadComponent
	]
})
export class SequenceTableModule {}
