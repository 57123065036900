import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import { OutreachSequenceService } from '../../services/outreach-sequence.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { ToastV2TypeEnum } from 'src/app/toast-v2/models/toast-v2.model';
import { takeUntil } from 'rxjs/operators';
import { GenericSmDialogComponent } from 'src/app/shared-components/generic-sm-dialog/generic-sm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QuillEditorComponent } from 'ngx-quill';
import { getUploadFileIconPath } from '../../../../../shared/utils';

@Component({
	selector: 'app-custom-reply-quill-editor',
	templateUrl: './custom-reply-quill-editor.component.html',
	styleUrls: ['./custom-reply-quill-editor.component.scss']
})
export class CustomReplyQuillEditorComponent
	implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('fileSelect') fileSelect: ElementRef;
	@ViewChild('editor') public editor: QuillEditorComponent;

	@Input() config: any;
	@Input() control: UntypedFormControl;
	@Input() attachedFilesForm: UntypedFormArray;
	@Input() submitLabel: string = 'Send now';

	@Output() onSubmit: EventEmitter<void> = new EventEmitter();
	@Output() onClear: EventEmitter<void> = new EventEmitter();

	public linkForm: UntypedFormGroup;
	public loader = false;
	public selectedFile: File;
	public selectedEvent;

	public rawActive = false;
	public boldActive = false;
	public italicActive = false;
	public underlineActive = false;

	public subscriptions: Subscription[] = [];
	public quillActive = false;
	public availableExtensions: string[] = [
		'txt',
		'pdf',
		'jpg',
		'png',
		'jpeg',
		'gif',
		'tif',
		'xlsx',
		'doc',
		'docx',
		'xls',
		'ppt',
		'pptx',
		'zip'
	];
	public availableImageExtensions: string[] = [
		'jpg',
		'png',
		'jpeg',
		'gif',
		'tif'
	];
	public fonts: string[] = [
		'sans-serif',
		'serif',
		'courier',
		'georgia',
		'gill-sans',
		'helvetica',
		'monospace'
	];

	public activeModules = {
		text: true,
		link: true,
		image: true,
		attachment: true,
		html: true,
		bold: true,
		italic: true,
		underline: true
	};

	public unsubscriber$: Subject<void> = new Subject<void>();

	public modules = {
		toolbar: []
	};

	constructor(
		private outreachSequenceService: OutreachSequenceService,
		private toast: ToastNotificationService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.initForms();
	}

	ngAfterViewInit(): void {
		this.subscribeToEvents();
	}

	public onClickSubmit() {
		this.onSubmit.emit();
	}

	private subscribeToEvents(): void {
		const editorChangedSub = this.editor.onEditorChanged.subscribe(
			value => {
				if (this.editor.quillEditor.hasFocus()) {
					const currentFormat = value.editor.getFormat();
					this.boldActive = !!currentFormat.bold;
					this.italicActive = !!currentFormat.italic;
					this.underlineActive = !!currentFormat.underline;
				}
			}
		);

		const selectionChangedSub = this.editor.onSelectionChanged.subscribe(
			value => {
				if (this.editor.quillEditor.hasFocus()) {
					const currentFormat = value.editor.getFormat();
					this.boldActive = !!currentFormat.bold;
					this.italicActive = !!currentFormat.italic;
					this.underlineActive = !!currentFormat.underline;
				}
			}
		);

		this.subscriptions.push(editorChangedSub, selectionChangedSub);
	}

	private unsubscribeFromEvents(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
		this.subscriptions = [];
	}

	private initForms(): void {
		this.linkForm = new UntypedFormGroup({
			name: new UntypedFormControl(),
			link: new UntypedFormControl()
		});
	}

	public insertLink(): void {
		let menu = document.querySelector(
			'.cdk-overlay-backdrop'
		) as HTMLElement;
		menu.click();
		const { name, link } = this.linkForm.value;
		this.linkForm.reset();
		this.insertContent(null, (quill, index) => {
			this.addLink(quill, index, name, link);
		});
	}

	public addLink(quill, index, text, url): void {
		quill.insertText(index, text, 'user');
		quill.setSelection(index, text.length);
		quill.theme.tooltip.edit('link', url);
		quill.theme.tooltip.save();
	}

	private insertContent(text, cb?) {
		var selection = this.editor.quillEditor?.getSelection(true);

		if (selection.index !== undefined) {
			if (text)
				this.editor.quillEditor?.insertText(selection.index, text);
			if (cb) cb(this.editor.quillEditor, selection.index);
		}
	}

	public insertEmbed(url): void {
		this.insertContent(null, (quill, index) => {
			quill?.insertEmbed(index, 'image', url);
			quill?.insertEmbed(index + 1, 'block', '');
		});
	}

	public sizeInMb(sizeInBytes: number): string {
		let sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		return sizeInMB;
	}

	private isValidFile(file, max, fileTypes): boolean {
		const sizeInMB = parseInt(this.sizeInMb(file.size));
		const type = file.name.split('.').pop();

		if (sizeInMB > max) {
			this.toast.sendErrorToast(
				`Size of the attachment cannot exceed ${max} MB!`
			);
			return false;
		}
		if (!fileTypes.includes(type)) {
			this.toast.sendErrorToast('Unsupported File Type!');
			return false;
		}

		return true;
	}

	public onFileEleChange(
		event: any,
		cb,
		maxSize?,
		acceptableExtensions?
	): void {
		const file = event.target.files[0];

		if (
			this.isValidFile(
				file,
				maxSize || 10,
				acceptableExtensions || this.availableExtensions
			)
		) {
			this.selectedFile = file;
			cb(file);
		}
	}

	private clearFileData(): void {
		this.fileSelect.nativeElement.removeEventListener(
			'change',
			this.selectedEvent
		);
		this.selectedFile = null;
		this.fileSelect.nativeElement.value = null;
	}

	public openAddFilesDialog(cb, type = 'file') {
		const e = this.fileSelect.nativeElement;

		// clear previous event handler attached to it
		this.clearFileData();

		if (type == 'image')
			this.selectedEvent = e =>
				this.onFileEleChange(
					e,
					cb,
					null,
					this.availableImageExtensions
				);
		else this.selectedEvent = e => this.onFileEleChange(e, cb);
		e.addEventListener('change', this.selectedEvent);
		e.click();
	}

	private onError(err): void {
		const title = 'Oops, something went wrong!';
		const msg = 'Please try again!';

		this.toast.sendCustomToast(msg, ToastV2TypeEnum.ERROR, 2000, title);
	}

	public attachFile(): void {
		this.openAddFilesDialog(file => {
			const currFiles = this.attachedFilesForm.value as any[];
			currFiles.push(file);
		});
	}

	public removeFile(index): void {
		const currFiles = this.attachedFilesForm.value as any[];
		currFiles.splice(index, 1);
	}

	public showImageUpload(): void {
		let matDialogData = {
			title: `Adding images may have impacts`,
			showDescription: true,
			description: `As a precaution, some email providers may block the display of external images due to security risks. Additionally, variations in HTML code interpretation may lead to images appearing altered. Please consider removing images.`,
			primaryActionTitle: 'Use Images',
			secondaryActionTitle: 'Cancel'
			// secondaryActionClass: 'secondary-btn',
		};
		let config = {
			minHeight: '303px', // Default Height For 1366px Resolution
			width: '532px'
		};
		let matDialogConfig: MatDialogConfig = {
			panelClass: ['center-dialog-no-shadow', 'image-upload-dialog'],
			...config,
			backdropClass: 'light-backdrop',
			data: matDialogData
		};
		let dialogRef = this.dialog.open(
			GenericSmDialogComponent,
			matDialogConfig
		);

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				if (res && res?.primaryAction === 'true') {
					this.openAddFilesDialog(file => {
						const formData = new FormData();
						formData.append('image', file);

						this.loader = true;
						this.outreachSequenceService
							.uploadImage(formData)
							.subscribe(
								(res: any) => {
									const data = res.data;
									if (data) this.insertEmbed(data);
								},
								err => {
									this.onError(err);
								}
							)
							.add(() => {
								this.loader = false;
							});
					}, 'image');
				}
			});
	}

	public toggleRawHtml(): void {
		this.rawActive = !this.rawActive;

		if (this.rawActive) {
			setTimeout(() => {
				document.getElementById('raw-editor').focus();
			});
			this.subscribeToEvents();
		} else {
			setTimeout(() => {
				this.editor.quillEditor?.focus();
			});
			this.unsubscribeFromEvents();
		}
	}

	public toggleBold(): void {
		if (this.editor) {
			this.editor.quillEditor?.focus();
			const currentFormat = this.editor.quillEditor?.getFormat();
			const isBold = currentFormat.bold || false;
			this.editor.quillEditor?.format('bold', !isBold);
			this.boldActive = !isBold;
		}
	}

	public toggleItalic(): void {
		if (this.editor) {
			this.editor.quillEditor?.focus();
			const currentFormat = this.editor.quillEditor?.getFormat();
			const isItalic = currentFormat.italic || false;
			this.editor.quillEditor?.format('italic', !isItalic);
			this.italicActive = !isItalic;
		}
	}

	public toggleUnderline(): void {
		if (this.editor) {
			this.editor.quillEditor?.focus();
			const currentFormat = this.editor.quillEditor?.getFormat();
			const isUnderline = currentFormat.underline || false;
			this.editor.quillEditor?.format('underline', !isUnderline);
			this.underlineActive = !isUnderline;
		}
	}

	public clearEditor(): void {
		if (this.editor && this.editor.quillEditor) {
			this.editor.quillEditor.setText('');
			this.control.patchValue('');
			this.attachedFilesForm.patchValue([]);
		}

		this.onClear.emit();
	}

	public getIconPath(filename: string): string {
		return getUploadFileIconPath(filename);
	}

	ngOnDestroy(): void {
		this.unsubscribeFromEvents();
	}
}
