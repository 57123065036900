import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { OutreachSequenceSummaryModel } from '../../../../models/outreach-sequence-new.model';

@Component({
	selector: 'td[sequence-menu-cell]',
	template: `
		<button
			mat-icon-button
			[matMenuTriggerFor]="menu"
			#optionsMenuTrigger="matMenuTrigger"
		>
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu" class="options-menu">
			<button
				class="filter-menu-list-item"
				mat-menu-item
				(click)="deleteClick.emit(data); optionsMenuTrigger.closeMenu()"
			>
				<img [src]="'assets/discovery-v2/delete-outlined.svg'" />
				<span class="filter-drp-name w-100">
					Delete
				</span>
			</button>
		</mat-menu>
	`,
	styleUrls: ['./menu-cell.component.scss'],
	imports: [MatIconModule, MatMenuModule, MatButtonModule],
	standalone: true
})
export class SequenceMenuCellComponent {
	@Input()
	public data: OutreachSequenceSummaryModel;

	@Output()
	public deleteClick = new EventEmitter<OutreachSequenceSummaryModel>();
}
