import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DropdownOption } from '../../models/dropdown.interface';

@Component({
	selector: 'app-select-drp-w-button',
	templateUrl: './select-drp-w-button.html',
	styleUrls: ['./select-drp-w-button.scss']
})
export class SelectDrpWButtonComponent implements OnInit {
	@Input('iconStyle') public iconStyle: string;
	@Input('cssStyle') public cssStyle: any;
	@Input('dropdownLabel') public dropdownLabel: string;
	@Input('inputPlaceholder') public inputPlaceholder: string = 'Select';
	@Input('dropdownControl')
	public dropdownControl: UntypedFormControl = new UntypedFormControl();
	@Input('options') public options: DropdownOption[];
	@Input('disabled') public disabled: boolean = false;
	@Input('showNullOption') public showNullOption: boolean = true;
	@Input('titleKey') public titleKey: string = 'name';
	@Input('firstOptionName') public firstOptionName: string = 'Select';
	@Input('icon') public icon: string = 'menu';
	@Input() public dateControl: UntypedFormControl = new UntypedFormControl();
	public isOpen: boolean = false;

	@Output() public selectionEvent = new EventEmitter<any>();

	constructor(private elementRef: ElementRef) {}

	ngOnInit(): void {
		this.dateControl.valueChanges.subscribe(value => {
			this.optionSelected(this.getDateOption());
		});
	}

	public toggleDropdown() {
		if (!this.disabled) {
			this.isOpen = !this.isOpen;
		}
	}

	public optionSelected(option: DropdownOption) {
		this.dropdownControl.setValue(option);
		this.selectionEvent.emit(option);
		this.isOpen = false;
	}

	public getOptions() {
		return this.options.filter(item => item.name != 'Date');
	}

	public getDateOption() {
		return this.options.find(item => item.name == 'Date');
	}

	@HostListener('document:click', ['$event'])
	onClickOutside(event: MouseEvent) {
		if (
			this.isOpen &&
			!this.elementRef.nativeElement.contains(event.target)
		) {
			this.isOpen = false;
		}
	}
}
