export interface TemplateDataResponseModel {
	data: TemplateDataModel;
}

export interface TemplateDataModel {
	default: GenericTemplateDataModel[];
	custom: GenericTemplateDataModel[];
}

export interface GenericTemplateDataModel {
	id?: number;
	name?: string;
	subject?: string;
	body?: string;
	attachments?: any[];
}
