<div class="dialog-root">
	<mat-icon mat-dialog-close class="close-btn">
		close
	</mat-icon>

	<div class="dialog-header">
		{{data?.title ?? 'Create New Item'}}
	</div>

	<div class="dialog-body">
		<div class="left">
			{{data?.subtitle ?? 'New Item'}}
		</div>
		<div class="right">
			<div *ngIf="suffixText" class="suffix-input-wrapper">
				<input
					[formControl]="nameControl"
					type="text"
					class="text-input"
					[placeholder]="data?.placeholder ?? 'Enter name'"
				/>
				<div class="suffix-text">{{ suffixText }}</div>
			</div>
			<input
				*ngIf="!suffixText"
				type="text"
				class="custom-input-name"
				[formControl]="nameControl"
				[placeholder]="data?.placeholder ?? 'Enter name'"
			/>
		</div>
	</div>

	<div class="dialog-footer">
		<button class="quaternary-btn" mat-dialog-close>Cancel</button>
		<button
			class="primary-btn"
			[class.disabled]="this.nameControl?.invalid"
			(click)="primaryAction()"
		>
			{{data?.primaryButton ?? 'Create'}}
		</button>
	</div>
</div>
