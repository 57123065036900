import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class LandingPageService {
	constructor(private http: HttpClient) {}

	public getAllData(queryType: string): Observable<any> {
		// return this.http.get(`${BaseApiUrl.SocialInfluencerPython}dashboard/brand/${queryType}`);
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}users/me`);
	}

	public getCampaignPerformance(): Observable<any> {
		return this.http.get(
			`${BaseApiUrl.SocialMessengerPython}analytics/campaigns/performance`
		);
	}

	public disableFirstTimeUser(): Observable<any> {
		return this.http.put(
			`${BaseApiUrl.SocialInfluencerPython}users/end-tour`,
			{}
		);
	}

	public disableFirstTimeCRM(): Observable<any> {
		return this.http.put(
			`${BaseApiUrl.SocialInfluencerPython}users/end-crm`,
			{}
		);
	}

	public disableFirstTimeCampaign(): Observable<any> {
		return this.http.put(
			`${BaseApiUrl.SocialInfluencerPython}users/end-campaign`,
			{}
		);
	}

	public getTopCards(id?, date?): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}analytics`;
		const url = new URL(urlString);
		let queryString = '';

		if (id && date) {
			queryString = `?campaign_id=${id}&time_group=${date}`;
		}

		const revenue = this.http.get<any>(`${url.href}/revenue${queryString}`);
		const activeJobs = this.http.get<any>(
			`${url.href}/active-jobs${queryString}`
		);
		const connection = this.http.get<any>(
			`${url.href}/connections${queryString}`
		);

		return forkJoin([revenue, connection, activeJobs]);
	}
}
