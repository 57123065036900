import { Injectable, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { BackOfficeService } from './back-office/back-office.service';
import { Observable, Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import {
	HideGlobalSpinner,
	ShowGlobalSpinner
} from '../shared/state/shared.actions';
import { select, Store } from '@ngrx/store';
import { SharedState } from '../shared/state/shared.reducer';
import { ToastNotificationService } from '../shared/toast-notification/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import {
	getInvoiceHistory,
	UserManagementState
} from '../user-management/state/user-management.reducer';
import { UtilsService } from '../shared/utils';
import { ShareInvoiceEmailComponent } from '../user-management/dialogs/share-invoice-email/share-invoice-email.component';
import { ShareInvoice } from '../user-management/models/share-invoice';
import { UserServiceSubscription } from 'src/app/_services/user/user-subscription.service';
import { AuthenticationService } from './authentication.service';
import { getUserDetails } from '../shared/state/user/user.reducer';
import { UserStateEnum } from './user/user-state.enum';
import { ConfirmDialogComponent } from '../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { SuccessDialogComponent } from '../shared/dialogs/success-dialog/success-dialog.component';

@Injectable()
export class PopupDialogService {
	@Input() public invoiceHistoryDetails: InvoiceHistory;
	public unsubscriber$ = new Subject<void>();
	public isLoaded = false;
	public dataSource: InvoiceHistory[];

	private selectedBillId: string;

	constructor(
		private matDialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private store: Store<UserManagementState>,
		private sharedStore: Store<SharedState>,
		private toastNotificationService: ToastNotificationService,
		private translate: TranslateService,
		private userServiceSubscription: UserServiceSubscription,
		private authenticationService: AuthenticationService
	) {}

	public confirm(
		title: string,
		message: string,
		width: string = '500px',
		height: string = 'auto',
		btnOkText: string = 'YES',
		btnOkColor: string = 'warn',
		btnCancelText: string = 'NO',
		btnCancelColor: string = ''
	): Observable<boolean> {
		const modalRef = this.matDialog.open(ConfirmationDialogComponent, {
			width: width,
			height: height
		});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.message = message;
		modalRef.componentInstance.btnOkText = btnOkText;
		modalRef.componentInstance.btnCancelText = btnCancelText;
		modalRef.componentInstance.btnOkColor = btnOkColor;
		modalRef.componentInstance.btnCancelColor = btnCancelColor;

		return modalRef.afterClosed();
	}

	public confirmAudience(
		title: string,
		selectedAudience: string,
		message: string,
		width: string = '480px',
		height: string = 'auto',
		btnOkText: string = 'CONFIRM',
		btnOkColor: string = 'warn',
		btnCancelText: string = 'CANCEL',
		btnCancelColor: string = ''
	): Observable<boolean> {
		const modalRef = this.matDialog.open(ConfirmDialogComponent, {
			width: width,
			height: height
		});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.selectedAudience = selectedAudience;
		modalRef.componentInstance.message = message;
		modalRef.componentInstance.btnOkText = btnOkText;
		modalRef.componentInstance.btnCancelText = btnCancelText;
		modalRef.componentInstance.btnOkColor = btnOkColor;
		modalRef.componentInstance.btnCancelColor = btnCancelColor;

		return modalRef.afterClosed();
	}

	public audienceSelected(
		title: string,
		selectedAudience: string,
		message: string,
		width: string = '480px',
		height: string = '355px'
	): Observable<boolean> {
		const modalRef = this.matDialog.open(SuccessDialogComponent, {
			width: width,
			height: height
		});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.selectedAudience = selectedAudience;
		modalRef.componentInstance.message = message;

		return modalRef.afterClosed();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}

	public showInvoice(id: string): void {
		this.backOfficeService
			.downloadInvoiceHistoryPdf(id, 'true')
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(downloadLink: string) => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					window.open(downloadLink);
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotificationService.sendErrorToast(
						this.translate.instant(
							'AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'
						)
					);
				}
			);
	}

	public payNow(currentInvoice: InvoiceHistory): void {
		let accountState: number;
		this.store
			.pipe(
				select(getUserDetails),
				skipWhile(details => !details),
				take(1)
			)
			.subscribe(userDetails => {
				accountState = userDetails.AccountState;
			});
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.backOfficeService
			.payInvoice(currentInvoice.id)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.dataSource = response;
					this.toastNotificationService.sendSuccessToast(
						'Payment Successfully done.'
					);
					this.fetchStore();
					if (
						accountState ===
						UserStateEnum.BillPendingRestrictedAccess
					) {
						setTimeout(() => {
							this.sharedStore.dispatch(new HideGlobalSpinner());
						}, 4000);
					} else {
						this.sharedStore.dispatch(new HideGlobalSpinner());
					}
				},
				error => {
					if (
						error.error[0].description ===
						'Payment collection failed. Reason: (card_declined) Your card was declined.'
					) {
						this.toastNotificationService.sendErrorToast(
							'The card has been declined. Please add or use another one.'
						);
					} else {
						this.toastNotificationService.sendErrorToast(
							this.translate.instant(
								'AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'
							)
						);
					}
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public download(id: string): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.backOfficeService
			.downloadInvoiceHistoryPdf(id, 'false')
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(downloadLink: string) => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					UtilsService.downloadUrlFile(downloadLink);
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotificationService.sendErrorToast(
						this.translate.instant(
							'AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'
						)
					);
				}
			);
	}

	public shareInvoice(bill: InvoiceHistory): void {
		this.selectedBillId = bill.id;
		const dialogRef = this.matDialog.open(ShareInvoiceEmailComponent, {
			width: '27%',
			data: {
				invoiceId: this.selectedBillId
			}
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((result: { status: string; email: string }) => {
				if (result && result.status) {
					this.shareInvoiceByEmail(result.email);
				}
			});
	}

	private fetchStore(): void {
		this.store
			.pipe(select(getInvoiceHistory), takeUntil(this.unsubscriber$))
			.subscribe(response => {
				this.dataSource = response;
			});
	}

	private shareInvoiceByEmail(email: string): void {
		const data: ShareInvoice = {
			chargebeeInvoiceId: this.selectedBillId,
			recipients: email
		};
		this.store.dispatch(new ShowGlobalSpinner());
		this.userServiceSubscription
			.shareInvoiceByEmail(data)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					this.toastNotificationService.sendSuccessToast(
						'Email sent'
					);
					this.store.dispatch(new HideGlobalSpinner());
				},
				() => {
					this.toastNotificationService.sendErrorToast(
						this.translate.instant(
							'AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'
						)
					);
					this.store.dispatch(new HideGlobalSpinner());
				}
			);
	}
}
