import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
	MatAutocompleteSelectedEvent,
	MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
	selector: 'app-auto-search-multi-drp-chip',
	templateUrl: './auto-search-multi-drp-chip.component.html',
	styleUrls: ['./auto-search-multi-drp-chip.component.scss']
})
export class AutoSearchMultiDrpChipComponent implements OnInit, OnDestroy {
	@ViewChild('autoTrigger', { read: MatAutocompleteTrigger })
	autoComplete: MatAutocompleteTrigger;
	@ViewChild('multiChipInput') multiChipInput;
	@Input() list: any[] = [];
	@Input() showInnerChips: boolean = true;
	@Input() width: string = '100%';
	@Input() placeholder: string = 'Search..';
	@Input() inputControl: UntypedFormControl = new UntypedFormControl();
	@Input() titleKey: string = 'name';
	@Input('disabled') public disabled: boolean = false;
	@Output() emitSelection: EventEmitter<any> = new EventEmitter<any>();
	@Output() emitDeselection: EventEmitter<any> = new EventEmitter<any>();
	public autocompleteControl = new UntypedFormControl();
	public separatorKeysCodes: number[] = [ENTER, COMMA];
	public filteredList: any[] = [];
	public chipList: string[] = [];
	public unsubscriber$ = new Subject<void>();

	constructor() {}

	ngOnInit(): void {
		this.filteredList = this.list;
		this.autocompleteControl.valueChanges
			.pipe(startWith(null), takeUntil(this.unsubscriber$))
			.subscribe(val => {
				if (!val || val.trim() == '') {
					this.filteredList = this.list;
				} else {
					this.filteredList = this.list.filter(j =>
						j[this.titleKey]
							.toLowerCase()
							.replace(/ /g, '')
							.trim()
							.includes(
								val.toLowerCase().replace(/ /g, '').trim()
							)
					);
				}
			});
		this.inputControl.valueChanges
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(val => {
				if (!val) {
					this.chipList = [];
					this.multiChipInput.nativeElement.value = '';
					this.autocompleteControl.reset();
				} else {
					this.chipList = [];
					val.forEach(x => {
						this.chipList.push(x.name);
					});
				}
			});
		if (this.inputControl.value && this.inputControl.value.length) {
			this.mapInitialValues();
		}
	}

	ngAfterViewInit() {
		window.addEventListener('scroll', this.scrollEvent, true);
	}

	scrollEvent = (event: any): void => {
		if (this.autoComplete.panelOpen) this.autoComplete.updatePosition();
	};

	public mapInitialValues(): void {
		this.chipList = [];
		this.inputControl.value.forEach(x => {
			this.chipList.push(x.name);
		});
	}

	add(event: MatChipInputEvent): void {}

	remove(item: string): void {
		const index = this.chipList.indexOf(item);
		if (index >= 0) {
			this.chipList.splice(index, 1);
		}
		let currentInputControlList = this.inputControl.value;
		const objIndex = currentInputControlList.findIndex(
			x => x[this.titleKey] == item
		);
		this.emitDeselection.emit(currentInputControlList[objIndex]);
		currentInputControlList.splice(objIndex, 1);
		this.inputControl.patchValue(currentInputControlList);
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		const value = event.option.value;
		if (this.chipList.includes(value)) {
			this.remove(value);
		} else {
			this.chipList.push(value);
			const obj = this.list.find(x => x[this.titleKey] == value);
			let currentInputControlList = this.inputControl.value;
			if (!currentInputControlList) {
				currentInputControlList = [];
			}
			currentInputControlList.push(obj);
			this.inputControl.patchValue(currentInputControlList);
			this.emitSelection.emit(obj);
		}
		this.multiChipInput.nativeElement.value = '';
		this.autocompleteControl.setValue(null);
	}

	public onClickInput(): void {
		const nativeElement = this.multiChipInput.nativeElement;
		nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'nearest'
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
