<div class="select-drp-wrapper {{ disabled ? 'disabled' : '' }}">
	<mat-form-field
		class="select-drp-field"
		appearance="outline"
		class="{{ disabled ? 'disabled-mat' : '' }}"
	>
		<mat-label *ngIf="!hideLabel">{{ inputLabel }}</mat-label>

		<mat-select
			class="mat-select-drp"
			panelClass="select-drp-v2-panel"
			[disableOptionCentering]="disableOptionCentering"
			[formControl]="dropdownControl"
			[compareWith]="compareOptions"
			(selectionChange)="optionSelected($event)"
		>
			<mat-option *ngIf="showNullOption" [value]="null">
				{{ nullOptionName }}
			</mat-option>
			<mat-option *ngFor="let option of options" [value]="option">
				<div class="d-flex justify-content-between">
					<span class="title">{{ option[titleKey] }}</span>
					<ng-container
						*ngIf="dropdownControl?.value?.[comparisonKey] != null"
					>
						<span
							*ngIf="dropdownControl?.value?.[comparisonKey] == option?.[comparisonKey]"
							class="fas fa-check d-flex k-align-items-center"
							style="color: #4a4a4a;"
						></span>
					</ng-container>
				</div>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
