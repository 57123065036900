import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	HideGlobalSpinner,
	ShowGlobalSpinner
} from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { PlatformEnum } from './Essentials/platform.enum';
import { SMIJobsService } from './Essentials/jobs.service';

@Component({
	selector: 'app-pay-dialog',
	templateUrl: './pay-dialog.component.html',
	styleUrls: ['./pay-dialog.component.scss']
})
export class PayDialogComponent implements OnInit {
	public cardDetails: any[] = [];
	public paymentCard: any;
	public defaultCard: any;
	public loading = true;
	public userDetail: UserDetailsInterface;
	private unsubscriber$: Subject<void> = new Subject<void>();
	constructor(
		public dialogRef: MatDialogRef<PayDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private billingService: PaymentServiceApi,
		private router: Router,
		public jobsService: SMIJobsService,
		private toastService: ToastNotificationService,
		private sharedStore: Store<SharedState>
	) {}

	ngOnInit(): void {
		this.loadUserCreditCards();
		this.userDetail = JSON.parse(
			localStorage.getItem('decodedJwtIo')
		) as UserDetailsInterface;
	}

	public loadUserCreditCards(): void {
		this.loading = true;
		this.billingService
			.getPaymentCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					if (details) {
						this.cardDetails = details;
						const cards = details.filter(card => card.isDefault);
						this.paymentCard = cards[0];
						this.defaultCard = cards[0];
						this.loading = false;
					}
				},
				error => {},
				() => {}
			);
	}
	public changeDefaultPaymentCard(card: any): void {
		this.paymentCard = card;
	}

	public gotoBiling(): void {
		this.router.navigate(['/user-management/payment-and-billing']);
		this.dialogRef.close();
	}

	public pay(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		const payload: any = {
			cardLastFourDigits: this.paymentCard.cardLastFourDigits,
			cardType: this.paymentCard.cardType
		};
		this.billingService
			.setDefaultPaymentCards(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					if (details) {
						this.confirmpay();
					}
				},
				error => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
		// this.loadUserCreditCards();
	}

	public confirmpay(): void {
		this.jobsService
			.payInfluencer(
				this.data.taskId,
				this.data.contentId,
				PlatformEnum[this.data.platform],
				this.data.channelPostType
			)
			.subscribe(
				res => {
					if (res.isSuccess == true) {
						this.sharedStore.dispatch(new HideGlobalSpinner());
						this.toastService.sendSuccessToast(
							'You successfully paid your influencer!'
						);
						this.dialogRef.close();
					} else {
						this.toastService.sendErrorToast(
							'Payment failed please try again'
						);
						this.dialogRef.close();
					}
				},
				error => {
					switch (error.error[0].code) {
						case 'Filed__SMI__InfluencerTask__Content__IsNotAllowed':
							this.toastService.sendErrorToast(
								'You have already paid the influencer for the particular channel post for the task.'
							);
							this.dialogRef.close();
							break;

						default:
							this.toastService.sendErrorToast(
								'Payment failed please try again'
							);
							this.dialogRef.close();
							break;
					}
				}
			);
	}
}
