import { Component, Input, OnInit } from '@angular/core';
import {
	DropDataAttributeDataModel,
	ExpandCollapseIconVisibilityEnum,
	FilteringListItemModel,
	GenericTableColumnsModel,
	GenericTableColumnTypeEnum,
	GenericTableDatasetModel,
	GenericTableDatasetTypeEnum,
	GenericTableRowsModel,
	IconSourceTypeEnum,
	SortByOptionsEnum,
	SortFilterMenuDataModel,
	SuffixIconDetailModel,
	UnlockEmailAttributeDataModel
} from './models/generic-table-row.model';
import { SocialPlatformsEnum } from '../../enums/social-platforms.enum';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'app-generic-table',
	templateUrl: './generic-table.component.html',
	styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit {
	@Input() public isMulti: boolean = false;
	@Input() public columns: GenericTableColumnsModel[] = [];
	@Input() public rows: GenericTableRowsModel[] = [];
	@Input() public showTableHeader: boolean = true;
	@Input() public rowHeight: string;

	public GenericTableColumnTypeEnum = GenericTableColumnTypeEnum;
	public GenericTableDatasetTypeEnum = GenericTableDatasetTypeEnum;
	public ExpandCollapseIconVisibilityEnum = ExpandCollapseIconVisibilityEnum;
	public SocialPlatformsEnum = SocialPlatformsEnum;
	public IconSourceTypeEnum = IconSourceTypeEnum;
	public SortByOptionsEnum = SortByOptionsEnum;

	constructor(private clipboard: Clipboard) {}

	ngOnInit(): void {}

	public onMatExpansionOpen(row: GenericTableRowsModel): void {
		row.isExpanded = true;
	}

	public onMatExpansionClosed(row: GenericTableRowsModel): void {
		row.isExpanded = false;
	}

	public onColumnClick(column: GenericTableColumnsModel): void {
		if (column?.columnCallback) {
			column?.columnCallback();
		}
	}

	public onColumnSuffix(column: GenericTableColumnsModel): void {
		if (column?.suffixCallback) {
			column?.suffixCallback();
		}
	}

	public onDatasetClick(dataset: GenericTableDatasetModel): void {
		if (dataset?.datasetCallback) {
			dataset?.datasetCallback();
		}
	}

	public onDataRowTitleClick(dataRow: GenericTableDatasetModel): void {
		if (dataRow?.titleCallback) {
			dataRow?.titleCallback();
		}
	}

	public onDataRowPrefix(dataRow: GenericTableDatasetModel): void {
		if (dataRow?.prefixCallback) {
			dataRow?.prefixCallback();
		}
	}

	public onDataRowSuffixClick(suffixIconDetail: SuffixIconDetailModel): void {
		if (suffixIconDetail?.suffixCallback) {
			suffixIconDetail?.suffixCallback();
		}
	}

	public onColumnCheckboxChange(
		event,
		column: GenericTableColumnsModel
	): void {
		event.stopPropagation();
		column?.checkboxChange(event);
	}

	public onDatasetCheckboxChange(
		event,
		dataRow: GenericTableDatasetModel
	): void {
		event.stopPropagation();
		if (dataRow?.checkboxChange) {
			dataRow?.checkboxChange(event);
		}
	}

	public onSelectMenuOption(option, dataRow): void {
		dataRow.menuOptionSelectCallback(option);
	}

	public toggleFilterSelection(
		filterMenuItem: FilteringListItemModel
	): void {}

	public applyFilters(sortMenuData: SortFilterMenuDataModel): void {
		if (sortMenuData?.applyFilterCallback) {
			sortMenuData.applyFilterCallback();
		}
	}

	public copyToClipboard(
		unlockEmailData: UnlockEmailAttributeDataModel
	): void {
		this.clipboard.copy(unlockEmailData?.email);
		unlockEmailData.copied = true;
		setTimeout(() => {
			unlockEmailData.copied = false;
		}, 5000);
	}

	public onUnlockEmailClick(
		unlockEmailData: UnlockEmailAttributeDataModel
	): void {
		if (unlockEmailData?.callback) {
			unlockEmailData.callback();
		}
	}

	public onDropDownCreateTagClick(
		dropDownData: DropDataAttributeDataModel,
		tagInput?: HTMLInputElement
	): void {
		if (dropDownData?.createTag) {
			dropDownData.isOpen = !dropDownData.isOpen;
			dropDownData.createTag(tagInput.value);
		}
	}

	public onDropDownAssignTagClick(
		dropDownData: DropDataAttributeDataModel,
		item?: any
	): void {
		if (dropDownData?.assignTag) {
			dropDownData.assignTag(item);
		}
	}

	public onDropDownApproveDeleteTagsClick(
		dropDownData: DropDataAttributeDataModel,
		item?: any
	): void {
		if (dropDownData?.approveDeleteTags) {
			dropDownData.approveDeleteTags(item);
		}
	}

	public onDropDownDeleteTagsClick(
		dropDownData: DropDataAttributeDataModel
	): void {
		if (dropDownData?.deleteTags) {
			dropDownData.deleteTags();
		}
	}

	public onDropDownToggleAddFormTagsClick(
		dropDownData: DropDataAttributeDataModel
	): void {
		dropDownData.isOpen = !dropDownData.isOpen;
	}

	public getStyleForTag(nameTag: string) {
		switch (nameTag) {
			case '':
				return {
					'background-color': 'white',
					border: '1px solid #dce1e8'
				};

			case 'Prospects':
				return {
					border: `1px solid #87A8D4`,
					'background-color': '#F7FAFF',
					color: '#055ED7'
				};

			case 'Conversation':
				return {
					border: `1px solid #8C64DC`,
					'background-color': '#F5F4F7',
					color: '#2D0B70'
				};

			case 'Rejected':
				return {
					border: `1px solid #DA8888`,
					'background-color': '#F8D6D6',
					color: '#610000'
				};

			case 'Hired':
				return {
					border: `1px solid #76BE91`,
					'background-color': '#D5F5E1',
					color: '#033C19'
				};

			case 'On Hold':
				return {
					border: `1px solid #D1B598`,
					'background-color': '#FFF4E9',
					color: '#3F1F00'
				};

			case 'Outreached':
				return {
					border: `1px solid #990099`,
					'background-color': '#FFCCFF',
					color: 'black'
				};
			default: {
				return {
					border: `1px solid #D5D5D5`,
					'background-color': '#F1F1F1',
					color: '#76787B'
				};
			}
		}
	}

	public clearFilters(sortMenuData: SortFilterMenuDataModel): void {
		if (sortMenuData?.clearFilterCallback) {
			sortMenuData.clearFilterCallback();
		}
	}

	public selectSortOption(
		sortBy: SortByOptionsEnum,
		sortMenuData: SortFilterMenuDataModel
	): void {
		sortMenuData.sortBy = sortBy;
		if (sortMenuData?.sortByCallback) {
			sortMenuData.sortByCallback(sortBy);
		}
	}

	public onFilterSearchValueChange(
		val: string,
		sortMenuData: SortFilterMenuDataModel
	): void {
		if (!val || val.trim() == '') {
			sortMenuData.filteredList = sortMenuData.list;
		} else {
			sortMenuData.filteredList = sortMenuData.list.filter(j =>
				j.name
					.toLowerCase()
					.replace(/ /g, '')
					.trim()
					.includes(val.toLowerCase().replace(/ /g, '').trim())
			);
		}
	}
}
