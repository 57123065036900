import { UserPlanLimitCodesEnum } from 'src/app/shared/models/plan-limits.model';

export interface GenericSidePopupModel {
	title?: string;
	videoPoster?: string;
	videoSrc?: string;
	footerText?: string;
	oneButtonFooter?: boolean;
	primaryBtnText?: string;
	secondaryBtnText?: string;
	list?: GenericSidePopupListItemModel[];
	showLimits?: boolean;
	limitCode?: UserPlanLimitCodesEnum;
}

export interface GenericSidePopupListItemModel {
	title?: string;
	subtitle?: string;
}

export enum GenericPredefinedPopupTypesEnum {
	HOME = 'home',
	DISCOVERY = 'discovery',
	LIST = 'list',
	CAMPAIGN = 'campaign',
	OUTREACH = 'outreach',
	ADVANCED_SEARCH = 'adv-search',
	PAYMENTS = 'payments',
	REPORTING = 'reporting',
	CREATORS = 'creators',
	ASSET_MANAGEMENT = 'asset-management',
	ECOMMERCE = 'e-commerce'
}
