export interface UploadedListDataModel {
	data: {
		available: ListDataInterface[];
		skipped: ListDataInterface[];
	};
}

export interface ListDataInterface {
	id: number;
	name?: string;
}

export interface CreateListResponseModel {
	data: InsideListData[];
}

export interface InsideListData {
	id: number;
	name?: string;
	discovery_ids: number[];
	created_at?: string;
	created_by?: number;
	updated_at?: string;
	updated_by?: number;
}
