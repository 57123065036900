<div class="tab-bar-blue">
	<ng-container *ngFor="let tab of tabItems; let tabSequence = index">
		<div
			class="tab-bar-blue-item"
			[ngClass]="{ selected: activeTabIndex == tabSequence }"
			(click)="setActiveTab(tabSequence)"
		>
			<div class="d-flex flex-column" style="gap: 8px;">
				<div class="title">
					{{ tab?.name }}
				</div>

				<div class="title bigger">
					<ng-container [ngSwitch]="tab?.type">
						<ng-container *ngSwitchCase="'currency'">
							{{ tab?.value | currency }}
						</ng-container>
						<ng-container *ngSwitchCase="'percent'">
							{{ tab?.value }}%
						</ng-container>
						<ng-container *ngSwitchDefault>
							{{ tab?.value }}
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
		<div
			class="vertical-border"
			*ngIf="tabSequence < tabItems.length - 1"
		></div>
	</ng-container>
</div>
