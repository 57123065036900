import {
	Component,
	ElementRef,
	Inject,
	OnInit,
	ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils';
import { GenericSidePopupModel } from './models/generic-side-popup-data.model';
import { PlanCreditUsageModel } from 'src/app/shared/models/plan.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserPlanLimitCodesEnum } from 'src/app/shared/models/plan-limits.model';

@Component({
	selector: 'app-generic-side-popup',
	templateUrl: './generic-side-popup.component.html',
	styleUrls: ['./generic-side-popup.component.scss']
})
export class GenericSidePopupComponent implements OnInit {
	@ViewChild('videoPlayer') videoPlayer: ElementRef;
	public isFirstTimePlay: boolean = true;
	public APPOINTMENT_URL: string = UtilsService.BOOK_DEMO_URL;
	public showLimitString?: string;

	public currentLimitCount?: number;
	public limitString?: string;
	public totalLimitCount?: number;
	public planData: PlanCreditUsageModel;
	private unsubscriber$ = new Subject<void>();

	constructor(
		private sharedService: SharedService,
		private dialogRef: MatDialogRef<GenericSidePopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data: GenericSidePopupModel
	) {}

	ngOnInit(): void {
		this.initListeners();
		this.checkForLimitString();
	}

	private checkForLimitString(): void {
		if (this.data?.showLimits && this.data?.limitCode != null) {
			this.updateLimitString();
		}
	}

	private initListeners(): void {
		this.sharedService
			.getPlanDetails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				this.planData = res.data;
				this.updateLimitString();
			});
	}

	private updateLimitString(): void {
		switch (this.data.limitCode) {
			case UserPlanLimitCodesEnum.DISCOVERY_SEARCH:
				this.limitString = `Discovery search credit limit reached: ${this.planData?.searches?.used}/${this.planData?.searches?.total} Used.`;
				break;
			case UserPlanLimitCodesEnum.DISCOVERY_PROFILE:
				this.limitString = `Profile view credit limit reached: ${this.planData?.views?.used}/${this.planData?.views?.total} Used.`;
				break;
			case UserPlanLimitCodesEnum.EXPORTS:
				this.limitString = `Export credit limit reached: ${this.planData?.exports?.used}/${this.planData?.exports?.total} Used.`;
				break;
			case UserPlanLimitCodesEnum.DISCOVERY_EMAILS:
				this.limitString = `Unlock email credit limit reached: ${this.planData?.emails?.used}/${this.planData?.emails?.total} Used.`;
				break;
			case UserPlanLimitCodesEnum.LISTS_COUNT:
				this.limitString = `List creation credit limit reached: ${this.planData?.list_created?.used}/${this.planData?.list_created?.total} Used.`;
				break;
			case UserPlanLimitCodesEnum.EMAILS_INTEGRATION:
				this.limitString = `Email integration credit limit reached: ${this.planData?.crm_email?.used}/${this.planData?.crm_email?.total} Used.`;
				break;
			case UserPlanLimitCodesEnum.INTEGRATED_DOMAINS:
				this.limitString = `Website integration credit limit reached: ${this.planData?.integrated_domains?.used}/${this.planData?.integrated_domains?.total} Used.`;
				break;
			// ? MORE CASES BELOW IF WE NEED IN FUTURE
			// case UserPlanLimitCodesEnum.ADVANCED_SEARCH:
			// case UserPlanLimitCodesEnum.TEAM_SIZE:
			// case UserPlanLimitCodesEnum.RESETED_COUNTERS:
		}
	}

	public async togglePlayPause() {
		if (this.isFirstTimePlay) {
			this.isFirstTimePlay = false;
		}
		const video: HTMLVideoElement = this.videoPlayer.nativeElement;

		if (video.paused) {
			await video.play();
		} else {
			await video.pause();
		}
	}

	public onSecondaryBtnClick(): void {
		this.dialogRef.close({ secondary: true });
	}

	public onPrimaryBtnClick(): void {
		this.dialogRef.close({ primary: true });
	}

	public openInNewTab(url: string): void {
		window.open(url, '_blank');
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
