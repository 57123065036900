import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
	MatDialog,
	MatDialogRef,
	MatDialogState
} from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { GenericSidePopupComponent } from 'src/app/shared-components/generic-side-popup/generic-side-popup.component';
import {
	GenericPredefinedPopupTypesEnum,
	GenericSidePopupModel
} from 'src/app/shared-components/generic-side-popup/models/generic-side-popup-data.model';
import { UtilsService } from '../utils';
import { UserPlanLimitCodesEnum } from '../models/plan-limits.model';

@Injectable({
	providedIn: 'root'
})
export class GenericSidePopupService {
	public dialogRef: MatDialogRef<GenericSidePopupComponent>;
	public bookADemoDialogData: GenericSidePopupModel = {
		title: 'Find influencers that match your interest',
		videoPoster:
			'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
		videoSrc:
			'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
		footerText: 'Request a personalized call with one of our experts.',
		oneButtonFooter: true,
		primaryBtnText: 'Book a demo call',
		list: [
			{
				title: 'Get list of similar creators',
				subtitle: 'Based on just one seed profile'
			},
			{
				title: 'Learn which influencers share similar audiences',
				subtitle: 'Or even or completely different ones'
			},
			{
				title: 'Shortlist relevant profiles',
				subtitle: 'Find out which accounts produce comparable content'
			}
		]
	};

	constructor(public dialog: MatDialog) {}

	public showPopup(matDialogData = this.bookADemoDialogData): Promise<any> {
		if (
			this.dialogRef &&
			this.dialogRef.getState() === MatDialogState.OPEN
		) {
			return Promise.resolve();
		}
		return new Promise<any>((resolve, reject) => {
			this.dialogRef = this.dialog.open(GenericSidePopupComponent, {
				width: '606px',
				height: '100%',
				panelClass: ['sharp-dialog-no-shadow', 'slide', 'slideInRight'],
				backdropClass: 'light-backdrop',
				position: {
					right: '0'
				},
				scrollStrategy: new NoopScrollStrategy(),
				data: matDialogData
			});

			this.dialogRef
				.afterClosed()
				.pipe(take(1))
				.subscribe(res => {
					resolve(res);
				});
		});
	}

	public showPredefinedSidePopups(
		type: GenericPredefinedPopupTypesEnum | string,
		limitCode?: UserPlanLimitCodesEnum,
		showLimitData = false
	): Promise<any> {
		let matDialogData: GenericSidePopupModel;

		switch (true) {
			case type.includes(GenericPredefinedPopupTypesEnum.DISCOVERY):
				matDialogData = {
					title: 'Find Influencers For Your Brand Today',
					videoSrc: UtilsService.DISCOVERY_KB_VIDEO_URL,
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Tailored Recommendations',
							subtitle:
								'Get matched with influencers who embody your brand values and audience demographics.'
						},
						{
							title: 'Analytics at Your Fingertips',
							subtitle:
								'Monitor Influencer effectiveness through real-time data insights.'
						},
						{
							title: 'Creator Database',
							subtitle:
								'Adapt and evolve your strategy with data of more than 200 million creators.'
						}
					]
				};
				break;
			case type.includes(GenericPredefinedPopupTypesEnum.LIST):
				matDialogData = {
					title: 'Build Your Perfect Influencer List',
					videoSrc: UtilsService.DISCOVERY_LIST_KB_VIDEO_URL,
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Curated Lists',
							subtitle:
								'Create & Manage creator lists that match your specific needs.'
						},
						{
							title: 'Save and Organise',
							subtitle:
								'Easily save and categorise influencers for different campaigns.'
						},
						{
							title: 'Effortless Collaboration',
							subtitle:
								'Share your lists with team members within the platform without any hassle.'
						}
					]
				};
				break;
			case type.includes(GenericPredefinedPopupTypesEnum.CAMPAIGN):
				matDialogData = {
					title: 'Streamline Your Influencer Campaign Management',
					videoSrc: UtilsService.CAMPAIGN_KB_VIDEO_URL,
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Campaign Wizard',
							subtitle:
								'Use our step-by-step guide to set up campaigns effortlessly.'
						},
						{
							title: 'Comprehensive Management Tools',
							subtitle:
								'Track post performance, engagement rates, and ROI in one place.'
						},
						{
							title: 'Customizable Templates',
							subtitle:
								'Use pre-designed templates that make campaign creation quick and simple.'
						}
					]
				};
				break;
			case type.includes(GenericPredefinedPopupTypesEnum.OUTREACH):
				matDialogData = {
					title: 'Communicate Effectively with Influencers',
					videoPoster:
						'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc:
						'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Direct Messaging',
							subtitle:
								'Contact any influencer directly through our secure platform.'
						},
						{
							title: 'Template Responses',
							subtitle:
								'Save time with customizable message templates.'
						},
						{
							title: 'Message Tracking',
							subtitle:
								'Keep track of conversations and follow-ups.'
						}
					]
				};
				break;
			case type.includes(GenericPredefinedPopupTypesEnum.ADVANCED_SEARCH):
				matDialogData = {
					title: 'Let Our Experts Find the Perfect Match',
					videoPoster:
						'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc:
						'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Expert Data Team',
							subtitle:
								'Our data team will go and find influencers that match your needs.'
						},
						{
							title: 'Tailored Searches',
							subtitle:
								'Specify your needs and let our team do the heavy lifting.'
						},
						{
							title: 'Regular Updates',
							subtitle:
								'Receive ongoing recommendations as your brand evolves.'
						}
					]
				};
				break;
			case type.includes(GenericPredefinedPopupTypesEnum.PAYMENTS):
				matDialogData = {
					title: 'Simplify Your Influencer Payouts',
					videoPoster:
						'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc:
						'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Automated Payments',
							subtitle:
								'Set up hassle-free, automatic payments to your influencers.'
						},
						{
							title: 'Transparent Tracking',
							subtitle:
								'Access real-time reports on payouts and budget status.'
						},
						{
							title: 'Customizable Payment Schedules',
							subtitle:
								'Choose payment schedules that work best for your business model.'
						}
					]
				};
				break;
			case type.includes(GenericPredefinedPopupTypesEnum.REPORTING):
				matDialogData = {
					title: 'Access Insights That Matter',
					videoPoster:
						'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc:
						'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Detailed Analytics',
							subtitle:
								'View engagement rates, reach, and ROI details for precise campaign tracking.'
						},
						{
							title: 'Engagement Analysis',
							subtitle:
								'Learn which demographics engage the most to tailor your campaigns.'
						},
						{
							title: 'Historical Data Comparison',
							subtitle:
								'Compare current performance against past results to assess growth.'
						}
					]
				};
				break;

			case type.includes(GenericPredefinedPopupTypesEnum.CREATORS):
				matDialogData = {
					title: 'Performance-Based Payouts: Reward Creativity',
					videoPoster:
						'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc:
						'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Incentivize Impactful Campaigns',
							subtitle:
								'Pay creators for what truly matters – results. Reward influencers based on their engagement, reach, and overall campaign success.'
						},
						{
							title: 'Maximize ROI with Data-Driven Rewards',
							subtitle:
								'Track creator performance through our analytics tools and ensure payouts align with the value they deliver.'
						},
						{
							title: 'Fair and Transparent Compensation',
							subtitle:
								'Empower your campaigns with a performance-first approach that benefits both you and the creators.'
						}
					]
				};
				break;
			case type.includes(
				GenericPredefinedPopupTypesEnum.ASSET_MANAGEMENT
			):
				matDialogData = {
					title: 'Keep your Campaign Assets Organized.',
					videoPoster:
						'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc:
						'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Simplify Asset Management',
							subtitle:
								'Store and access all campaign essentials in a single, secure platform.'
						},
						{
							title: 'Streamline Your Campaign Assets',
							subtitle:
								'Centralize and organize your marketing assets for seamless campaign execution.'
						},
						{
							title: 'Unleash Creativity with Easy Uploads',
							subtitle:
								'From visuals to URLs, get everything in place to power your campaigns.'
						}
					]
				};
				break;

			case type.includes(GenericPredefinedPopupTypesEnum.ECOMMERCE):
				matDialogData = {
					title: 'Empower Influencers to Drive E-Commerce Success',
					videoPoster:
						'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc:
						'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Turn Influencers into Your Sales Partners',
							subtitle:
								'Leverage the power of influencers to promote your products and drive direct sales.'
						},
						{
							title:
								'Seamlessly Integrate Products into Campaigns',
							subtitle:
								'Showcase your eCommerce products with influencers who align with your brand.'
						},
						{
							title: 'Track Sales and ROI in Real-Time',
							subtitle:
								'Monitor influencer-driven sales and revenue on the platform. Gain insights to optimise strategies and maximise your eCommerce success.'
						}
					]
				};
				break;
			default:
				return Promise.resolve();
		}
		matDialogData.showLimits = showLimitData;
		if (limitCode) {
			matDialogData.limitCode = limitCode;
		}
		return this.showPopup(matDialogData);
	}

	public dismissPopup(): void {
		if (this.dialogRef) {
			this.dialogRef.close();
		}
	}
}
