<ul class="tab-list" [ngStyle]="{ gap: tabGap + 'px' }">
	<li
		*ngFor="let tab of tabItems; let tabSequence = index"
		class="tab-item"
		[ngClass]="{ selected: activeTabIndex === tabSequence }"
		(click)="setActiveTab(tabSequence)"
		[ngStyle]="{ width: tabWidth }"
	>
		<div class="tab-text">{{ tab.name }}</div>
	</li>

	<li
		class="tab-underline-indicator"
		role="presentation"
		[ngStyle]="{ transform: sliderPosition, width: tabWidth }"
	></li>
</ul>
