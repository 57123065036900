import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import {
	UpdateOnboardingPayloadModel,
	UserOnboardingGetResponseModel,
	UserOnboardingStepEnum
} from '../models/onboarding.model';

@Injectable({
	providedIn: 'root'
})
export class OnboardingService {
	private moduleUrl = 'users/onboarding/';

	constructor(private http: HttpClient) {}

	public getUserOnboardingStatus(): Observable<{
		current_step: UserOnboardingStepEnum;
	}> {
		return this.http.get<{ current_step: UserOnboardingStepEnum }>(
			`${BaseApiUrl.SocialMessengerPython}${this.moduleUrl}status`
		);
	}

	public getOnboardingDetails(): Observable<{
		data: UserOnboardingGetResponseModel;
	}> {
		return this.http.get<{ data: UserOnboardingGetResponseModel }>(
			`${BaseApiUrl.SocialMessengerPython}${this.moduleUrl}details`
		);
	}

	public updateOnboardingStep(
		payload: UpdateOnboardingPayloadModel
	): Observable<{ success: boolean }> {
		return this.http.put<{ success: boolean }>(
			`${BaseApiUrl.SocialMessengerPython}${this.moduleUrl}details`,
			payload
		);
	}
}
