import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	ColumnApi,
	GridApi,
	IServerSideGetRowsParams,
	PaginationChangedEvent,
	RowNode
} from 'ag-grid-community';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseApiUrl } from '../../../../_services/base-api-urls';
import { AgGridColDefInterface } from '../../../../shared/master-table/models/ag-grid-col-def.interface';
import { AgGridRequest } from '../../../../shared/models/ag-grid-request';
import { campaignForm } from '../models/campaigns.interface';
import { AddColumnsToReqService } from '../../../../shared/master-table/services/add-columns-to-req.service';
// silsrang-migration
// import { UpdateAdsetInterface } from 'src/app/ads-manager/ads-manager-insights/models/update-adset.interface';
import { UpdateCampaignInterface } from '../models/update-campaign.interface';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { CampaignsResponse } from '../models/campaigns-response.model';
import { CampaignContractsResponse } from '../models/campaigns-contracts';
import { smiUrl } from '../../../utils/url-builder';
import { PaginationQuery } from '../../../../_models/pagination';
import { CampaignSortingQuery } from '../components/campaigns/campaigns-table.component';
import { takeUntil } from 'rxjs/operators';

export type SortingDirection = 'desc' | 'asc';

export interface CustomResonseInterface {
	customViews: Array<string>;
	defaultViews: Array<string>;
	masterColumns: AgGridColDefInterface[];
}

export interface CampaignFlowData {
	selectedCampaign?: any;
	selectedInfluencer?: any;
	selectedJob?: any;
	selectedTask?: any;
	currentView?: string;
}

export interface CampaignsStateModel {
	pageNumber?: number;
	pageSize?: number;
	searchFilter?: string;
}
@Injectable({
	providedIn: 'root'
})
export class CampaignService {
	public resetSubject = new Subject<void>();
	public changeTabSubject = new Subject<void>();
	public triggerBundleUpdateSubject = new Subject<void>();
	public isCheckboxDeactivated = true;
	public isSelectionToggledProgramatically = false;
	public lastNextPageCursor: string = null;
	public hasDelivery: boolean = false;
	public selectedCampaignsIds: string[] = [];
	public defaultCampaignsIds: string[] = [];
	public gridApi: GridApi;
	public columnApi: ColumnApi;
	public selectedAccount: string;
	public totalsRow: Array<Object>;
	public bundleUpdateArray: any[] = [];
	public timeRange: any;
	private triggerCheckCheckboxState = new Subject<void>();
	public selectedCampaigns = new BehaviorSubject<number>(0);
	public defaultCampaigns = new BehaviorSubject<number>(null);
	public selectedCampaignUrl = new BehaviorSubject<any>(null);
	public castselectedCampaignUrl = this.selectedCampaignUrl.asObservable();
	public castSelectedCampaigns = this.selectedCampaigns.asObservable();
	public castDefaultCampaigns = this.defaultCampaigns.asObservable();
	public campaignsState: CampaignsStateModel = {
		pageNumber: 0,
		pageSize: 10,
		searchFilter: ''
	};

	unsubscribe = {
		campaigns: new Subject(),
		jobsData: new Subject()
	};

	constructor(
		private http: HttpClient,
		private addColumnsService: AddColumnsToReqService
	) {}

	public generateTrackingURL(input) {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}bitly/shorten_url?brand_url=${input}`;

		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public createCampaign(body: campaignForm): Observable<number> {
		return this.http.post<number>(
			`${BaseApiUrl.SocialInfluencerPython}campaignsPage/create`,
			body
		);
	}

	public checkIfCampaignCreationLimitReached(): Observable<boolean> {
		return this.http.get<boolean>(
			`${BaseApiUrl.SocialInfluencerPython}campaignsPage/checkCampaignLimit`
		);
	}

	public validateConnection() {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}oauth/stripe/validate`;

		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public connectStripe() {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}oauth/stripe/preinstall`;

		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public disconnectStripe() {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}oauth/stripe/uninstall`;

		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public checkCampaignBudget(id) {
		let urlString = `${BaseApiUrl.SocialMessengerPython}campaigns/${id}/budget`;
		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public getStripeConnectionId(code, state, scope) {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}oauth/stripe/install?code=${code}&state=${state}&scope=${scope}`;

		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public editCampaignName(body: any) {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}campaignsPage/updateCampaignName`
		);
		return this.http.patch<any>(url.href, body);
	}
	public editCampaignbudget(body: any) {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}campaignsPage/updateCampaignBudget`
		);
		return this.http.patch<any>(url.href, body);
	}

	public editJobName(body: any) {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}jobs/updateJobName`
		);
		return this.http.patch<any>(url.href, body);
	}
	public editJobbudget(body: any) {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}jobs/updateJobBudget`
		);
		return this.http.patch<any>(url.href, body);
	}

	public stripeWebhook(site) {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}stripe_webhook/userwebsite`;

		const url = new URL(urlString);
		return this.http.post<any>(url.href, site);
	}

	public shopifyAdd(site) {
		let urlString = `${BaseApiUrl.SocialMessengerPython}oauth/shopify/preinstall`;

		const url = new URL(urlString);
		return this.http.post<any>(url.href, site);
	}

	public confirmShopConnection(input) {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}shopify/?shop_url=${input}`;
		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public triggerBundleUpdate(): Observable<void> {
		return this.triggerBundleUpdateSubject.asObservable();
	}

	public checkBoxStateObservable(): Observable<void> {
		return this.triggerCheckCheckboxState.asObservable();
	}

	public getCampaignList(
		pagination: PaginationQuery,
		searchString?: string,
		sorting?: CampaignSortingQuery
	): Observable<CampaignsResponse> {
		this.unsubscribe.campaigns.next();
		const url = smiUrl()
			.add('campaigns')
			.add('campaignsPage')
			.pagination(pagination)
			.query('name', searchString)
			.query('budget', sorting?.budget)
			.query('number_of_posts', sorting?.numberOfPosts)
			.query('spent', sorting?.spent)
			.query('total_creators', sorting?.totalCreators)
			.query('total_revenue', sorting?.totalRevenue)
			.done();

		return this.http
			.get<CampaignsResponse>(url)
			.pipe(takeUntil(this.unsubscribe.campaigns));
	}

	public getCampaignById(campaignId: number): Observable<any> {
		return this.http.get<CampaignsResponse>(
			`${BaseApiUrl.SocialInfluencerPython}campaigns/${campaignId}`
		);
	}

	public getInfluencerData(
		originUrl: string,
		pageNumber: number,
		pageSize: number,
		data?: { id?: number; searchString?: string }
	) {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}${originUrl}/creators?page_size=${pageSize}&page_number=${pageNumber}`;
		if (data?.id) {
			urlString = urlString + `&job_id=${data?.id}`;
		}
		if (data?.searchString) {
			urlString = urlString + `&name=${data?.searchString}`;
		}
		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public getJobsData(
		originUrl: string,
		pageNumber: number,
		pageSize: number,
		data?: {
			campaign_id?: number;
			influencer_id?: number;
			searchString?: string;
		}
	) {
		this.unsubscribe.jobsData.next();
		if (
			!data ||
			(!data?.campaign_id && !data?.influencer_id && data?.searchString)
		) {
			let urlString = `${BaseApiUrl.SocialInfluencerPython}jobsPage/jobs?page_size=${pageSize}&page_number=${pageNumber}`;
			if (data?.searchString) {
				urlString = urlString + `&name=${data?.searchString}`;
			}
			const url = new URL(urlString);
			return this.http.get<CampaignContractsResponse>(url.href);
		}
		let urlString = `${BaseApiUrl.SocialInfluencerPython}${originUrl}/jobs?page_size=${pageSize}&page_number=${pageNumber}`;

		if (data?.campaign_id) {
			urlString = urlString + `&campaign_id=${data.campaign_id}`;
		}
		if (data?.influencer_id) {
			urlString = urlString + `&influencer_id=${data.influencer_id}`;
		}
		if (data?.searchString) {
			urlString = urlString + `&name=${data?.searchString}`;
		}
		const url = new URL(urlString);
		return this.http.get<CampaignContractsResponse>(url.href);
	}

	public getTasksData(
		originUrl: string,
		pageNumber: number,
		pageSize: number,
		data?: { id?: number; searchString?: string }
	) {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}${originUrl}/tasks?page_size=${pageSize}&page_number=${pageNumber}`;
		if (data?.id) {
			urlString = urlString + `&job_id=${data?.id}`;
		}
		if (data?.searchString) {
			urlString = urlString + `&name=${data?.searchString}`;
		}
		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public createNewTask(payload: {}) {
		const url = new URL(`${BaseApiUrl.SocialInfluencerPython}task`);
		return this.http.post<any>(url.href, payload);
	}

	public getDashboardData(id) {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}campaignsPage/jobdashboard?campaign_id=${id}`
		);
		return this.http.get<any>(url.href);
	}

	public checkUrlVerification(
		WebsiteUrl: string,
		testing: boolean
	): Observable<any> {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}eventTracking/verification`
		);
		// let body = {
		// 	'ref_id': 'lctest1020'
		// }
		// let headers = new Headers({ 'Content-Type': 'application/json' });
		url.searchParams.set('url', WebsiteUrl);
		url.searchParams.set('testing', JSON.stringify(testing));
		return this.http.get<any>(url.href);
	}

	public formatData(value, type) {
		switch (type) {
			case 'date':
				value = this.formatDate(value);
				break;
			case 'string':
				value = value;
				break;
			case 'number':
				value = this.formatNumber(value);
				break;
			case 'platform':
				value = value;
				break;
		}
		return value;
	}

	public formatDate(value) {
		if (value) {
			let date = value.split('T')[0].split('-');
			return date[2] + '/' + date[1] + '/' + date[0];
		} else {
			return null;
		}
	}

	public formatNumber(value) {
		let val: string | number = Math.abs(value);

		if (val >= 10 ** 3 && val < 10 ** 6) {
			let displayValue = val / 1000;
			if (Number.isInteger(displayValue)) {
				val = (val / 1000).toFixed(0) + ' K';
			} else {
				val = (val / 1000).toFixed(2) + ' K';
			}
		} else if (val >= 10 ** 6) {
			let dispalyValue = val / 1000000;
			if (Number.isInteger(dispalyValue)) {
				val = (val / 1000000).toFixed(0) + ' M';
			} else {
				val = (val / 1000000).toFixed(2) + ' M';
			}
		} else {
			val = val;
		}

		return val;
	}

	public mapRequest(
		params: IServerSideGetRowsParams,
		pageSize: number
	): AgGridRequest {
		const request = this.addColumnsService.addColumns(params);
		this.isCheckboxDeactivated = true;
		request.timeRange = this.timeRange;
		request.pageSize = pageSize;
		request.nextPageCursor = this.lastNextPageCursor;
		return request;
	}

	public deleteStructure(adId: string): Observable<void> {
		return this.http.delete<void>(``);
	}

	public onPaginationChanged(paginationEvent: PaginationChangedEvent): void {
		this.triggerCheckCheckboxState.next();
	}
	public deselectAll(): void {
		if (this.gridApi) {
			this.isSelectionToggledProgramatically = true;
			this.gridApi.getSelectedNodes().forEach((row: RowNode) => {
				this.bundleUpdateArray.push({
					isChosen: false
				});
				row.setSelected(false);
			});
			setTimeout(() => {
				this.isSelectionToggledProgramatically = false;
				this.bundleUpdateArray = [];
			});
		}
	}

	public updateSelectedRows(toggledRow: UpdateCampaignInterface): void {
		this.triggerCheckCheckboxState.next();
		if (!this.isSelectionToggledProgramatically) {
			if (toggledRow.isChosen) {
				this.bundleUpdateArray.push(toggledRow.selectedRow);
				this.selectedCampaignsIds.push(toggledRow.selectedRow.Id);
				this.selectedCampaigns.next(this.selectedCampaignsIds.length);
			} else {
				let index1 = this.bundleUpdateArray.findIndex(
					x => x == toggledRow.selectedRow
				);
				let index2 = this.selectedCampaignsIds.findIndex(
					x => x == toggledRow.selectedRow.Id
				);
				this.bundleUpdateArray.splice(index1);
				this.selectedCampaignsIds.splice(index2, 1);
				this.selectedCampaigns.next(this.selectedCampaignsIds.length);
			}
		} else {
			if (toggledRow.isChosen) {
				this.bundleUpdateArray.push(toggledRow.selectedRow);
				this.selectedCampaignsIds.push(toggledRow.selectedRow.Id);
				this.selectedCampaigns.next(this.selectedCampaignsIds.length);
			} else {
				let index1 = this.bundleUpdateArray.findIndex(
					x => x == toggledRow.selectedRow
				);
				let index2 = this.selectedCampaignsIds.findIndex(
					x => x == toggledRow.selectedRow.Id
				);
				this.bundleUpdateArray.splice(index1);
				this.selectedCampaignsIds.splice(index2, 1);
				this.selectedCampaigns.next(this.selectedCampaignsIds.length);
			}
		}
	}

	public checkSelectedRows(gridApi: GridApi): void {
		gridApi.forEachNode(rowNode => {
			if (
				this.selectedCampaignsIds?.indexOf(rowNode.data?.campaign_id) >
					-1 &&
				!rowNode.isSelected()
			) {
				rowNode.setSelected(true);
			} else if (
				this.selectedCampaignsIds?.indexOf(
					rowNode.data?.campaign_id
				) === -1 &&
				rowNode.isSelected()
			) {
				rowNode.setSelected(false);
			}
			this.triggerCheckCheckboxState.next();
		});
	}

	public checkIfNotLastRequest(): boolean {
		return this.lastNextPageCursor !== null;
	}

	public setDefaultCampaigns(sets: any): void {
		let ids = sets.map(x => x.Id);
		this.defaultCampaignsIds = ids;
		this.defaultCampaigns.next(this.defaultCampaignsIds.length);
	}

	public CheckFilter(filter: any): any {
		filter.CreatedById = {
			filterType: 1,
			type: 3,
			filter: JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo))
				.user_filed_id,
			filterTo: ''
		};

		return filter;
	}

	public sortData(sortModel: any): any {
		if (sortModel == null) {
			return {
				column: 'Name',
				sort: 2
			};
		} else {
			return {
				column: sortModel.colId,
				sort: sortModel.sort
			};
		}
	}

	public resetRequest(): void {
		this.lastNextPageCursor = null;
		this.resetSubject.next();
	}
	public listenToRefreshData(): Observable<void> {
		return this.resetSubject.asObservable();
	}

	private removeColDefPropertiesUnrelatedToAgGrid(
		columnDef: AgGridColDefInterface
	): void {
		if (columnDef.numberOfDecimals) {
			delete columnDef.numberOfDecimals;
		}
		if (columnDef.columnType) {
			delete columnDef.columnType;
		}
	}

	public checkSelected(): void {
		this.deselectAll();
		this.bundleUpdateArray = [];
		this.selectedCampaignsIds.forEach(element => {
			setTimeout(() => {
				this.gridApi
					.getRenderedNodes()
					.find(rowNode => rowNode.data.Id == element)
					.setSelected(true);
			}, 200);
		});
	}

	public onClickName(campaignId: string): void {
		this.deselectAll();
		setTimeout(() => {
			this.gridApi
				.getRenderedNodes()
				.find(rowNode => rowNode.data.Id === campaignId)
				.setSelected(true);
		}, 0);
		setTimeout(() => {
			this.changeTabSubject.next();
		}, 100);
	}

	public listenToTabChange(): Observable<void> {
		return this.changeTabSubject.asObservable();
	}

	public getSelectedCampaignData(campaignId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}campaigns/${campaignId}`;
		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public getJobList(id: number): Observable<any> {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}jobs/${id}/creators`
		);
		return this.http.get<any>(url.href);
	}

	public deleteCampaign(id: number): Observable<any> {
		const url = new URL(
			`${BaseApiUrl.SocialMessengerPython}campaigns/${id}`
		);
		return this.http.delete<any>(url.href);
	}
}
