export interface PlanDetailsV2 {
	bulk_save?: number;
	campaigns?: number;
	exports?: number;
	influencers_per_campaign?: number;
	influencers_per_job?: number;
	influencers_per_month?: number;
	jobs?: number;
	name?: string;
	price_per_3_months?: number;
	price_per_6_months?: number;
	price_per_annum?: number;
	price_per_month?: number;
	profiles?: number;
	searches?: number;
	seats?: number;
	support?: string[];
	tag?: string;
}

export interface PlanDetails {
	product_id: string;
	product_name: string;
	product_description: string;
	product_price: string;
	product_price_id: string;
	product_price_currency: string;
	product_price_subscription: string;
	product_price_interval: string;
}

export interface PlanVariants {
	variant_1: PlanDetails;
	variant_2: PlanDetails;
	variant_3: PlanDetails;
	variant_4: PlanDetails;
	variant_5: PlanDetails;
	variant_6: PlanDetails;
	variant_7: PlanDetails;
	variant_8: PlanDetails;
	variant_9: PlanDetails;
	variant_10: PlanDetails;
}
