import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnChanges,
	Renderer2,
	SimpleChanges
} from '@angular/core';

@Component({
	selector: 'td[sequence-text-cell]',
	template: '<span>{{data.toString()}}</span>',
	styleUrls: ['./text-cell.component.scss'],
	standalone: true
})
export class SequenceTextCellComponent implements AfterViewInit, OnChanges {
	@Input()
	data: string | number;

	@Input()
	center = false;

	@Input()
	link = false;

	constructor(private renderer: Renderer2, private element: ElementRef) {}

	ngAfterViewInit(): void {
		this.updateAlignment();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['center'] || changes['link']) {
			this.updateAlignment();
		}
	}

	updateAlignment() {
		if (this.link) {
			this.renderer.addClass(this.element.nativeElement, 'link');
		} else {
			this.renderer.removeClass(this.element.nativeElement, 'link');
		}

		if (this.center) {
			this.renderer.addClass(this.element.nativeElement, 'center');
		} else {
			this.renderer.removeClass(this.element.nativeElement, 'center');
		}
	}
}
