import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GenericInputDataModel } from '../models/generic.interface';
import { GenericTableColumnsModel } from '../tables/generic-table/models/generic-table-row.model';

@Component({
	selector: 'app-generic-table-toggle-column',
	templateUrl: './generic-table-toggle-column.component.html',
	styleUrls: ['./generic-table-toggle-column.component.scss']
})
export class GenericTableToggleColumnComponent implements OnInit {
	@Input() public availableColumns: GenericTableColumnsModel[] = [];
	@Input() public inputControl: UntypedFormControl = new UntypedFormControl(
		[]
	);

	constructor() {}

	ngOnInit(): void {}

	public onBtnClick(): void {}

	public onColumnSliderClick(event, input: GenericTableColumnsModel): void {
		event?.preventDefault();
		event?.stopPropagation();
		const selectedValues = this.inputControl?.value as number[];
		const index = selectedValues.indexOf(input?.id);
		if (index < 0) {
			selectedValues.push(input.id);
		} else {
			if (selectedValues.length > 1) {
				selectedValues.splice(index, 1);
			}
		}
		this.inputControl.patchValue(selectedValues);
	}
}
