import { Component, Injector, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	HideGlobalSpinner,
	ShowGlobalSpinner
} from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { SetUserPlan } from 'src/app/user-management/state/user-management.actions';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import {
	getUserPlanState,
	UserManagementState,
	userPaymentPlan
} from '../../../../../state/user-management.reducer';
import { IdAndNameInterface } from '../../../../../../shared/permisions/table-permissions/id-name.interface';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';

@Component({
	selector: 'app-my-payment',
	templateUrl: './my-payment.component.html',
	styleUrls: ['./my-payment.component.scss']
})
export class MyPaymentComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public token: string;
	public planDetails: any;
	public cardDetails: any[];

	public loading: boolean;

	public currentRoute: string;
	public errorMessage: string;

	public duration: string;
	public tarrif: any;
	public paymentCard: any;
	public decodeJwtToken: UserDetailsInterface;
	public cardsBoolean: boolean;

	constructor(
		private injector: Injector,
		private formBuilder: UntypedFormBuilder,
		private toastService: ToastNotificationService,
		private billingService: PaymentServiceApi,
		private router: Router,
		private store: Store<UserManagementState>,
		private sharedStore: Store<SharedState>
	) {}

	ngOnInit(): void {
		this.decodeJwtToken = JSON.parse(
			localStorage.getItem('decodedJwtIo')
		) as UserDetailsInterface;
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.getDataFromStore();
		this.getTarrifDetails();
		this.areCardsMoreThanZero();
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	get getPlanAmount(): string {
		//console.log(this.tarrif?.price);
		let cur = this.tarrif?.currency || 'USD';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: cur
		}).format(this.tarrif?.amount || 0);
		return cur + ' ' + amount;
	}

	get getTarrifAmount(): string {
		let cur = this.tarrif?.currency || 'USD';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: cur
		}).format(this.tarrif?.price || 0);
		return amount;
	}

	get calculateDiscount(): string {
		let discount = this.tarrif?.price - (20 / 100) * this.tarrif?.price;
		let cur = this.tarrif?.currency || 'USD';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: cur
		}).format(discount || 0);
		return amount;
	}

	get getAnnulaSummary(): string {
		let discount =
			(this.tarrif?.price - (20 / 100) * this.tarrif?.price) * 12;
		let cur = this.tarrif?.currency || 'USD';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: cur
		}).format(discount || 0);
		return amount;
	}

	public getTarrifDetails(): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.getUserTarrifPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((details: any) => {
				if (details) {
					details.amount = this.roundFigure(details.amount);
					var dates = details.nextInvoiceDate
						.split('T')[0]
						.split('-');
					details.nextInvoiceDate = `${dates[2]}.${dates[1]}.${dates[0]}`;
					details.nextInvoiceYear = `${dates[2] + 1}.${dates[1]}.${
						dates[0]
					}`;
				}
				this.planDetails = details;
			});
		this.loading = false;
		this.sharedStore.dispatch(new HideGlobalSpinner());
	}

	public getDataFromStore(): void {
		this.sharedStore
			.pipe(select(getUserPlanState), takeUntil(this.unsubscriber$))
			.subscribe(detail => {
				if (detail) {
					this.tarrif = detail.tarrif;
					this.duration = detail.duration;
					this.paymentCard = detail.paymentCard;
				}
			});
	}

	public roundFigure(num: number): number {
		const factor = 10 ** 2;
		return Math.round(num * factor) / factor;
	}

	public onChangeDuration(duration: string): void {
		this.duration = duration;
		if (this.tarrif.price !== 'Free Trial') {
			this.tarrif.amount =
				duration == 'month'
					? this.tarrif.price * 1
					: this.tarrif.price * 12 -
					  (20 / 100) * (this.tarrif.price * 12);
		}
		this.updateStore();
	}

	public subscribeToPlan(): void {
		this.updateStore();
	}
	public updateStore(): void {
		const payload: userPaymentPlan = {
			duration: this.duration,
			tarrif: this.tarrif,
			paymentCard: this.paymentCard
		};
		this.store.dispatch(new SetUserPlan(payload));
	}

	public subscribeToNewPlan(): void {
		if (!this.cardsBoolean) {
			this.toastService.sendWarningToast(
				'Please add a card to make the payment.'
			);
			return;
		}
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		let contactIds = [];
		contactIds.push(this.decodeJwtToken.user_filed_id);
		const payload: any = {
			newOfferId: this.tarrif.id,
			filedIds: contactIds
		};

		this.billingService
			.changUsereOffer(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((details: any) => {
				if (details) {
					this.router.navigate([
						'/user-management/my-plan/subscription-confirmation'
					]);
				}
			});

		this.loading = true;
		this.sharedStore.dispatch(new HideGlobalSpinner());
	}

	public onUpdateCurrentDefaultCard(cardDetail: any): void {
		this.paymentCard = cardDetail;
		this.updateStore();
	}

	public areCardsMoreThanZero(): void {
		this.billingService
			.getPaymentCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					if (details) {
						if (details.length > 0) {
							this.cardsBoolean = true;
						} else this.cardsBoolean = false;
					}
				},
				error => {},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public addCard(): void {
		this.router.navigate(['/user-management/payment-and-billing/add-card']);
	}

	public makeYear(date: string): string {
		let dateString = date.replace('.', '/');
		let today = new Date(dateString);
		return today.setFullYear(today.getFullYear() + 1).toString();
	}
}
