import {
	CUSTOM_ELEMENTS_SCHEMA,
	NgModule,
	NO_ERRORS_SCHEMA
} from '@angular/core';
import { MaterialModule } from '../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilterTextPipe } from './pipes/filterText';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusinessOwnerService } from '../_services/facebook-accounts/business-owner.service';
import { RoundedImageContainerComponent } from './rounded-image-container/rounded-image-container.component';
import { ContainsPipe } from './pipes/contains';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
	CommonModule,
	CurrencyPipe,
	DatePipe,
	TitleCasePipe
} from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CamelCaseToTextPipe } from './pipes/humanize';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AgGridModule } from 'ag-grid-angular';
import { GridsterModule } from 'angular-gridster2';
import { ManageAdAccountPermissionsComponent } from '../user-management/components/account/facebook-inputs/ad-account-inputs/manage-ad-account-permissions.component';
import { DragAndDropDirective } from '../user-management/directives/drag-and-drop.directive';
import { UserServiceApi } from '../_services/user/user.api.service';
import { NoResourceComponent } from './404/no-resource.component';
import { CustomDateComponent } from './ag-grid-custom-cell-renderers/custom-date-component/custom-date-component.component';
import { CustomFieldCellRendererComponent } from './ag-grid-custom-cell-renderers/custom-field-cell-renderer/custom-field-cell-renderer.component';
import { BackOfficeNavigationComponent } from './back-office-navigation/back-office-navigation.component';
import { ButtonComponent } from './button-component/button.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NgbToMomentToString } from './calendar/calendar.pipe';
import { CalendarService } from './calendar/calendar.service';
import { DropdownSearchSelectComponent } from './dropdown-search-select/dropdown-search-select.component';
import { ErrorMessageService } from './form-input/error-message.service';
import { GenericDropdownComponent } from './form-input/generic-dropdown/generic-dropdown.component';
import { InputCardNumberComponent } from './form-input/input-card-number/input-card-number.component';
import { InputNumberCardDirective } from './form-input/input-number-card.directive';
import { InputCvcComponent } from './form-input/input-cvc/input-cvc.component';
import { InputBillingDateComponent } from './form-input/input-date/input-billing-date.component';
import { InputNumberValueDirective } from './form-input/input-number-value.directive';
import { InputNumberComponent } from './form-input/input-number/input-number.component';
import { InputPasswordComponent } from './form-input/input-password/input-password.component';
import { InputTextComponent } from './form-input/input-text/input-text.component';
import { InputTextareaComponent } from './form-input/input-textarea/input-textarea.component';
import { AgGridDirective } from './master-table/directives/ag-grid.directive';
import { MasterTableComponent } from './master-table/master-table.component';
import { CheckPermissionsDirective } from './permisions/check-permissions.directive';
import { PermissionsService } from './permisions/permissions.service';
import { TablePermissionService } from './permisions/table-permissions/table-permission-service';
import { TablePermissionsComponent } from './permisions/table-permissions/table-permissions.component';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { LimitLengthPipe } from './pipes/limit-length.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html';
import { SnakeCaseToHuman } from './pipes/snake-case-to-human.pipe';
import { ArraySortPipe } from './pipes/sort';
import { GenericPopupComponent } from './pop-ups/generic-popup/generic-popup.component';
import { AdAccountEffects } from './state/effects/ad-account-effects.service';
import { GeneralSettingsEffects } from './state/effects/general-settings-effects.service';
import { sharedReducer } from './state/shared.reducer';
import { userReducer } from './state/user/user.reducer';
import { TableTabsItemsComponent } from './table-tabs-items/table-tabs-items.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';

import { SafeURLPipe } from './pipes/safe-url.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { DefaultNoRowOverlayComponent } from './master-table/template/default-no-row-overlay/default-no-row-overlay.component';
import { MatSortModule } from '@angular/material/sort';
import { InputFieldComponent } from './input-field/input-field.component';
import {
	FormFieldModule,
	InputsModule,
	NumericTextBoxModule,
	TextBoxModule
} from '@progress/kendo-angular-inputs';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { SuccessDialogComponent } from './dialogs/success-dialog/success-dialog.component';

import { InputFieldPasswordComponent } from './input-field-password/input-field-password.component';
import { TruncateDirective } from './truncate/truncate.directive';
import { DiscoveryDrpComponent } from './discovery-drp/discovery-drp.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AnalyticsInfluencerCardComponent } from './analytics-influencer-card/analytics-influencer-card.component';
import { AnalyticsContentCardComponent } from './analytics-content-card/analytics-content-card.component';
import { LollySpinnerLoaderComponent } from '../shared-components/loaders/lolly-spinning-loader/lolly-spinning-loader.component';
import { TextInputComponent } from '../shared-components/text-input/text-input.component';
import { TextInputNumberComponent } from '../shared-components/text-input-number/text-input-number.component';

import { TextInputWithLabelComponent } from '../shared-components/text-input-with-label/text-input-with-label.component';
import { InputWithAddMoreComponent } from '../shared-components/input-with-add-more/input-with-add-more.component';
import { SearchInputComponent } from '../shared-components/search-input/search-input.component';
import { EmailInputFieldComponent } from '../shared-components/email-input-field/email-input-field.component';
import { ToggleSwitchComponent } from '../shared-components/toggle-switch/toggle-switch.component';
import { DropdownWithInputComponent } from '../shared-components/custom-dropdowns/dropdown-with-input/dropdown-with-input.component';
import { DropdownWoInputComponent } from '../shared-components/custom-dropdowns/dropdown-wo-input/dropdown-wo-input.component';
import { CheckboxComponent } from '../shared-components/checkbox/checkbox.component';
import { SwitchComponent } from '../shared-components/switch/switch.component';
import { ITooltipComponent } from '../shared-components/tooltips/i-tooltip/i-tooltip.component';
import { InfoTooltipComponent } from '../shared-components/tooltips/info-tooltip/info-tooltip.component';
import { LollyStepperComponent } from '../shared-components/lolly-stepper/lolly-stepper.component';
import { GlobalPopupComponent } from './dialogs/popup01/popup01.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { FiledFontComponent } from './filed-font/filed-font.component';
import { MinusPipe } from '../shared-components/graphs-eCharts/pipes/minus.pipe';
import { Graph06ProgressbarComponent } from '../shared-components/graphs-eCharts/graph06-progressbar/graph06-progressbar.component';
import { ShortNumberPipe } from './pipes/shortnumber-pipe';
import { CustomTooltipComponent } from '../shared-components/custom-tooltip/custom-tooltip.component';
import { QuillModule } from 'ngx-quill';
import { PrimaryButtonComponent } from './buttons/primary-button/primary-button.component';
import { PasswordoutlineTbComponent } from '../shared/form-input/passwordoutline-tb/passwordoutline-tb.component';
import { OutlineTbComponent } from '../shared/form-input/outline-tb/outline-tb.component';
import { AppMessageCardComponent } from '../shared/message-card/message-card.component';
import { AppCardListComponent } from '../shared/card-list/card-list.component';
import { AppAnalyticsProfileDetailsCardComponent } from '../shared/analytics-profile-details-card/analytics-profile-details-card.component';
import { LollyBtn03Component } from '../shared/buttons/normal-buttons/btn03/btn03.component';
import { NoSpecialCharsDirective } from '../shared-components/no-special-chars.directive';
import { InputNoSpecialComponent } from './input-no-special/input-no-special.component';
import { NestedDropdownCheckboxComponent } from '../shared-components/nested-dropdown-checkbox/nested-dropdown-checkbox.component';
import { MultiSelectAutoDropdownComponent } from '../shared-components/multi-select-auto-dropdown/multi-select-auto-dropdown.component';
import { CreateProductSeedingStepThreeComponent } from '../shared-components/create-product-seeding-step-three/create-product-seeding-step-three.component';
import { CreateProductSeedingStepTwoComponent } from '../shared-components/create-product-seeding-step-two/create-product-seeding-step-two.component';
import { CreateProductSeedingStepOneComponent } from '../shared-components/create-product-seeding-step-one/create-product-seeding-step-one.component';
import { CampaignCreationProductSeedingComponent } from '../shared-components/campaign-creation-product-seeding/campaign-creation-product-seeding.component';
import { VideoPopupComponent } from '../shared/pop-ups/video-popup/video-popup.component';
import { IGreyTooltipComponent } from '../shared-components/i-grey-tooltip/i-grey-tooltip.component';
import { SelectDrpWoInputComponent } from '../shared-components/custom-dropdowns/select-drp-wo-input/select-drp-wo-input.component';
import { AutoMultiDrpWithChipsComponent } from '../shared-components/custom-dropdowns/auto-multi-drp-with-chips/auto-multi-drp-with-chips.component';
import { AutoSearchMultiDrpChipComponent } from '../shared-components/custom-dropdowns/auto-search-multi-drp-chip/auto-search-multi-drp-chip.component';
import { GenericSmDialogComponent } from '../shared-components/generic-sm-dialog/generic-sm-dialog.component';
import { SimpleTextInputComponent } from '../shared-components/simple-text-input/simple-text-input.component';
import { DropzoneDirective } from '../shared-components/directives/dropzone.directive';
import { DateInputComponent } from '../shared-components/date-input/date-input.component';
import { LollyLoadingDialogComponent } from '../shared-components/lolly-loading-dialog/lolly-loading-dialog.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from '../shared-components/providers/custom-paginator';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AutoInputChipComponent } from '../shared-components/custom-dropdowns/auto-input-chip/auto-input-chip.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CustomCalendarDatePickerComponent } from '../shared-components/custom-calendar-date-picker/custom-calendar-date-picker.component';
import { MultiDrpLocationsComponent } from '../shared-components/multi-drp-locations/multi-drp-locations.component';
import { SelectDrpWInputComponent } from '../shared-components/custom-dropdowns/select-drp-w-input/select-drp-w-input.component';
import { CreditLimitComponent } from './credit-limit/credit-limit.component';
import { SelectDrpWithCustomSearchComponent } from '../shared-components/custom-dropdowns/select-drp-with-custom-search/select-drp-with-custom-search.component';
import { AutoMultiSelectGroupedComponent } from '../shared-components/auto-multi-select-grouped/auto-multi-select-grouped.component';
import { GmailLandingComponent } from '../shared-components/gmail-landing/gmail-landing.component';
import { PriorityToasterComponent } from '../shared-components/priority-toaster/priority-toaster.component';
import { CustomBudgetInputComponent } from '../shared-components/custom-budget-input/custom-budget-input.component';
import { CustomSideStepperComponent } from '../shared-components/custom-side-stepper/custom-side-stepper.component';
import { NewBudgetInputComponent } from '../shared-components/new-budget-input/new-budget-input.component';
import { ExtendBudgetDialogComponent } from '../shared-components/extend-budget-dialog/extend-budget-dialog.component';
import { BulkEmailDialogComponent } from '../shared-components/bulk-email-dialog/bulk-email-dialog.component';
import { HintsComponent } from '../shared-components/hints/hints.component';
import { NewGenericDialogComponent } from '../shared-components/new-generic-dialog/new-generic-dialog.component';
import { AddListToCampaignComponent } from '../shared-components/add-list-to-campaign/add-list-to-campaign.component';
import { GenericSidePopupComponent } from '../shared-components/generic-side-popup/generic-side-popup.component';
import { GenericTableComponent } from '../shared-components/tables/generic-table/generic-table.component';
import { GoogleAccountLandingComponent } from '../shared-components/google-account-landing/google-account-landing.component';
import { WebsiteInputComponent } from '../shared-components/website-input/website-input.component';
import { GenericInputDialogComponent } from '../shared-components/generic-input-dialog/generic-input-dialog.component';
import { AutofocusDirective } from '../shared-components/directives/autofocus.directive';
import { GenericLineGraphComponent } from '../shared-components/charts/generic-line-graph/generic-line-graph.component';
import { GenericBarGraphComponent } from '../shared-components/charts/generic-bar-graph/generic-bar-graph.component';
import { GenericPieGraphComponent } from '../shared-components/charts/generic-pie-graph/generic-pie-graph.component';
import { InfiniteScrollDirective } from '../shared-components/directives/infinite-scroll.directive';
import { GenericTableToggleColumnComponent } from '../shared-components/generic-table-toggle-column/generic-table-toggle-column.component';
import { CustomDateRangePickerComponent } from '../shared-components/custom-date-range-picker/custom-date-range-picker.component';
import { ProgressBarComponent } from '../shared-components/progress-bar/progress-bar.component';
import { CustomTextInputComponent } from '../shared-components/custom-text-input/custom-text-input.component';
import { ManageSubscriptionComponent } from '../shared-components/manage-subscription/manage-subscription.component';
import { GenericTwoInputDialogComponent } from '../shared-components/generic-two-input-dialog/generic-two-input-dialog.component';
import { SelectDropdownV2Component } from '../shared-components/dropdowns-v2/select-dropdown-v2/select-dropdown-v2.component';
import { SelectDrpWFilterWInputComponent } from '../shared-components/custom-dropdowns/select-drp-w-filter-w-input/select-drp-w-filter-w-input.component';
import { SelectDrpWButtonComponent } from '../shared-components/custom-dropdowns/select-drp-w-button/select-drp-w-button';
import { RoleBasedControlDirective } from '../shared-components/directives/role-based-control.directive';
import { CustomDateRangePickerButtonComponent } from '../shared-components/custom-date-range-picker-button/custom-date-range-picker-button.component';
import { CustomReplyQuillEditorComponent } from '../social-media-influencer/modules/outreach-v2/component-pieces/custom-reply-quill-editor/custom-reply-quill-editor.component';
import { TabBarWithUnderlineIndicatorComponent } from '../shared-components/tab-bar-with-underline-indicator/tab-bar-with-underline-indicator.component';
import { TabBarBlueComponent } from '../shared-components/tab-bars/tab-bar-blue/tab-bar-blue.component';
import { SequenceTableModule } from '../social-media-influencer/modules/outreach-v2/components/outreach-sequence/sequence-table/sequence-table.module';

const importExport = [LollySpinnerLoaderComponent];

@NgModule({
	imports: [
		MaterialModule,
		NgxDaterangepickerMd,
		CommonModule,
		StoreModule.forFeature('shared', sharedReducer),
		StoreModule.forFeature('user', userReducer),
		EffectsModule.forFeature([AdAccountEffects, GeneralSettingsEffects]),
		AgGridModule.withComponents([DefaultNoRowOverlayComponent]),
		MatButtonToggleModule,
		NgSelectModule,
		TranslateModule,
		NgbDatepickerModule,
		GridsterModule,
		DragDropModule,
		MatMenuModule,
		NgxEchartsModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		MatSnackBarModule,
		ScrollingModule,
		InputsModule,
		FormFieldModule,
		NumericTextBoxModule,
		TextBoxModule,
		RouterModule,
		MatSortModule,
		DropDownsModule,
		DialogsModule,
		QuillModule,
		NgxSkeletonLoaderModule,
		SequenceTableModule,
		...importExport
	],
	declarations: [
		TabBarBlueComponent,
		TabBarWithUnderlineIndicatorComponent,
		RoleBasedControlDirective,
		SelectDropdownV2Component,
		ManageSubscriptionComponent,
		CustomTextInputComponent,
		ProgressBarComponent,
		CustomDateRangePickerComponent,
		CustomDateRangePickerButtonComponent,
		GenericTableToggleColumnComponent,
		GenericPieGraphComponent,
		GenericBarGraphComponent,
		GenericLineGraphComponent,
		InfiniteScrollDirective,
		GenericTableComponent,
		GenericInputDialogComponent,
		GenericTwoInputDialogComponent,
		WebsiteInputComponent,
		GoogleAccountLandingComponent,
		GenericSidePopupComponent,
		AddListToCampaignComponent,
		NewGenericDialogComponent,
		HintsComponent,
		BulkEmailDialogComponent,
		ExtendBudgetDialogComponent,
		PriorityToasterComponent,
		NewBudgetInputComponent,
		CustomBudgetInputComponent,
		CustomSideStepperComponent,
		GmailLandingComponent,
		AutoMultiSelectGroupedComponent,
		SelectDrpWithCustomSearchComponent,
		CustomCalendarDatePickerComponent,
		AutoInputChipComponent,
		LollyLoadingDialogComponent,
		MultiDrpLocationsComponent,
		DateInputComponent,
		DropzoneDirective,
		AutofocusDirective,
		SimpleTextInputComponent,
		GenericSmDialogComponent,
		AutoSearchMultiDrpChipComponent,
		AutoMultiDrpWithChipsComponent,
		SelectDrpWoInputComponent,
		SelectDrpWInputComponent,
		SelectDrpWFilterWInputComponent,
		IGreyTooltipComponent,
		CampaignCreationProductSeedingComponent,
		CreateProductSeedingStepOneComponent,
		CreateProductSeedingStepTwoComponent,
		CreateProductSeedingStepThreeComponent,
		MultiSelectAutoDropdownComponent,
		NestedDropdownCheckboxComponent,
		InputNoSpecialComponent,
		NoSpecialCharsDirective,
		CustomTooltipComponent,
		MinusPipe,
		Graph06ProgressbarComponent,
		PasswordoutlineTbComponent,
		OutlineTbComponent,
		AppMessageCardComponent,
		LollyStepperComponent,

		AnalyticsInfluencerCardComponent,
		AnalyticsContentCardComponent,
		DefaultNoRowOverlayComponent,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		ButtonComponent,
		WelcomePageComponent,
		NoResourceComponent,
		CalendarComponent,
		NgbToMomentToString,
		ManageAdAccountPermissionsComponent,
		FilterTextPipe,
		AppCardListComponent,
		ContainsPipe,
		ConfirmationDialogComponent,
		RoundedImageContainerComponent,
		CamelCaseToTextPipe,
		InputTextComponent,
		ArraySortPipe,
		InputNumberComponent,
		InputNumberValueDirective,
		CheckPermissionsDirective,
		PrimaryButtonComponent,
		InputTextareaComponent,
		InputPasswordComponent,
		DropdownSearchSelectComponent,
		InputCardNumberComponent,
		InputNumberCardDirective,
		InputCvcComponent,
		InputBillingDateComponent,
		DragAndDropDirective,
		FilterArrayPipe,
		GenericPopupComponent,
		TablePermissionsComponent,
		BackOfficeNavigationComponent,
		TableTabsItemsComponent,
		CustomDateComponent,
		MasterTableComponent,
		AgGridDirective,
		LimitLengthPipe,
		GenericDropdownComponent,
		CustomFieldCellRendererComponent,
		SafeURLPipe,
		InputFieldComponent,
		ConfirmDialogComponent,
		SuccessDialogComponent,
		InputFieldPasswordComponent,
		TruncateDirective,
		DiscoveryDrpComponent,
		ITooltipComponent,
		InfoTooltipComponent,
		//new components
		DropdownWithInputComponent,
		DropdownWoInputComponent,
		TextInputComponent,
		SwitchComponent,
		TextInputWithLabelComponent,
		ButtonComponent,
		InputWithAddMoreComponent,
		CheckboxComponent,
		SearchInputComponent,
		ToggleSwitchComponent,
		EmailInputFieldComponent,
		GlobalPopupComponent,
		FiledFontComponent,
		TextInputNumberComponent,
		AppAnalyticsProfileDetailsCardComponent,
		LollyBtn03Component,
		VideoPopupComponent,
		CreditLimitComponent,
		SelectDrpWButtonComponent,
		CustomReplyQuillEditorComponent
	],
	exports: [
		TabBarBlueComponent,
		TabBarWithUnderlineIndicatorComponent,
		RoleBasedControlDirective,
		SelectDropdownV2Component,
		ManageSubscriptionComponent,
		CustomTextInputComponent,
		ProgressBarComponent,
		CustomDateRangePickerComponent,
		CustomDateRangePickerButtonComponent,
		GenericTableToggleColumnComponent,
		GenericPieGraphComponent,
		GenericBarGraphComponent,
		GenericLineGraphComponent,
		InfiniteScrollDirective,
		GenericTableComponent,
		GenericInputDialogComponent,
		WebsiteInputComponent,
		GoogleAccountLandingComponent,
		GenericSidePopupComponent,
		AddListToCampaignComponent,
		NewGenericDialogComponent,
		HintsComponent,
		BulkEmailDialogComponent,
		ExtendBudgetDialogComponent,
		PriorityToasterComponent,
		NewBudgetInputComponent,
		CustomBudgetInputComponent,
		CustomSideStepperComponent,
		AutoMultiSelectGroupedComponent,
		NgxDaterangepickerMd,
		DragDropModule,
		SelectDrpWInputComponent,
		SelectDrpWFilterWInputComponent,
		MultiDrpLocationsComponent,
		CustomCalendarDatePickerComponent,
		AutoInputChipComponent,
		LollyLoadingDialogComponent,
		DropzoneDirective,
		AutofocusDirective,
		DateInputComponent,
		SimpleTextInputComponent,
		GenericSmDialogComponent,
		AutoSearchMultiDrpChipComponent,
		AutoMultiDrpWithChipsComponent,
		SelectDrpWoInputComponent,
		IGreyTooltipComponent,
		CampaignCreationProductSeedingComponent,
		CreateProductSeedingStepOneComponent,
		CreateProductSeedingStepTwoComponent,
		CreateProductSeedingStepThreeComponent,
		MultiSelectAutoDropdownComponent,
		NestedDropdownCheckboxComponent,
		InputNoSpecialComponent,
		NoSpecialCharsDirective,
		LollyBtn03Component,
		QuillModule,
		TextInputNumberComponent,
		AppAnalyticsProfileDetailsCardComponent,
		AppCardListComponent,
		CustomTooltipComponent,
		AppMessageCardComponent,
		FiledFontComponent,
		DialogsModule,
		GlobalPopupComponent,
		ITooltipComponent,
		InfoTooltipComponent,
		PasswordoutlineTbComponent,
		OutlineTbComponent,
		MinusPipe,
		Graph06ProgressbarComponent,
		LollyStepperComponent,
		LollySpinnerLoaderComponent,
		AnalyticsInfluencerCardComponent,
		AnalyticsContentCardComponent,
		CheckPermissionsDirective,
		MatInputModule,
		MatNativeDateModule,
		MatRippleModule,
		MatDatepickerModule,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		NoResourceComponent,
		TranslateModule,
		NgbDatepickerModule,
		ManageAdAccountPermissionsComponent,
		WelcomePageComponent,
		MatButtonToggleModule,
		MatMenuModule,
		AgGridModule,
		NgxEchartsModule,
		CalendarComponent,
		FilterTextPipe,
		ContainsPipe,
		CamelCaseToTextPipe,
		RoundedImageContainerComponent,
		PrimaryButtonComponent,
		// FormatTextPipe,
		MaterialModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		MatSnackBarModule,
		ScrollingModule,
		InputTextComponent,
		ArraySortPipe,
		GridsterModule,
		ButtonComponent,
		InputNumberComponent,
		InputTextareaComponent,
		InputPasswordComponent,
		DropdownSearchSelectComponent,
		NgSelectModule,
		InputCardNumberComponent,
		InputCvcComponent,
		InputBillingDateComponent,
		DragAndDropDirective,
		FilterArrayPipe,
		MasterTableComponent,
		TableTabsItemsComponent,
		TablePermissionsComponent,
		BackOfficeNavigationComponent,
		LimitLengthPipe,
		GenericPopupComponent,
		GenericDropdownComponent,
		DropDownsModule,
		SafeURLPipe,
		MatSortModule,
		InputFieldComponent,
		ConfirmationDialogComponent,
		ConfirmDialogComponent,
		InputFieldPasswordComponent,
		TruncateDirective,
		DiscoveryDrpComponent,
		DropdownWithInputComponent,
		DropdownWoInputComponent,
		TextInputComponent,
		SwitchComponent,
		TextInputWithLabelComponent,
		ButtonComponent,
		InputWithAddMoreComponent,
		CheckboxComponent,
		SearchInputComponent,
		ToggleSwitchComponent,
		EmailInputFieldComponent,
		NgxSkeletonLoaderModule,
		SelectDrpWithCustomSearchComponent,
		SelectDrpWButtonComponent,
		CustomReplyQuillEditorComponent,
		SequenceTableModule,
		...importExport
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [
		NoSpecialCharsDirective,
		ShortNumberPipe,
		TablePermissionService,
		PermissionsService,
		ErrorMessageService,
		TitleCasePipe,
		SnakeCaseToHuman,
		DatePipe,
		CurrencyPipe,
		CalendarService,
		BusinessOwnerService,
		UserServiceApi,
		CheckPermissionsDirective,
		InterceptorsProvider,
		{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'outline' }
		}
	]
})
export class SharedModule {}
