import { PaginationResponseModel } from 'src/app/shared/models/pagination.model';
import { EcommercePlatformsEnum } from './ecommerce.enum';

export interface EcommerceGenericModel {
	id: number;
	name: string;
}

export interface EcommerceGetConnectedPlatformResponse {
	platform?: EcommercePlatformsEnum;
}

export interface ImportCatalogsResponse {
	data: ImportedCatalogModel[];
}

export interface ImportedCatalogModel {
	id: number;
	handle: string;
	title: string;
	updated_at: string;
	body_html?: string;
	published_at: string;
	sort_order?: string;
	template_suffix?: string;
	published_scope: string;
	admin_graphql_api_id: string;
	image?: ShopifyCollectionImage;
}

export interface CatalogToImportModel {
	id: number;
	handle: string;
	title: string;
}

export interface ShopifyCollectionImage {
	created_at: string;
	alt?: string;
	width: number;
	height: number;
	src: string;
}

export interface EcommerceGetCatalogResponse {
	data: EcommerceCatalogModel[];
	pagination: PaginationResponseModel;
}

export interface EcommerceCatalogModel {
	id: number;
	title: string;
	no_of_products: number;
}

export interface CatalogCreationModel {
	title: string;
}

export interface CatalogDeletionModel {
	catalog_ids: number[];
}
