<div class="dropdown-container" [style]="cssStyle">
	<button
		class="dropdown-button"
		mat-icon-button
		(click)="toggleDropdown()"
		[disabled]="disabled"
	>
		<mat-icon class="icon" [style]="iconStyle">{{icon}}</mat-icon>
	</button>
	<div class="dropdown-menu" *ngIf="isOpen">
		<mat-option
			class="dropdown-option"
			*ngIf="showNullOption"
			[value]="null"
			(click)="optionSelected(null)"
		>
			{{ firstOptionName }}
		</mat-option>
		<mat-option
			class="dropdown-option"
			*ngFor="let option of getOptions()"
			[value]="option"
			(click)="optionSelected(option)"
		>
			<div class="d-flex justify-content-between">
				<span *ngIf="dropdownControl?.value != option" class="title">
					{{ option[titleKey] }}
				</span>
				<span
					*ngIf="dropdownControl?.value == option"
					class="title selected"
				>
					{{ option[titleKey] }}
				</span>

				<mat-icon
					class="mat-icons-round option-icon selected"
					*ngIf="option.data.icon && dropdownControl?.value == option"
				>
					{{option.data.icon}}
				</mat-icon>
				<mat-icon
					class="mat-icons-round option-icon"
					*ngIf="option.data.icon && dropdownControl?.value != option"
				>
					{{option.data.icon}}
				</mat-icon>
			</div>
		</mat-option>
		<div class="dropdown-option" *ngIf="getDateOption()">
			<app-custom-date-range-picker-button
				class="custom-date-range-picker"
				(selected)="optionSelected(getDateOption())"
				[dateControl]="dateControl"
				label="Date"
			></app-custom-date-range-picker-button>

			<mat-icon
				class="mat-icons-round option-icon selected"
				*ngIf="getDateOption().data.icon && dropdownControl?.value == getDateOption()"
			>
				{{getDateOption().data.icon}}
			</mat-icon>
			<mat-icon
				class="mat-icons-round option-icon"
				*ngIf="getDateOption().data.icon && dropdownControl?.value != getDateOption()"
			>
				{{getDateOption().data.icon}}
			</mat-icon>
		</div>
	</div>
</div>
