import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
	ToastV2Model,
	ToastV2TypeEnum
} from 'src/app/toast-v2/models/toast-v2.model';

@Injectable({
	providedIn: 'root'
})
export class ToastNotificationService {
	private toastSubject = new Subject<ToastV2Model>();
	private dismissSubject = new Subject<void>();

	constructor() {}

	private createNewToast(
		type: ToastV2TypeEnum,
		message: string,
		duration?: number,
		title?: string
	): ToastV2Model {
		return new ToastV2Model(type, message, duration, title);
	}

	public getToasts(): Observable<ToastV2Model> {
		return this.toastSubject.asObservable();
	}

	public getDismissSubject(): Observable<void> {
		return this.dismissSubject.asObservable();
	}

	public sendSuccessToast(
		message: string,
		duration?: number,
		title?: string
	): void {
		const toast = this.createNewToast(
			ToastV2TypeEnum.SUCCESS,
			message,
			duration,
			title
		);
		this.toastSubject.next(toast);
	}

	public sendErrorToast(
		message: string,
		duration?: number,
		title?: string
	): void {
		const toast = this.createNewToast(
			ToastV2TypeEnum.ERROR,
			message,
			duration,
			title
		);
		this.toastSubject.next(toast);
	}

	public sendWarningToast(
		message: string,
		duration?: number,
		title?: string
	): void {
		const toast = this.createNewToast(
			ToastV2TypeEnum.WARNING,
			message,
			duration,
			title
		);
		this.toastSubject.next(toast);
	}

	public sendInfoToast(
		message: string,
		duration?: number,
		title?: string
	): void {
		const toast = this.createNewToast(
			ToastV2TypeEnum.INFO,
			message,
			duration,
			title
		);
		this.toastSubject.next(toast);
	}

	public sendCustomToast(
		message: string,
		type: ToastV2TypeEnum,
		duration?: number,
		title?: string
	): void {
		const toast = this.createNewToast(type, message, duration, title);
		this.toastSubject.next(toast);
	}

	public dismiss(): void {
		this.dismissSubject.next();
	}
}
