<div class="website-input-wrapper">
	<div class="prefix-text">https://</div>
	<input
		[formControl]="inputControl"
		type="text"
		class="website-input"
		placeholder="example.com"
		(paste)="onPaste($event)"
	/>
</div>
