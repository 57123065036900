import { Injectable } from '@angular/core';
import { StorageKey } from '../_models/local-storage-key';
import { UserDetailsInterface } from '../_models/identity-models/user-details.interface';
import { BaseApiUrl } from './base-api-urls';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenDetailInterface } from '../_models/token.interface';
import { EnumerationEx } from '../_models/enumeration';

@Injectable({
	providedIn: 'root'
})
export class TokenService {
	public token: string;

	constructor(protected http: HttpClient) {}

	public decodeToken(token: string = this.token): UserDetailsInterface {
		const jwtData = token.split('.')[1];
		const decodedJwtJsonData = window.atob(jwtData);
		const fullTokenAsJson = JSON.parse(decodedJwtJsonData);
		localStorage.setItem(
			StorageKey.decodedJwtIo,
			JSON.stringify(fullTokenAsJson)
		);
		return fullTokenAsJson;
	}

	public enableConfirmToken(status: boolean): Observable<boolean> {
		return this.http.post<boolean>(
			`${BaseApiUrl.Users}Users/enable-twoFactorAuth?IsEnabled=${status}`,
			{ IsEnabled: status }
		);
	}

	public enablePaymentConfirmToken(payload: any): Observable<boolean> {
		return this.http.post<boolean>(
			`${BaseApiUrl.Contact}Contacts/enable-paymentFailure-notification`,
			payload
		);
	}

	public addContactToken(formData: any): Observable<boolean> {
		return this.http.post<boolean>(
			`${BaseApiUrl.UserVerification}2fa/`,
			formData
		);
	}

	public verifyContactToken(verifyObj: any): Observable<boolean> {
		return this.http.post<boolean>(
			`${BaseApiUrl.UserVerification}verify/verify-contact`,
			verifyObj
		);
	}

	public verifyDeleteContactToken(verifyObj: any): Observable<boolean> {
		return this.http.post<boolean>(
			`${BaseApiUrl.UserVerification}verify/verify-delete`,
			verifyObj
		);
	}

	public addPaymentToken(formData: any): Observable<boolean> {
		return this.http.post<boolean>(
			`${BaseApiUrl.UserVerification}2fa/paymentFailure`,
			formData
		);
	}

	public getPaymentFailureStatus(): Observable<boolean> {
		return this.http.get<boolean>(
			`${BaseApiUrl.Contact}Contacts/paymentFailure-notification-status`
		);
	}

	public get2TwoFactorStatus(): Observable<boolean> {
		return this.http.get<boolean>(
			`${BaseApiUrl.Users}Users/twoFactorAuth-enabled-status`
		);
	}

	public getContactToken(): Observable<string> {
		return this.http.get<string>(`${BaseApiUrl.UserVerification}2fa/`);
	}

	public get2FactorToken(): Observable<boolean> {
		return this.http.get<boolean>(
			`${BaseApiUrl.UserVerification}2fa/enable2fa`
		);
	}

	public enableDisable2FactorAuth(verifyObj: any): Observable<boolean> {
		return this.http.post<boolean>(
			`${BaseApiUrl.UserVerification}verify/verify-enable2fa?code=${verifyObj}`,
			''
		);
	}

	public refreshToken(token?: string): Observable<any> {
		let headers = new HttpHeaders({ Authorization: token });

		return this.http.post(
			`${BaseApiUrl.SocialInfluencerPython}users/refresh-token`,
			{},
			{ headers: headers }
		);
	}
}
