import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import {
	NavigationEnd,
	RouteConfigLoadEnd,
	RouteConfigLoadStart,
	Router,
	RouterEvent
} from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { interval, Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import { buildGeneratedValues } from '../../../../build-generated-values';
import { environment } from '../../../environments/environment';
import { AccountTypeService } from '../../shared/account-type.service';
import { ButtonClassEnum } from '../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../shared/button-component/button-type.enum';
import { AccountsPermissions } from '../../shared/permisions/enums/accounts-permissions';
import { AdsManagerPermissions } from '../../shared/permisions/enums/ads-manager-permissions';
import { BackofficePermissions } from '../../shared/permisions/enums/backoffice-permissions';
import { Modules } from '../../shared/permisions/enums/modules';
import { OptimizePermissions } from '../../shared/permisions/enums/optimize-permissions';
import { PixelPermissions } from '../../shared/permisions/enums/pixel-permissions';
import { ReportsPermissions } from '../../shared/permisions/enums/reports-permissions';
import { PermissionsDirectiveInterface } from '../../shared/permisions/models/permissions-directive.interface';
import {
	getGlobalSpinnerStatus,
	getPublishStatus,
	SharedState
} from '../../shared/state/shared.reducer';
import { SetUserDetails } from '../../shared/state/user/user.actions';
import {
	getAccountState,
	getPermissions,
	getUserDetails,
	hasBackOfficePermission,
	isUserImpersonated,
	UserState
} from '../../shared/state/user/user.reducer';
import {
	getActiveUserManagementPage,
	UserManagementState
} from '../../user-management/state/user-management.reducer';
import { UserDetails } from '../../_models/identity-models/identity-detail';
import { StorageKey } from '../../_models/local-storage-key';
import { AccountSelectedGroup } from '../../_models/user-models/account-selected-group';
import { AuthenticationService } from '../../_services/authentication.service';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { ChannelButton } from './sidenav-button-channel.model';
// import { CampaignSmartCreateService } from '../../campaign-smart-create/services/campaign-smart-create.service';
import { CampaignBuilderPermissions } from '../../shared/permisions/enums/campaign-builder-permissions';
import { UpdatePublishStatus } from '../../shared/state/shared.actions';
import { PublishStatusInterface } from '../../shared/state/state-entities/publish/publish-status.interface';
import { Publish } from '../../shared/state/state-entities/publish/publish-status.model';
// import { PublishResponseInterface } from '../../campaign-smart-create/models/publish-response.interface';
import { MatMenuTrigger } from '@angular/material/menu';
import { AudiencePermissions } from 'src/app/shared/permisions/enums/audience-permissions';
import { CreativeBuilderPermissions } from 'src/app/shared/permisions/enums/creative-builder-permissions';
import { InfluencerPermissions } from 'src/app/shared/permisions/enums/influencer-permissions';
import { ProductCatalogPermissions } from 'src/app/shared/permisions/enums/product-catalog-permissions';
import { UserRejectedReason } from 'src/app/_services/user/user-rejected-reason.enum';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { ScrollService } from '../../_services/scroll.service';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss'],
	providers: [ScrollService]
})
export class SidenavComponent implements OnInit, OnDestroy {
	@ViewChild('sidenav') public sidenav: MatSidenav;
	@ViewChild('firstTimeLogin') public firstTimeLogin: TemplateRef<any>;
	@ViewChild('menuTrigger') public trigger: MatMenuTrigger;

	public accountSelectedGroupByDefault: AccountSelectedGroup =
		AccountSelectedGroup.MyAccount;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public backOfficePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.BackOffice,
		permissions: [BackofficePermissions.CanAccessBackOffice]
	};
	public accountsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Accounts,
		permissions: [AccountsPermissions.CanAccessAccounts]
	};
	public adsManagerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.AdsManager,
		permissions: [AdsManagerPermissions.CanAccessAdsManager]
	};
	public campaignBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CampaignBuilder,
		permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
	};
	public creativeBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CreativeBuilder,
		permissions: [CreativeBuilderPermissions.CanAccessCreativeBuilder]
	};
	public optimizePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Optimize,
		permissions: [OptimizePermissions.CanAccessOptimize]
	};
	public pixelPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Pixel,
		permissions: [PixelPermissions.CanAccessPixels]
	};
	public audiencePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Audience,
		permissions: [AudiencePermissions.CanAccessAudience]
	};
	public reportsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Reports,
		permissions: [ReportsPermissions.CanAccessReports]
	};
	public influencerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Influencer,
		permissions: [InfluencerPermissions.FullAccess]
	};
	public productCatalogPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.ProductCatalog,
		permissions: [ProductCatalogPermissions.CanAccessProductCatalog]
	};
	public showSpinner = false;
	public isAdmin = false;
	public isImpersonated = false;
	public unpaidInvoice = false;
	public showProgressBar = false;
	public invoicesList: InvoiceHistory[];
	public timestamp: string;
	public userDetails: UserDetails;
	public showUserManagement: boolean;
	public dropdownIsVisible: boolean;
	public selectedButton: ChannelButton;
	public accountState: number;
	public currentUrl: string;
	public progressMin = 0;
	public progressMax: number;
	public progressStatus: number;
	public isLoading: boolean;
	public isSocialMediaInfluencer: boolean;
	public isInfluencerPlatform: boolean;
	public progressBarLabel: LabelSettings = {
		position: 'end',
		visible: true,
		format: () => `${this.progressStatus + ' of ' + this.progressMax}`
	};
	public userStateEnum = UserStateEnum;
	public pcHover = false;
	public pcChildHover = false;
	public userStatus: UserRejectedReason;
	public isSelectedNoBO: string;
	public isBO = false;
	productCatalogMenuSelected: boolean;
	selectedRoute: string;
	public newPointPosition: string = '0px';

	public get navHover(): boolean {
		return this.pcHover || this.pcChildHover;
	}

	public hideSideBar: number = 0;

	private expandAccountMenuSubscription = new Subscription();
	private unsubscriber$ = new Subject<void>();
	private asyncLoadCount: number;
	public hasSMI: boolean;
	public smiHover = false;
	public smiChildHover = false;
	public get smiNavHover(): boolean {
		return this.smiHover || this.smiChildHover;
	}

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private authV2Service: AuthenticationV2Service,
		private accountTypeService: AccountTypeService,
		private config: NgSelectConfig,
		private store: Store<UserManagementState>,
		private dialog: MatDialog,
		private userServiceApi: UserServiceApi,
		public translate: TranslateService,
		private sharedStore: Store<SharedState>,
		private userStore: Store<UserState>,
		private backOfficeService: BackOfficeService,
		// private smartCreateService: CampaignSmartCreateService,//silsrang-migration
		private toastService: ToastNotificationService,
		private scrollService: ScrollService,
		public cdRef: ChangeDetectorRef
	) {
		this.asyncLoadCount = 0;
		this.router.events
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((event: RouterEvent) => {
				if (event instanceof RouteConfigLoadStart) {
					this.asyncLoadCount++;
					this.showSpinner = !!this.asyncLoadCount;
				} else if (event instanceof RouteConfigLoadEnd) {
					this.asyncLoadCount--;
					this.showSpinner = !!this.asyncLoadCount;
				}

				if (event instanceof NavigationEnd) {
					if (event.id === 1) {
						this.isUserAdmin();
					}
					this.userStore
						.pipe(select(getUserDetails), take(1))
						.subscribe(user => {
							this.userStore
								.pipe(select(hasBackOfficePermission), take(1))
								.subscribe(isAdmin => {
									this.currentUrl = event.url;
									if (!isAdmin) {
										this.backOfficeService
											.getUserState(user.FiledId)
											.pipe(takeUntil(this.unsubscriber$))
											.subscribe(accountState => {
												this.currentUrl = event.url;

												if (
													this.currentUrl !==
														'/user-management/billing-and-payment' &&
													accountState ===
														UserStateEnum.BillPendingRestrictedAccess
												) {
													this.router.navigate([
														'/user-management/billing-and-payment'
													]);
												} else if (
													accountState ===
														UserStateEnum.FreemiumExpiredNoCreditCard ||
													accountState ===
														UserStateEnum.FreeTrialExpiredNoCreditCard
												) {
													this.router.navigate([
														'/authentication/trial-end'
													]);
												} else if (
													accountState ===
													UserStateEnum.NoCreditCard
												) {
													this.router.navigate([
														'/authentication/add-billing-address'
													]);
												} else if (
													accountState ===
													UserStateEnum.NoBusinessOwner
												) {
													this.router.navigate([
														'/authentication/connect'
													]);
												}
											});
									}
								});
						});
					// this.hasSMI = event.url.split('/')[1] == 'influencer';
				}
			});

		this.getUserPermissions();

		if (environment.Flags.hasTimestamp) {
			this.timestamp = buildGeneratedValues.timeStamp;
		}

		this.config.appendTo = 'body';
		// Configs for ng-select
		this.config.bindValue = 'value';
	}

	public ngOnInit(): void {
		this.isUserAdmin();
		this.initializeSpinner();

		this.hideSideBar = +localStorage.getItem('hideBar') || 0;

		if (this.selectedButton) {
			this.accountTypeService.setAccountType(
				this.selectedButton.sourceChannel
			);
			// this.accountTypeService.setAccountPlatform(this.selectedButton.sourcePlatform);
		}
		this.impersonationHandle();
		this.checkUserManagementState();
		this.isUnpaidInvoice();
		this.initPublishListener();
		this.authenticationService.setRoute$
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(route => {
				this.selectedRoute = route;
				if (route && route !== '') {
					this.isSelectedNoBO = route;
				}
			});
		this.triggerSelectedPoint();
	}

	public ngAfterViewChecked(): void {
		this.cdRef.detectChanges();
	}

	public ngOnDestroy(): void {
		// this.authenticationService.setDeniedRoute$.next(null); //DEBUGGING
		this.unsubscriber$.next();
		this.unsubscriber$.complete();

		this.removeSubscriptions();
	}

	public getUserPermissions(): void {
		this.sharedStore
			.pipe(select(getPermissions), take(1))
			.subscribe(roles => {
				if (
					roles[0].module == Modules.Influencer &&
					roles[1].module == Modules.Miscellaneous &&
					roles[2].module == Modules.Settings &&
					roles.length === 3
				) {
					this.hasSMI = true;
				}
			});
	}

	public triggerSelectedPoint(): void {
		interval(200)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				const activeElementRef = document.getElementsByClassName(
					'selectedItem'
				)[0] as HTMLElement;
				const activeElementRefOther = document.getElementsByClassName(
					'isSelectedNoBO'
				)[0] as HTMLElement;
				let elementBounderies = null;
				if (activeElementRef) {
					elementBounderies = activeElementRef?.getBoundingClientRect();
				} else {
					elementBounderies = activeElementRefOther?.getBoundingClientRect();
				}

				this.newPointPosition =
					(
						(elementBounderies ? elementBounderies.y : 0) + 21
					).toString() + 'px';
			});
	}

	public setPointPosition(): Object {
		let styles = {
			top: this.newPointPosition,
			left: '-4px'
		};
		return styles;
	}

	public onScroll(event: any): void {
		this.scrollService.changeScrollY(event.target.scrollTop);
	}

	public exitImpersonation(): void {
		const oldToken = localStorage.getItem(StorageKey.oldToken);
		const oldDecodedToken = localStorage.getItem(
			StorageKey.oldDecodedJwtIo
		);

		this.userStore
			.pipe(select(getUserDetails), take(1))
			.subscribe(userDetails => {
				this.userDetails = userDetails;
			});
		this.router.navigate(['/back-office']);

		localStorage.setItem(StorageKey.token, oldToken);
		localStorage.setItem(
			StorageKey.decodedJwtIo,
			JSON.stringify(oldDecodedToken)
		);

		this.userDetails.IsImpersonated = false;
		this.userStore.dispatch(new SetUserDetails(this.userDetails));

		this.resetPermissions();
	}

	public impersonationHandle(): void {
		this.userStore
			.pipe(select(getUserDetails), takeUntil(this.unsubscriber$))
			.subscribe(details => {
				const googleBO = JSON.parse(
					localStorage.getItem('installedPlatorm')
				)?.find(value => {
					return value === 'Google';
				});
				this.isBO =
					details?.FacebookBusinessOwnerId !== '' &&
					details?.FacebookBusinessOwnerId
						? true
						: false;
				this.userStatus = details?.RejectedReason;
				this.resetPermissions();
			});
		this.userStore
			.pipe(select(isUserImpersonated), takeUntil(this.unsubscriber$))
			.subscribe(isImpersonated => {
				this.isImpersonated = isImpersonated;
				this.dropdownIsVisible = true;
				this.resetPermissions();
			});
		this.userStore
			.pipe(select(hasBackOfficePermission), take(1))
			.subscribe(isAdmin => {
				this.isAdmin = isAdmin;
				this.dropdownIsVisible = !isAdmin;
			});
	}

	public checkUserManagementState(): void {
		this.store
			.pipe(
				select(getActiveUserManagementPage),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(resp => {
				this.showUserManagement = resp;
			});
	}

	public openKnowledgeBase(): void {}

	public goToUserPage(): void {
		this.trigger.closeMenu();
		this.router.navigate(['/user-management/my-profile']);
	}

	public logout(): void {
		this.trigger.closeMenu();
		this.authV2Service.signoutUser();
	}

	public isUserAdmin(): void {
		this.userStore
			.pipe(select(hasBackOfficePermission), take(1))
			.subscribe(isAdmin => {
				this.isAdmin = isAdmin;
				if (!this.isAdmin) {
					// this.pollingPublish();
				}
			});
	}

	public initPublishListener(): void {
		this.sharedStore
			.pipe(
				select(getPublishStatus),
				takeUntil(this.unsubscriber$),
				debounceTime(6000)
			)
			.subscribe(status => {
				this.showProgressBar = status?.isActive;
				if (this.showProgressBar && !this.isAdmin) {
					this.isLoading = !status.progressStatus;
					this.progressStatus = status.progressStatus;
					this.progressMax = status.totalStructures;
					// this.pollingPublish();
				}
			});
	}

	// public pollingPublish(): void {
	// 	this.smartCreateService
	// 		.checkPublishStatus()
	// 		.pipe(
	// 			map(response => {
	// 				if (!response) {
	// 					throw new Error();
	// 				}
	// 				return response;
	// 			}),
	// 			retryWhen(errors => errors.pipe(take(5), delay(1000))),
	// 			catchError(() => {
	// 				this.sharedStore.dispatch(new HideGlobalSpinner());
	// 				this.toastService.sendCustomToast(
	// 					'Something went wrong, please try again later!',
	// 					ToastNotificationType.Warning,
	// 					8000,
	// 					'Error ! Could not Publish!'
	// 				);
	// 				return EMPTY;
	// 			})
	// 		)
	// 		.subscribe(response => {
	// 			if (response) {
	// 				const status: PublishStatusInterface = {
	// 					isActive: true,
	// 					totalStructures: response.totalStructures,
	// 					progressStatus: response.publishedStructures
	// 				};

	// 				setTimeout(() => {
	// 					this.getToastMessage(response);
	// 				}, 3000);

	// 				this.sharedStore.dispatch(new UpdatePublishStatus(status));
	// 			} else {
	// 				this.clearPublishProgress();
	// 			}
	// 		});
	// }

	public clearPublishProgress(): void {
		const notActive: PublishStatusInterface = {
			isActive: false,
			totalStructures: null,
			progressStatus: null
		};
		this.sharedStore.dispatch(new UpdatePublishStatus(notActive));
		this.showProgressBar = false;
	}

	// public getToastMessage(resp: PublishResponseInterface): void {
	public getToastMessage(resp: any): void {}

	public addHoverBar(): void {
		setTimeout(() => {
			this.pcHover = true;
		}, 100);
	}

	public removeHoverBar(): void {
		setTimeout(() => {
			this.pcHover = false;
		}, 100);
	}
	public addSMIHoverBar(): void {
		setTimeout(() => {
			this.smiHover = true;
		}, 100);
	}

	public removeSMIHoverBar(): void {
		setTimeout(() => {
			this.smiHover = false;
		}, 100);
	}

	private removeSubscriptions(): void {
		this.expandAccountMenuSubscription.unsubscribe();
	}

	private initializeSpinner(): void {
		this.sharedStore
			.select(getGlobalSpinnerStatus)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(status => (this.showSpinner = status));
	}

	private isUnpaidInvoice(): void {
		this.userStore
			.pipe(select(getAccountState), takeUntil(this.unsubscriber$))
			.subscribe(accountState => {
				this.accountState = accountState;

				if (this.accountState === 7 && !this.isAdmin) {
					this.unpaidInvoice = true;
				}
			});
	}

	private resetPermissions(): void {
		this.backOfficePermission = {
			moduleName: Modules.BackOffice,
			permissions: [BackofficePermissions.CanAccessBackOffice]
		};
		this.accountsPermission = {
			moduleName: Modules.Accounts,
			permissions: [AccountsPermissions.CanAccessAccounts]
		};
		this.adsManagerPermission = {
			moduleName: Modules.AdsManager,
			permissions: [AdsManagerPermissions.CanAccessAdsManager]
		};
		this.campaignBuilderPermission = {
			moduleName: Modules.CampaignBuilder,
			permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
		};
		this.optimizePermission = {
			moduleName: Modules.Optimize,
			permissions: [OptimizePermissions.CanAccessOptimize]
		};
		this.pixelPermission = {
			moduleName: Modules.Pixel,
			permissions: [PixelPermissions.CanAccessPixels]
		};
		this.audiencePermission = {
			moduleName: Modules.Audience,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.reportsPermission = {
			moduleName: Modules.Reports,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.influencerPermission = {
			moduleName: Modules.Influencer,
			permissions: [InfluencerPermissions.FullAccess]
		};
	}

	public accountsClickedNoBO(route: string): void {
		this.selectedRoute = route;
		this.authenticationService.setRoute$.next(route);
		if (route === 'influencer') {
			this.router.navigate(['influencer']);
		}
		return;
	}
}
