import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import {
	SignupWithEmailPasswordPayloadModel,
	SignupResponseModel,
	SignupWithGooglePayloadModel
} from '../models/signup.model';
import { Router } from '@angular/router';
import { PaymentLinkStatusEnum } from 'src/app/shared-components/models/payment-status.enum';
import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DiscoveryService } from 'src/app/social-media-influencer/modules/discovery-v2/services/discovery.service';
import { OutreachService } from 'src/app/social-media-influencer/modules/outreach-v2/services/outreach.service';
import {
	SigninResponseModel,
	SigninWithEmailPasswordPayloadModel,
	SigninWithGooglePayloadModel,
	VerifyEmailTokenResponseModel
} from '../models/signin.model';
import {
	UserAccountStateEnum,
	UserJWTDetailsInterface
} from '../models/auth.model';
import { UserRoleEnum } from 'src/app/shared-components/models/user-role.model';
import { getFormData } from 'src/app/shared/utils';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationV2Service {
	private userRole: UserRoleEnum = UserRoleEnum.OWNER;

	public authLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(
		private http: HttpClient,
		private router: Router,
		private sharedService: SharedService,
		private discoveryService: DiscoveryService,
		private outreachService: OutreachService
	) {
		const decodedToken = this.getCurrentDecodedToken();
		if (decodedToken) {
			this.userRole = decodedToken.role;
		}
	}

	public getUserRole(): UserRoleEnum {
		return this.userRole;
	}

	public isUserSignedIn(): boolean {
		const token = localStorage.getItem(StorageKey.token);
		if (!token) return false;

		const decodedJwtRaw = localStorage.getItem(StorageKey.decodedJwtIo);
		if (!decodedJwtRaw) return false;

		let decodedJwt;
		try {
			decodedJwt = JSON.parse(decodedJwtRaw);
		} catch (error) {
			console.error('Invalid JWT in storage:', error);
			return false;
		}

		if (!decodedJwt || typeof decodedJwt.exp !== 'number') {
			console.error('Invalid or missing "exp" property in JWT');
			return false;
		}

		const currentTime = moment();
		const expirationTime = moment(decodedJwt.exp * 1000);

		return currentTime.isBefore(expirationTime);
	}

	public signoutUser(routeAfterLogout?: string): void {
		localStorage.clear();
		this.router.navigate([routeAfterLogout ?? '/authentication']);
		this.clearAllModuleStates();
	}

	public signoutUserWithoutRedirect(): void {
		localStorage.clear();
		this.clearAllModuleStates();
	}

	public clearAllModuleStates(): void {
		this.discoveryService.clearState();
		this.outreachService.clearState();
		this.sharedService.clearState();
	}

	public getCurrentDecodedToken(): UserJWTDetailsInterface | null {
		const decodedJwtIoString = localStorage.getItem(
			StorageKey.decodedJwtIo
		);
		if (!decodedJwtIoString) {
			return null;
		}
		const userDetails: UserJWTDetailsInterface = JSON.parse(
			decodedJwtIoString
		);
		return userDetails;
	}

	public updateSignedInToken(token: string): UserJWTDetailsInterface {
		localStorage.setItem(StorageKey.token, token);
		const decodedJwtIo = this.decodeToken(token);
		localStorage.setItem(
			StorageKey.decodedJwtIo,
			JSON.stringify(decodedJwtIo)
		);
		this.userRole = decodedJwtIo.role;
		return decodedJwtIo;
	}

	public decodeToken(token: string): UserJWTDetailsInterface {
		const jwtData = token.split('.')[1];
		const decodedJwtJsonData = window.atob(jwtData);
		const fullTokenAsJson = JSON.parse(decodedJwtJsonData);
		return fullTokenAsJson;
	}

	public signinUserWithToken(
		token: string,
		callbackForComplete?: Function
	): void {
		const decodedJwtIo = this.updateSignedInToken(token);
		this.setFirstTimeValuesTrue();
		const userAccountState = parseInt(decodedJwtIo.user_account_state);

		switch (userAccountState) {
			case UserAccountStateEnum.COMPLETE:
			case UserAccountStateEnum.FAILED:
				if (callbackForComplete) {
					callbackForComplete();
				} else {
					this.router.navigate(['/']);
				}
				break;
			case UserAccountStateEnum.ONGOING:
			case UserAccountStateEnum.PENDING:
				this.getPaymentLink();
				break;
			default:
				this.signoutUser();
		}
	}

	public updateAccessTokenFromServer(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.refreshToken()
				.pipe(take(1))
				.subscribe(
					newToken => {
						if (newToken) {
							this.updateSignedInToken(newToken);
							resolve(newToken);
						} else {
							reject();
						}
					},
					err => {
						reject();
					}
				);
		});
	}

	public refreshToken(): Observable<string> {
		return this.http.post<string>(
			`${BaseApiUrl.SocialInfluencerPython}users/refresh-token`,
			{}
		);
	}

	private getPaymentLink(): void {
		const token = localStorage.getItem(StorageKey.token);
		this.sharedService
			.getUserPaymentStatus()
			.pipe(take(1))
			.subscribe(
				res => {
					if (res.status == PaymentLinkStatusEnum.SUCCESS) {
						const paymentLink = res.data;
						window.open(paymentLink, '_self');
					} else {
						this.router.navigate(['/authentication/payment'], {
							queryParams: { token }
						});
					}
				},
				err => {}
			);
	}

	public setFirstTimeValuesTrue(): void {
		localStorage.setItem(StorageKey.firstTimeDiscovery, 'true');
		localStorage.setItem(StorageKey.firstTimeCampaign, 'true');
		localStorage.setItem(StorageKey.firstTimeCRM, 'true');
		localStorage.setItem(StorageKey.first_time_lolly_user, 'true');
		localStorage.setItem(StorageKey.first_time_login_lists, 'true');
	}

	public signupUserWithEmailPassword(
		payload: SignupWithEmailPasswordPayloadModel
	): Observable<SignupResponseModel> {
		return this.http.post<SignupResponseModel>(
			`${BaseApiUrl.SocialMessengerPython}users/sign-up`,
			payload
		);
	}

	public signupUserWithGoogle(
		payload: SignupWithGooglePayloadModel
	): Observable<SignupResponseModel> {
		return this.http.post<SignupResponseModel>(
			`${BaseApiUrl.SocialMessengerPython}users/sign-up-google`,
			payload
		);
	}

	public subuserSignupWithEmailPassword(
		payload: SignupWithEmailPasswordPayloadModel,
		token: string
	): Observable<SignupResponseModel> {
		const headers = new HttpHeaders({
			Authorization: `Bearer ${token}`
		});
		return this.http.post<SignupResponseModel>(
			`${BaseApiUrl.SocialMessengerPython}subusers/sign-up`,
			payload,
			{ headers: headers }
		);
	}

	public subuserSignupWithGoogle(
		payload: SignupWithGooglePayloadModel,
		token: string
	): Observable<SignupResponseModel> {
		const headers = new HttpHeaders({
			Authorization: `Bearer ${token}`
		});
		return this.http.post<SignupResponseModel>(
			`${BaseApiUrl.SocialMessengerPython}subusers/sign-up-google`,
			payload,
			{ headers: headers }
		);
	}

	public resendVerificationMail(token: string): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: `Bearer ${token}`
		});
		return this.http.post<any>(
			`${BaseApiUrl.SocialMessengerPython}users/resend-verification-email`,
			{},
			{ headers: headers }
		);
	}

	public signinWithEmailPassword(
		payload: SigninWithEmailPasswordPayloadModel
	): Observable<SigninResponseModel> {
		return this.http.post<SigninResponseModel>(
			`${BaseApiUrl.SocialMessengerPython}users/sign-in`,
			payload
		);
	}

	public signinWithGoogle(
		payload: SigninWithGooglePayloadModel
	): Observable<SigninResponseModel> {
		return this.http.post<SigninResponseModel>(
			`${BaseApiUrl.SocialMessengerPython}users/sign-in-google`,
			payload
		);
	}

	public verifyEmailToken(
		token: string
	): Observable<VerifyEmailTokenResponseModel> {
		let queryParams = new HttpParams();
		queryParams = queryParams.append('token', token);
		return this.http.get<VerifyEmailTokenResponseModel>(
			`${BaseApiUrl.SocialMessengerPython}auth/verify`,
			{ params: queryParams }
		);
	}

	public authPreInstall(
		platform: string,
		redirect_url: string = ''
	): Observable<any> {
		return this.http.get(
			`${BaseApiUrl.SocialMessengerPython}oauth/${platform}/preinstall${redirect_url}`
		);
	}

	public forgotPassword(email: string): Observable<any> {
		const formData = new FormData();
		formData.append('email', email);
		return this.http.post(
			`${BaseApiUrl.SocialInfluencerPython}users/forgot-password`,
			formData
		);
	}

	public resetPassword(payload): Observable<object> {
		const formData = getFormData(payload);

		return this.http.post(
			`${BaseApiUrl.SocialInfluencerPython}users/update-password`,
			formData
		);
	}
}
