import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OutreachSequenceSummaryModel } from 'src/app/social-media-influencer/modules/outreach-v2/models/outreach-sequence-new.model';

@Component({
	selector: 'td[sequence-toggle-cell]',
	template: `
		<mat-slide-toggle
			class="custom-blue-slide-toggle"
			(click)="$event.preventDefault(); onCheck.emit(data)"
			[checked]="data.is_active"
		></mat-slide-toggle>
	`,
	styleUrls: ['./toggle-cell-component.scss'],
	imports: [CommonModule, MatSlideToggleModule],
	standalone: true
})
export class SequenceToggleCellComponent {
	@Input()
	data: OutreachSequenceSummaryModel;

	@Output()
	onCheck = new EventEmitter<OutreachSequenceSummaryModel>();
}
