<div class="">
	<!-- !tab -->
	<section id="faq-header" class="">
		<div
			class="container flex flex-col text-center px-6 sm:px-7 md:px-8 lg:px-10 lg:px-12 2xl:px-16"
		>
			<div class="button_gropu_tab">
				<button
					class="bg-yellow custom_button_bg faq-selector {{
						selectedPlansData === monthlyPlansData ? 'active' : ''
					}}"
					data-target="faq-brands"
					(click)="changeDuration(PlanDurationEnum.monthly)"
				>
					<strong>Monthly billing</strong>
					No contacts, cancel anytime
				</button>
				<button
					class="bg-purple custom_button_bg faq-selector {{
						selectedPlansData === yearlyPlansData ? 'active' : ''
					}}"
					data-target="faq-creators"
					(click)="changeDuration(PlanDurationEnum.yearly)"
				>
					<strong>Yearly billing</strong>
					Save 20%
				</button>
			</div>
		</div>
	</section>

	<ng-container *ngIf="loading">
		<div class="loader-wrapper">
			<div class="loader-inner" *ngFor="let loaders of [1, 2, 3]">
				<ngx-skeleton-loader
					appearance="line"
					style="height: 100%; width: 100%; border-radius: 27px;"
					[theme]="{
						height: '100%',
						width: '100%',
						'border-radius': '27px'
					}"
				></ngx-skeleton-loader>
			</div>
		</div>
	</ng-container>

	<!-- !pricing -->
	<section *ngIf="!loading" id="faq-brands" class="z-[2] pricing_tow_tab">
		<div
			class="container c-wrapper flex flex-col text-center px-6 sm:px-7 md:px-8 lg:px-10 lg:px-12 2xl:px-16"
		>
			<div class="main_price">
				<div class="pricing_box">
					<div
						*ngFor="
							let plan of selectedPlansData;
							let planSequence = index
						"
						class="inner_pricing middel_price {{
							plan?.stripe_price_id == currentPlan?.price_id &&
							currentPlan?.status == 'active'
								? 'active'
								: ''
						}}"
					>
						<p *ngIf="planSequence == 1">
							<img
								alt=""
								loading="lazy"
								src="assets\profile\6447dd0049378e8f47155cdf_Stars.svg"
								class="icon---xs"
								data-airgap-id="228"
							/>
							Most Popular
						</p>
						<div class="middel_inner">
							<ngx-skeleton-loader
								appearance="line"
								style="height: 32px; width: 150px;"
								[theme]="{ height: '32px', width: '150px' }"
								*ngIf="loading"
							></ngx-skeleton-loader>
							<h3
								[ngClass]="{
									first: planSequence == 0,
									last: planSequence == 2
								}"
								*ngIf="!loading"
							>
								{{ plan?.title }}
							</h3>
							<p>{{ plan?.description }}</p>
							<div class="price_price">
								<h4>
									<sup>
										{{
											0
												| currency
													: currencyCode
													: 'symbol-narrow'
												| slice: 0:1
										}}
									</sup>
									<!-- *skeleton loader -->
									<ngx-skeleton-loader
										appearance="line"
										style="height: 40px; width: 65px;"
										[theme]="{
											height: '40px',
											width: '65px'
										}"
										*ngIf="loading"
									></ngx-skeleton-loader>
									<span class="price" *ngIf="!loading">
										<ng-container
											*ngIf="
												plan?.interval ==
												PlanDurationEnum.monthly
											"
										>
											{{ plan?.price || 0 | number }}
										</ng-container>
										<ng-container
											*ngIf="
												plan?.interval ==
												PlanDurationEnum.yearly
											"
										>
											{{
												plan?.price / 12 || 0
													| number: '1.0-0'
											}}
										</ng-container>
									</span>
									<span class="duration">/mo</span>
								</h4>
								<p>Get Lolly tailored for your company.</p>
							</div>
							<div class="start_button">
								<a
									class="{{
										subscriptionData?.status == 'active' &&
										plan?.stripe_price_id ==
											this.currentPlan?.price_id &&
										plan?.interval ==
											this.currentPlan?.price_interval
											? 'pointer-none opacity-50'
											: ''
									}}"
									(click)="redirectToStripe(plan)"
									[ngClass]="{
										first: planSequence == 0,
										last: planSequence == 2
									}"
								>
									<div class="arrow">
										<img
											src="assets\profile\Icon feather-arrow-right.svg"
										/>
									</div>
									<span *ngIf="buttonText" class="custom-btn">
										{{ buttonText }}
									</span>
									<span *ngIf="!buttonText">
										{{ getButtonText(planSequence) }}
									</span>
								</a>
							</div>
							<div class="list_price_div">
								<ul>
									<li>
										<div
											class="d-flex k-align-items-center"
											style="gap: 10px;"
										>
											<ng-container
												*ngIf="
													plan?.limits
														?.search_results_limit
												"
											>
												{{
													plan?.limits
														?.search_results_limit
														| number
												}}
												Searches
											</ng-container>
											<ng-container
												*ngIf="
													!plan?.limits
														?.search_results_limit
												"
											>
												Unlimited Searches
											</ng-container>
											<span class="black-i-tooltip">
												i
												<div
													class="show-on-hover bottom-right"
												>
													Number of available
													discovery searches
												</div>
											</span>
										</div>
									</li>
									<li>
										<div
											class="d-flex k-align-items-center"
											style="gap: 10px;"
										>
											<ng-container
												*ngIf="
													plan?.limits
														?.viewed_profiles_limit
												"
											>
												{{
													plan?.limits
														?.viewed_profiles_limit
														| number
												}}
												Detailed Profiles
											</ng-container>
											<ng-container
												*ngIf="
													!plan?.limits
														?.viewed_profiles_limit
												"
											>
												Unlimited Detailed Profiles
											</ng-container>
											<span class="black-i-tooltip">
												i
												<div
													class="show-on-hover bottom-right"
												>
													In-Depth Insights: Access
													influencer demographics and
													more
												</div>
											</span>
										</div>
									</li>
									<li>
										<div
											class="d-flex k-align-items-center"
											style="gap: 10px;"
										>
											<ng-container
												*ngIf="
													plan?.limits
														?.viewed_emails_limit
												"
											>
												{{
													plan?.limits
														?.viewed_emails_limit
														| number
												}}
												Emails (View email ids)
											</ng-container>
											<ng-container
												*ngIf="
													!plan?.limits
														?.viewed_emails_limit
												"
											>
												Unlimited Emails (View email
												ids)
											</ng-container>
											<span class="black-i-tooltip">
												i
												<div
													class="show-on-hover bottom-right"
												>
													Get access to creator emails
													ids and communicate promptly
													with them
												</div>
											</span>
										</div>
									</li>
									<li
										*ngIf="
											plan?.limits?.has_advanced_search
										"
									>
										<div
											class="d-flex k-align-items-center"
											style="gap: 10px;"
										>
											Advanced Search
										</div>
									</li>
									<li>
										<div
											class="d-flex k-align-items-center"
											style="gap: 10px;"
										>
											<ng-container
												*ngIf="
													plan?.limits?.exports_limit
												"
											>
												{{
													plan?.limits?.exports_limit
														| number
												}}
												Influencers Export
											</ng-container>
											<ng-container
												*ngIf="
													!plan?.limits?.exports_limit
												"
											>
												Unlimited Influencers Export
											</ng-container>
										</div>
									</li>
									<li>
										<div
											class="d-flex k-align-items-center"
											style="gap: 10px;"
										>
											<ng-container
												*ngIf="
													plan?.limits
														?.emails_integration_limit
												"
											>
												{{
													plan?.limits
														?.emails_integration_limit
														| number
												}}
												Mail Accounts
											</ng-container>
											<ng-container
												*ngIf="
													!plan?.limits
														?.emails_integration_limit
												"
											>
												Unlimited Mail Accounts
											</ng-container>
										</div>
									</li>
									<li>
										<div
											class="d-flex k-align-items-center"
											style="gap: 10px;"
										>
											<ng-container
												*ngIf="
													plan?.limits
														?.team_size_limit
												"
											>
												{{
													plan?.limits
														?.team_size_limit
														| number
												}}
												Users
											</ng-container>
											<ng-container
												*ngIf="
													!plan?.limits
														?.team_size_limit
												"
											>
												Unlimited Users
											</ng-container>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
