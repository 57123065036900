<section class="side-nav closed">
	<mat-sidenav #sidenav role="navigation">
		<div
			[ngClass]="{ 'no-menu-margin': !dropdownIsVisible }"
			class="menu-list"
			*ngIf="accountState !== userStateEnum.BillPendingRestrictedAccess"
		>
			<mat-list>
				<mat-list-item
					*appCheckPermissions="backOfficePermission"
					[routerLink]="'/back-office'"
					routerLinkActive="selectedItem"
				>
					<mat-icon
						[ngClass]="{
							'nav-selected':
								currentUrl?.includes('back-office') &&
								!currentUrl?.includes('creative-builder')
						}"
						class="nav-icons"
					>
						admin_panel_settings
					</mat-icon>
					<span
						[ngClass]="{
							'route-text-selected':
								currentUrl?.includes('back-office') &&
								!currentUrl?.includes('creative-builder')
						}"
						class="route-text"
					>
						BACK OFFICE
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item
					*appCheckPermissions="backOfficePermission"
					[routerLink]="'/creative-builder-back-office'"
					routerLinkActive="selectedItem"
				>
					<mat-icon
						[ngClass]="{
							'nav-selected': currentUrl?.includes(
								'creative-builder'
							)
						}"
						class="nav-icons"
					>
						wallpaper
					</mat-icon>
					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'creative-builder'
							)
						}"
						class="route-text"
					>
						CB BACK OFFICE
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item
					class="accounts-button"
					[ngClass]="{
						isSelectedNoBO: isSelectedNoBO === 'accounts',
						anotherSelectedNoBO:
							isSelectedNoBO !== '' &&
							isSelectedNoBO !== 'accounts'
					}"
					*appCheckPermissions="accountsPermission"
					[routerLink]="'/accounts'"
					routerLinkActive="selectedItem"
					(click)="accountsClickedNoBO('accounts')"
				>
					<img
						*ngIf="!currentUrl?.includes('accounts')"
						src="assets/icons/main/AccountManager-Line.svg"
					/>
					<img
						*ngIf="currentUrl?.includes('accounts')"
						src="assets/icons/main/AccountManager-Solid.svg"
					/>

					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'accounts'
							)
						}"
						class="route-text"
					>
						Accounts
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item
					class="ads-manager-button"
					[ngClass]="{
						isSelectedNoBO: isSelectedNoBO === 'ads-manager'
					}"
					*appCheckPermissions="adsManagerPermission"
					[routerLink]="'/ads-manager/insights'"
					routerLinkActive="selectedItem"
					(click)="accountsClickedNoBO('ads-manager')"
				>
					<img
						*ngIf="!currentUrl?.includes('ads-manager')"
						src="assets/icons/main/AdsManager-Line.svg"
					/>
					<img
						*ngIf="currentUrl?.includes('ads-manager')"
						src="assets/icons/main/AdsManager-Solid.svg"
					/>

					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'ads-manager'
							)
						}"
						class="route-text"
					>
						Ads Manager
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item
					class="cb-button"
					[ngClass]="{
						isSelectedNoBO:
							isSelectedNoBO === 'campaign' ||
							currentUrl?.includes('campaign')
					}"
					*appCheckPermissions="campaignBuilderPermission"
					[routerLink]="'/campaign/intro'"
					routerLinkActive="selectedItem"
					(click)="accountsClickedNoBO('campaign')"
				>
					<img
						*ngIf="!currentUrl?.includes('campaign')"
						src="assets/icons/main/CampaignBuilder-Line.svg"
					/>
					<img
						*ngIf="currentUrl?.includes('campaign')"
						src="assets/icons/main/CampaignBuilder-Solid.svg"
					/>

					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'campaign'
							)
						}"
						class="route-text"
					>
						Campaign Builder
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item
					class="optimize-button"
					[ngClass]="{
						isSelectedNoBO: isSelectedNoBO === 'optimize'
					}"
					*appCheckPermissions="optimizePermission"
					[routerLink]="'/optimize/'"
					routerLinkActive="selectedItem"
					(click)="accountsClickedNoBO('optimize')"
				>
					<img
						*ngIf="!currentUrl?.includes('optimize')"
						src="assets/icons/main/Optimise-Line.svg"
					/>
					<img
						*ngIf="currentUrl?.includes('optimize')"
						src="assets/icons/main/Optimise-Solid.svg"
					/>

					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'optimize'
							)
						}"
						class="route-text"
					>
						Optimize
					</span>
				</mat-list-item>
			</mat-list>
			<!--	<mat-list>
				<mat-list-item
					class="pixel-button"
					[ngClass]="{ isSelectedNoBO: isSelectedNoBO === 'pixel' }"
					*appCheckPermissions="pixelPermission"
					[routerLink]="'/pixel/'"
					routerLinkActive="selectedItem"
					(click)="accountsClickedNoBO('pixel')"
				>
					<img *ngIf="!currentUrl?.includes('pixel')" src="assets/icons/main/Pixel-Line.svg" />
					<img *ngIf="currentUrl?.includes('pixel')" src="assets/icons/main/Pixel-Solid.svg" />

					<span [ngClass]="{ 'route-text-selected': currentUrl?.includes('pixel') }" class="route-text">Pixel</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item
					class="audience-button"
					[ngClass]="{ isSelectedNoBO: isSelectedNoBO === 'audience' }"
					*appCheckPermissions="audiencePermission"
					[routerLink]="'/audience'"
					routerLinkActive="selectedItem"
					(click)="accountsClickedNoBO('audience')"
				>
					<img *ngIf="!currentUrl?.includes('audience')" src="assets/icons/main/Audience-Line.svg" />
					<img *ngIf="currentUrl?.includes('audience')" src="assets/icons/main/Audience-Solid.svg" />

					<span [ngClass]="{ 'route-text-selected': currentUrl?.includes('audience') }" class="route-text">
						Audience
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item
					class="reports-button"
					[ngClass]="{ isSelectedNoBO: isSelectedNoBO === 'reports' }"
					*appCheckPermissions="reportsPermission"
					[routerLink]="'/reports'"
					routerLinkActive="selectedItem"
					(click)="accountsClickedNoBO('reports')"
				>
					<img *ngIf="!currentUrl?.includes('reports')" src="assets/icons/main/Reporting-Line.svg" />
					<img *ngIf="currentUrl?.includes('reports')" src="assets/icons/main/Reporting-Solid.svg" />

					<span [ngClass]="{ 'route-text-selected': currentUrl?.includes('reports') }" class="route-text">
						Reports
					</span>
				</mat-list-item>
			</mat-list> -->

			<!-- Return back CB and Feeds Manager -->
			<mat-list
				(mouseover)="addHoverBar()"
				(mouseleave)="removeHoverBar()"
			>
				<mat-list-item
					class="pc-button"
					(click)="accountsClickedNoBO('product-catalog')"
					[ngClass]="{
						isSelectedNoBO: isSelectedNoBO === 'product-catalog',
						anotherSelectedNoBO:
							isSelectedNoBO !== '' &&
							isSelectedNoBO !== 'product-catalog'
					}"
					*appCheckPermissions="productCatalogPermission"
					[ngClass]="{
						selectedItem: rImport.isActive || rManage.isActive
					}"
					[routerLink]="'/catalog'"
					routerLinkActive="selectedItem"
					[routerLinkActiveOptions]="{ exact: false }"
				>
					<img
						*ngIf="!currentUrl?.includes('catalog')"
						src="assets/icons/main/Feed-Mgt-Line.svg"
					/>
					<img
						*ngIf="currentUrl?.includes('catalog')"
						src="assets/icons/main/Feed-Mgt-Solid.svg"
					/>

					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'catalog'
							)
						}"
						class="route-text"
					>
						Feed Manager
					</span>
				</mat-list-item>
			</mat-list>

			<mat-list>
				<mat-list-item
					class="creative-button"
					(click)="accountsClickedNoBO('creative-builder')"
					[ngClass]="{
						isSelectedNoBO: isSelectedNoBO === 'creative-builder',
						anotherSelectedNoBO:
							isSelectedNoBO !== '' &&
							isSelectedNoBO !== 'creative-builder'
					}"
					*appCheckPermissions="creativeBuilderPermission"
					[routerLink]="'/creative-builder'"
					routerLinkActive="selectedItem"
				>
					<img
						*ngIf="!currentUrl?.includes('creative-builder')"
						src="assets/icons/main/CreativeBuilder-Line.svg"
					/>
					<img
						*ngIf="currentUrl?.includes('creative-builder')"
						src="assets/icons/main/CreativeBuilder-Solid.svg"
					/>

					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'creative-builder'
							)
						}"
						class="route-text"
					>
						Creative Builder
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item
					class="creative-button"
					(click)="accountsClickedNoBO('home')"
					[ngClass]="{
						isSelectedNoBO: isSelectedNoBO === 'creative-builder',
						anotherSelectedNoBO:
							isSelectedNoBO !== '' &&
							isSelectedNoBO !== 'creative-builder'
					}"
					*appCheckPermissions="creativeBuilderPermission"
					[routerLink]="'/home'"
					routerLinkActive="selectedItem"
				>
					<img
						*ngIf="!currentUrl?.includes('creative-builder')"
						src="assets/icons/main/CreativeBuilder-Line.svg"
					/>
					<img
						*ngIf="currentUrl?.includes('creative-builder')"
						src="assets/icons/main/CreativeBuilder-Solid.svg"
					/>

					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'creative-builder'
							)
						}"
						class="route-text"
					>
						Home
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item
					class="creative-button"
					(click)="accountsClickedNoBO('template')"
					[ngClass]="{
						isSelectedNoBO: isSelectedNoBO === 'creative-builder',
						anotherSelectedNoBO:
							isSelectedNoBO !== '' &&
							isSelectedNoBO !== 'creative-builder'
					}"
					*appCheckPermissions="creativeBuilderPermission"
					[routerLink]="'/template'"
					routerLinkActive="selectedItem"
				>
					<img
						*ngIf="!currentUrl?.includes('creative-builder')"
						src="assets/icons/main/CreativeBuilder-Line.svg"
					/>
					<img
						*ngIf="currentUrl?.includes('creative-builder')"
						src="assets/icons/main/CreativeBuilder-Solid.svg"
					/>

					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'creative-builder'
							)
						}"
						class="route-text"
					>
						Template
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list
				(mouseover)="addSMIHoverBar()"
				(mouseleave)="removeSMIHoverBar()"
			>
				<mat-list-item
					class="influencer-button"
					[ngClass]="{
						selectedItem:
							smiDashboard.isActive ||
							smiDiscovery.isActive ||
							smiOutreach.isActive ||
							smiCreative.isActive ||
							smiReporting.isActive ||
							smiJobs.isActive ||
							smiPayments.isActive
					}"
					[ngClass]="{
						isSelectedNoBO: isSelectedNoBO === 'influencer',
						anotherSelectedNoBO:
							isSelectedNoBO !== '' &&
							isSelectedNoBO !== 'influencer'
					}"
					(click)="accountsClickedNoBO('influencer')"
					*appCheckPermissions="influencerPermission"
				>
					<img
						*ngIf="!currentUrl?.includes('influencer')"
						src="assets/icons/main/Influencer-Line.svg"
					/>
					<img
						*ngIf="currentUrl?.includes('influencer')"
						src="assets/icons/main/Influencer-Solid.svg"
					/>

					<span
						[ngClass]="{
							'route-text-selected': currentUrl?.includes(
								'influencer'
							)
						}"
						class="route-text"
					>
						Influencer
					</span>
				</mat-list-item>
			</mat-list>
		</div>
	</mat-sidenav>
</section>

<nav
	class="side-bar"
	[ngClass]="{ 'side-bar--active': navHover }"
	(mouseover)="pcChildHover = true"
	(mouseleave)="pcChildHover = false; removeHoverBar()"
>
	<a
		[routerLink]="'/catalog/catalog-manage'"
		#rManage="routerLinkActive"
		routerLinkActive="side-bar__link--active"
		[routerLinkActiveOptions]="{ exact: true }"
		class="side-bar__link"
		(click)="accountsClickedNoBO('product-catalog')"
	>
		<img
			src="assets/icons/import2-icon.svg"
			class="side-bar__link--icon icon-import"
		/>
		<p class="side-bar__link--name">
			<filed-font
				[fontSize]="'14px'"
				[fontWeight]="'400'"
				[color]="'$color03'"
			>
				Manage
			</filed-font>
		</p>
	</a>
	<a
		[routerLink]="'/catalog/catalog-import-option'"
		#rImport="routerLinkActive"
		routerLinkActive="side-bar__link--active"
		[routerLinkActiveOptions]="{ exact: true }"
		class="side-bar__link"
		(click)="accountsClickedNoBO('product-catalog')"
	>
		<img
			src="assets/icons/import2-icon.svg"
			class="side-bar__link--icon icon-import"
		/>
		<p class="side-bar__link--name">
			<filed-font
				[fontSize]="'14px'"
				[fontWeight]="'400'"
				[color]="'$color03'"
			>
				Import
			</filed-font>
		</p>
	</a>
</nav>

<nav
	class="side-bar"
	[ngClass]="{ 'side-bar--active': smiNavHover }"
	(mouseover)="smiChildHover = true"
	(mouseleave)="smiChildHover = false; removeSMIHoverBar()"
>
	<a
		[routerLink]="'/dashboard'"
		#smiDashboard="routerLinkActive"
		routerLinkActive="side-bar__link--active"
		[routerLinkActiveOptions]="{ exact: true }"
		class="side-bar__link"
	>
		<mat-icon class="nav-icons">home</mat-icon>
		<p class="side-bar__link--name">
			<filed-font
				[fontSize]="'14px'"
				[fontWeight]="'400'"
				[color]="'$color03'"
			>
				Dashboard
			</filed-font>
		</p>
	</a>
	<a
		[routerLink]="'/discovery'"
		#smiDiscovery="routerLinkActive"
		routerLinkActive="side-bar__link--active"
		[routerLinkActiveOptions]="{ exact: true }"
		class="side-bar__link"
	>
		<mat-icon class="nav-icons">search</mat-icon>
		<p class="side-bar__link--name">
			<filed-font
				[fontSize]="'14px'"
				[fontWeight]="'400'"
				[color]="'$color03'"
			>
				Discovery
			</filed-font>
		</p>
	</a>
	<a
		[routerLink]="'/outreach'"
		#smiOutreach="routerLinkActive"
		routerLinkActive="side-bar__link--active"
		[routerLinkActiveOptions]="{ exact: false }"
		class="side-bar__link"
	>
		<mat-icon class="nav-icons">desktop_windows</mat-icon>
		<p class="side-bar__link--name">
			<filed-font
				[fontSize]="'14px'"
				[fontWeight]="'400'"
				[color]="'$color03'"
			>
				Outreach
			</filed-font>
		</p>
	</a>
	<a
		[routerLink]="'/creatives'"
		#smiCreative="routerLinkActive"
		routerLinkActive="side-bar__link--active"
		[routerLinkActiveOptions]="{ exact: false }"
		class="side-bar__link"
	>
		<mat-icon class="nav-icons">wallpaper</mat-icon>
		<p class="side-bar__link--name">
			<filed-font
				[fontSize]="'14px'"
				[fontWeight]="'400'"
				[color]="'$color03'"
			>
				Creatives
			</filed-font>
		</p>
	</a>
	<a
		[routerLink]="'/reporting'"
		#smiReporting="routerLinkActive"
		routerLinkActive="side-bar__link--active"
		[routerLinkActiveOptions]="{ exact: false }"
		class="side-bar__link"
	>
		<mat-icon class="nav-icons">donut_large</mat-icon>
		<p class="side-bar__link--name">
			<filed-font
				[fontSize]="'14px'"
				[fontWeight]="'400'"
				[color]="'$color03'"
			>
				Reporting
			</filed-font>
		</p>
	</a>
	<a
		[routerLink]="'/jobs'"
		#smiJobs="routerLinkActive"
		routerLinkActive="side-bar__link--active"
		[routerLinkActiveOptions]="{ exact: false }"
		class="side-bar__link"
	>
		<mat-icon class="nav-icons">business_center</mat-icon>
		<p class="side-bar__link--name">
			<filed-font
				[fontSize]="'14px'"
				[fontWeight]="'400'"
				[color]="'$color03'"
			>
				Jobs
			</filed-font>
		</p>
	</a>
	<a
		[routerLink]="'/payments'"
		#smiPayments="routerLinkActive"
		routerLinkActive="side-bar__link--active"
		[routerLinkActiveOptions]="{ exact: false }"
		class="side-bar__link"
	>
		<img
			src="../../../assets/icons/social-media-icons/payments-nav-icon.svg"
			alt="payment-nav-icon"
			class="nav-icons"
		/>
		<p class="side-bar__link--name">
			<filed-font
				[fontSize]="'14px'"
				[fontWeight]="'400'"
				[color]="'$color03'"
			>
				Payments
			</filed-font>
		</p>
	</a>
</nav>

<section class="side-container closed">
	<div class="header closed">
		<mat-toolbar class="header_toolbar">
			<div class="header-logo" routerLink="/accounts">
				<img src="../../../assets/logo/lolly-L-logo-white.svg" />
			</div>
			<app-multi-channel-account-selection
				*ngIf="dropdownIsVisible && !hasSMI"
			></app-multi-channel-account-selection>

			<span fxFlex></span>

			<div class="progress" *ngIf="showProgressBar">
				<span class="progress-text">Publishing:</span>
			</div>

			<div *ngIf="isImpersonated" class="impersonation-exit">
				<div class="impersonation-content">
					<p class="impersonation-text">
						You are in Impersonation Mode. Any changes you make will
						impact your client.
					</p>

					<app-primary-button
						[buttonLable]="'EXIT'"
						[buttonType]="'Primary'"
						[isPeddingReduceSmall]="true"
						(buttonClicked)="exitImpersonation()"
					></app-primary-button>
				</div>
			</div>

			<div class="header_icons">
				<div class="unpaid-invoice-btn" *ngIf="this.unpaidInvoice">
					<a [routerLink]="['/user-management/billing-and-payment']">
						Unpaid Invoice
					</a>
					<div class="unpaid-description">
						There is an issue with your card. We were unable to bill
						you. Please add a new payment.
					</div>
				</div>
				<button
					(click)="openKnowledgeBase()"
					class="help-button"
					mat-button
					matTooltip="{{ 'HELP' | translate }}"
					matTooltipClass="button-custom-tooltip"
				>
					<i class="fas fa-question-circle"></i>
				</button>
				<button
					#menuTrigger="matMenuTrigger"
					[matMenuTriggerFor]="menu"
					[class.account-button-active]="showUserManagement"
					mat-button
					matTooltip="{{ 'ACCOUNT' | translate }}"
					matTooltipClass="button-custom-tooltip"
				>
					<i class="fas fa-user-circle">
						<mat-menu #menu="matMenu" class="custom_mat_menu">
							<div class="wrapper-dropdown-buttons">
								<app-button
									(buttonClicked)="goToUserPage()"
									[buttonClass]="buttonClassEnum.Tertiary"
									[buttonType]="buttonTypeEnum.Simple"
									buttonLabel="SETTINGS"
									*ngIf="dropdownIsVisible"
								></app-button>
								<app-button
									(buttonClicked)="logout()"
									[buttonClass]="buttonClassEnum.Secondary"
									[buttonType]="buttonTypeEnum.Simple"
									buttonLabel="SIGN OUT"
								></app-button>
							</div>
						</mat-menu>
					</i>
				</button>
			</div>
		</mat-toolbar>
	</div>

	<div class="main-container closed">
		<mat-sidenav-container>
			<mat-sidenav-content (scroll)="onScroll($event)">
				<router-outlet></router-outlet>
				<app-lolly-spinning-loader
					[rotating]="true"
					class="loading-indicator"
					*ngIf="showSpinner"
				></app-lolly-spinning-loader>
				<!-- <app-knowledge-base></app-knowledge-base> TO BE updated-->
			</mat-sidenav-content>
		</mat-sidenav-container>
	</div>
</section>

<div [ngStyle]="setPointPosition()" class="selected-circle"></div>

<!-- temporary -->
<ng-template #firstTimeLogin>
	<h2 mat-dialog-title>Thanks for using Lolly !</h2>
	<mat-dialog-content class="mat-typography restrict-users-dialog">
		<h3>
			The platform is undergoing development for our upcoming release and
			therefore new users don’t have access just yet. Please rest assured
			Dexter is still working in the background to analyse your
			campaign(s). Please get in touch with your Client Success Manager
			regarding any questions.
		</h3>
		<app-button
			(buttonClicked)="logout()"
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			buttonLabel="Logout"
		></app-button>
	</mat-dialog-content>
</ng-template>
